import { useSelector } from "react-redux";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";

import pages from "../../../../constants/pages";
import { colors } from "../../../css components/Style";
import { Autocomplete, ComponentHeader } from "../../../../components";
import AudienceForm from "../audience details components/AudienceForm";
import AudienceDetailsDialog from "../audience details components/AudienceDetailsDailog";
import { PrimaryButton } from "../../../css components/Button";

export default function FormTwo({
  audiences,
  brandIdentity,
  formikProps,
  editState,
  component,
  audienceData,
  setAudienceData,
  setAudienceCreated,
}) {
  const navigate = useNavigate();
  const { categories, location, languages } = useSelector(
    (state) => state.memberDetails
  );
  const [details, setDetails] = useState({
    id: formikProps.values.audience,
    state: false,
  });

  return (
    <Fragment>
      <Grid item xs={12}>
        <ComponentHeader
          title={"Audience"}
          subtitle={
            component === "" ? "Choose your audience" : "Audience Selected"
          }
        />
      </Grid>

      {!formikProps.values?.campaignID || editState ? (
        <Fragment>
          <Grid item xs={6}>
            <Autocomplete
              label={"Select Audience"}
              forcePopupIcon={!editState ? false : true}
              name="audienceID"
              placeholder="Select Audience"
              defaults={{
                primaryKey: "audience_id",
                displayLabel: "audience_group",
              }}
              sx={{
                "& .MuiSvgIcon-root": {
                  display: !editState && "none",
                },
              }}
              options={audiences}
              disabled={
                !editState ||
                (!audienceData.isEditable &&
                  formikProps.values.status === "OFFER_ACCEPTED")
              }
              onChange={(_, value, type) => handleChangeAudience(value, type)}
              formikProps={formikProps}
            />
          </Grid>

          {formikProps.values.audienceID && (
            <Grid item xs={12}>
              <PrimaryButton
                size="small"
                onClick={() =>
                  setDetails({
                    id: formikProps.values.audienceID,
                    state: true,
                    open: "detail",
                  })
                }
                variant="outlined"
              >
                Audience Details
              </PrimaryButton>
            </Grid>
          )}

          {!brandIdentity && editState && (
            <Grid item xs={12}>
              <Typography
                style={{
                  fontWeight: 500,
                  marginBottom: "8px",
                  display: "flex",
                }}
              >
                Can’t find your audience group, let’s &nbsp;
                <Typography
                  style={{ color: colors.purple, cursor: "pointer" }}
                  onClick={() => navigate(pages.createAudience.route)}
                >
                  {" "}
                  create one
                </Typography>{" "}
                .
              </Typography>
            </Grid>
          )}
        </Fragment>
      ) : (
        <AudienceForm
          editState={editState}
          audienceData={audienceData}
          categories={categories}
          location={location}
          languages={languages}
          formikProps={formikProps}
          component={component}
          setAudienceData={setAudienceData}
        />
      )}

      <AudienceDetailsDialog
        categories={categories}
        location={location}
        languages={languages}
        details={details}
        setDetails={setDetails}
        component="dialog"
        setAudienceCreated={setAudienceCreated}
        formikProps={formikProps}
        campaignID={formikProps?.values?.campaignID}
      />
    </Fragment>
  );

  function handleChangeAudience(value, type) {
    if (type === "selectOption") {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        audienceID: value.audience_id,
      }));

      setDetails({
        id: value.audience_id,
        state: false,
        open: "detail",
      });
    } else {
      value = {
        audience_id: "",
        audience_group: "",
      };

      formikProps.setValues((prevVal) => ({
        ...prevVal,
        audienceID: value._id,
      }));
    }
  }
}
