export const brandDetailsObject = {
  brandID: "",
  businessEntityId: "",
  brandName: "",
  website: "",
  profile: "",
  status: false,
  audienceLocation: [],
  audienceLanguages: [],
  socialMedia: {
    facebook: "",
    instagram: "",
    twitter: "",
    whatsapp: "",
  },
  brandAvatar: "",
  avatar: "",
  about: "",
  onlineStore: "",
  siteCategory: "",
  monthlyActiveUsers: "",
  prefrredChannel: [],
};
