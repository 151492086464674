import {
  Typography,
  Dialog as MUIDialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CloseOutlined } from "@mui/icons-material";

import { colors } from "../../css components/Style";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "95%", height: "100%" },
  div: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
  },
}));
export default function ViewAgreement({ viewAgreement, setViewAgreement }) {
  const classes = useStyles();

  const handleClose = () => {
    setViewAgreement({
      data: viewAgreement?.data,
      state: false,
    });
  };

  return (
    <MUIDialog
      classes={{ paper: classes.paper }}
      open={viewAgreement?.state}
      onClose={handleClose}
    >
      <DialogTitle>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Typography color={colors.purple} variant="h4">
              View Agreement
            </Typography>
          </Grid>

          <Grid item xs={5} textAlign={"end"}>
            <IconButton onClick={handleClose}>
              <CloseOutlined />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <div className="modal">
          <div className="modalContent">
            <iframe
              title="View Agreement"
              src={`${
                viewAgreement.data.is_verified
                  ? viewAgreement.data?.signed_agreement_link
                  : viewAgreement.data?.unsigned_agreement_link
              }#zoom=100`}
              style={{ width: "100%", height: "550px" }}
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </DialogContent>
    </MUIDialog>
  );
}
