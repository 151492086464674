import { SketchPicker } from "react-color";
import { useEffect, useRef, useState } from "react";
import { Grid, IconButton, InputAdornment } from "@mui/material";

import {
  ButtonFooter,
  ComponentHeader,
  FileUpload,
  FloatingEditActionButtons,
  PageHeader,
  Paper,
  TextField,
} from "../../../components";
import EditButtons from "../../../components/EditButtons";
import { ColorLens } from "@mui/icons-material";
import { colors } from "../../css components/Style";

export default function FormDisplay({ editState, setEditState, formikProps }) {
  const pickerStyle = {
    default: {
      picker: {
        position: "absolute",
        bottom: "53px",
        left: "40%",
      },
    },
  };

  const [primaryColor, setPrimaryColor] = useState(false);
  const [secondaryColor, setSecondaryColor] = useState(false);

  let ClickPrimaryLens = (handler) => {
    let primaryNode = useRef();

    useEffect(() => {
      let maybeHandler = (event) => {
        if (!primaryNode.current.contains(event.target)) {
          handler();
        }
      };

      document.addEventListener("mousedown", maybeHandler);

      return () => {
        document.removeEventListener("mousedown", maybeHandler);
      };
    });

    return primaryNode;
  };

  let primaryNode = ClickPrimaryLens(() => {
    setPrimaryColor(false);
  });

  function handlePrimaryColor() {
    setPrimaryColor(!primaryColor);
  }

  let ClickSecondaryLens = (handler) => {
    let secondaryNode = useRef();

    useEffect(() => {
      let maybeHandler = (event) => {
        if (!secondaryNode.current.contains(event.target)) {
          handler();
        }
      };

      document.addEventListener("mousedown", maybeHandler);

      return () => {
        document.removeEventListener("mousedown", maybeHandler);
      };
    });

    return secondaryNode;
  };

  let secondaryNode = ClickSecondaryLens(() => {
    setSecondaryColor(false);
  });

  function handleSecondaryColor() {
    setSecondaryColor(!primaryColor);
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <PageHeader
          pageName={"Set Site UI"}
          pageHeader={"Change Site UI"}
          buttons={[
            <EditButtons
              formikProps={formikProps}
              editState={editState}
              setEditState={setEditState}
            />,
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        <Paper>
          <Grid item xs={12}>
            <ComponentHeader
              title={"UI Settings"}
              subtitle={"Change UI according to your prefrence"}
            />
          </Grid>

          <Grid item xs={6}>
            <FileUpload
              disabled={!editState}
              size="small"
              placeholder="Upload Site Logo"
              name="siteLogo"
              label="Site Logo"
              formikProps={formikProps}
              allowedExtension=".jpg,.jpeg,.png,.gif"
              folderPath={
                "public/dynamic_dashboard/" + new Date().getTime() + "_"
              }
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled={!editState}
              name="siteCopyright"
              label="White Label"
              formikProps={formikProps}
              placeholder="Site Copy Logo"
            />
          </Grid>

          <Grid item xs={6}>
            <div
              style={{
                position: "relative",
              }}
              ref={primaryNode}
            >
              {primaryColor && (
                <SketchPicker
                  styles={pickerStyle}
                  display={primaryColor}
                  onChange={({ hex }) => {
                    formikProps.setValues((prevVal) => ({
                      ...prevVal,
                      siteColors: setColors(prevVal.siteColors),
                    }));

                    function setColors(val) {
                      val.primary = hex;
                      return val;
                    }
                  }}
                  color={formikProps.values.siteColors.primary}
                />
              )}

              <TextField
                disabled
                name="siteColors.primary"
                label="Site Primary Button Color"
                formikProps={formikProps}
                placeholder="Site Primary Color"
                case="none"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div
                        style={{
                          background: formikProps.values.siteColors.primary,
                          height: "15px",
                          width: "15px",
                          borderRadius: "50%",
                        }}
                      ></div>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        style={{ color: colors.purple }}
                        onClick={handlePrimaryColor}
                        disabled={!editState}
                      >
                        <ColorLens color={colors.purple} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <div
              style={{
                position: "relative",
              }}
              ref={secondaryNode}
            >
              {secondaryColor && (
                <SketchPicker
                  styles={pickerStyle}
                  display={secondaryColor}
                  onChange={({ hex }) => {
                    formikProps.setValues((prevVal) => ({
                      ...prevVal,
                      siteColors: setColors(prevVal.siteColors),
                    }));

                    function setColors(val) {
                      val.secondary = hex;
                      return val;
                    }
                  }}
                  color={formikProps.values.siteColors.secondary}
                />
              )}

              <TextField
                disabled
                name="siteColors.secondary"
                label="Site Secondary Button Color"
                formikProps={formikProps}
                placeholder="Site Secondary Color"
                case="none"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div
                        style={{
                          background: formikProps.values.siteColors.secondary,
                          height: "15px",
                          width: "15px",
                          borderRadius: "50%",
                        }}
                      ></div>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        style={{ color: colors.purple }}
                        onClick={handleSecondaryColor}
                        disabled={!editState}
                      >
                        <ColorLens color={colors.purple} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <FileUpload
              disabled={!editState}
              size="small"
              name="emailLogo"
              placeholder="Email Logo"
              label="Email Logo"
              formikProps={formikProps}
              allowedExtension=".jpg,.jpeg,.png,.gif"
              folderPath={"public/dynamic_dashboard/" + new Date().getTime()}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled={!editState}
              name="emailPlatform"
              label="Email Platform"
              formikProps={formikProps}
              placeholder="Email Platform"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled={!editState}
              name="emailHeader"
              label="Email Header"
              formikProps={formikProps}
              placeholder="Email Header"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled={!editState}
              name="emailFooter"
              label="Email Footer"
              formikProps={formikProps}
              placeholder="Email Footer"
            />
          </Grid>
        </Paper>
      </Grid>

      {editState && (
        <Grid item xs={12}>
          <ButtonFooter
            ID={formikProps.values.ID}
            editState={editState}
            setEditState={setEditState}
            formikProps={formikProps}
          />
        </Grid>
      )}

      {!editState && (
        <FloatingEditActionButtons
          ID={formikProps.values.campaignID}
          editState={editState}
          setEditState={setEditState}
          formikProps={formikProps}
        />
      )}
    </Grid>
  );
}
