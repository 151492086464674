import { Add } from "@mui/icons-material";
import { Fab, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function FloatingAddActionButtons({ route, title }) {
  const navigate = useNavigate();

  return (
    <Tooltip title={title}>
      <Fab
        style={{
          position: "absolute",
          right: "4%",
          bottom: "4%",
          color: "#fff",
          backgroundColor: "#28967e",
        }}
        onClick={() => navigate(route)}
      >
        <Add />
      </Fab>
    </Tooltip>
  );
}
