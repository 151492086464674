import * as Yup from "yup";

export const dynamicUiObjects = {
  siteLogo: "",
  siteCopyright: "",
  siteColors: {
    primary: "",
    secondary: "",
  },
  emailLogo: "",
  emailPlatform: "",
  emailHeader: "",
};

export function objectFromResponse(response) {
  return {
    siteLogo: response.site_logo,
    siteCopyright: response.site_copyright,
    siteColors: {
      primary: response.site_colors.primary,
      secondary: response.site_colors.secondary,
    },
    emailLogo: response.email_logo,
    emailPlatform: response.email_platform,
    emailHeader: response.email_header,
    emailFooter: response.email_footer,
  };
}

export function objectFromFormData(data) {
  return {
    site_logo: data.siteLogo,
    site_copyright: data.siteCopyright,
    site_colors: {
      primary: data.siteColors.primary,
      secondary: data.siteColors.secondary,
    },
    email_logo: data.emailLogo,
    email_platform: data.emailPlatform,
    email_header: data.emailHeader,
    email_footer: data.emailFooter,
  };
}

export const validationSchema = Yup.object().shape({
  siteLogo: Yup.mixed().required("Site Logo is required."),
  siteCopyright: Yup.string().required("Site copy rights is required."),
});
