import React from "react";
import { Grid } from "@mui/material";
import "../../whatsapp interface/WhatsappPreview.css";

import { ComponentHeader, Paper } from "../../../../components";
import ActionButtons from "./TemplateActionButton";
import TemplateCallToActionButton from "./TemplateCallToActionButtons";
import QuickReply from "./QuickReply";

export default function TemplateButtons({ formikProps, addSample }) {
  return (
    <Paper>
      <Grid style={{ alignSelf: "center" }} item xs={12}>
        <ComponentHeader
          title={"Template Buttons"}
          subtitle={
            "Create buttons that let customers respond to your message or take action. You can add only three buttons for quick reply and 2 buttons for call to action (Optional)."
          }
          buttons={[
            <ActionButtons formikProps={formikProps} addSample={addSample} />,
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        {formikProps.values.templateButtons?.type === "call_to_action" && (
          <TemplateCallToActionButton
            formikProps={formikProps}
            addSample={addSample}
          />
        )}
        {formikProps.values.templateButtons?.type === "quick_reply" && (
          <QuickReply formikProps={formikProps} addSample={addSample} />
        )}
      </Grid>
    </Paper>
  );
}
