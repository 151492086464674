import { Grid } from "@mui/material";
import { TextField } from "../../../components";
import { Fragment } from "react";

export default function AudienceSizeForm({ formikProps, component }) {
  return (
    <Fragment>
      <Grid item xs={12} style={{ marginTop: "10px" }}>
        <TextField
          label={"Final Audience Parameters"}
          disabled={component === "consumer"}
          minRows={5}
          formikProps={formikProps}
          name="audience_params"
          multiline
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Total Audience Size"
          disabled={component === "consumer"}
          formikProps={formikProps}
          name="audience_size"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          disabled={component === "consumer"}
          formikProps={formikProps}
          name="audience_pricing"
          label="Audience Pricing"
        />
      </Grid>
    </Fragment>
  );
}
