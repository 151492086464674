import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useAxios } from "../../../hooks";
import Form from "./bot details components/Form";
import { Accept } from "../../css components/Button";
import PageHeader from "./../../../components/PageHeader";
import { botDetailsObject } from "./bot details components/botDetailsObject";

export default function BotDetails() {
  const axios = useAxios({ baseURL: "cpass_api" });

  const { botID } = useParams();

  const [editState, setEditState] = useState(false);
  const [formData, setFormData] = useState(botDetailsObject);

  useEffect(() => {
    axios({ url: "/details/" + botID, disableRedirect: true }).then(
      (response) => {
        if (response.status) {
          setFormData(response.data);
        }
      }
    );
  }, [botID, axios]);

  return (
    <Fragment>
      <PageHeader
        pageName="Bot Details"
        buttons={[
          !editState && (
            <Accept
              style={{ marginLeft: "5px" }}
              size="large"
              variant="contained"
              onClick={() => setEditState(!editState)}
            >
              Edit
            </Accept>
          ),
        ]}
      />

      <Form
        formData={formData}
        setFormData={setFormData}
        editState={editState}
        setEditState={setEditState}
      />
    </Fragment>
  );
}
