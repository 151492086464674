import queryString from "query-string";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { Loader, Paper } from "../../components";
import Form from "./onboarding details components/Form";
import { useAxios, useComponent } from "../../hooks";
import {
  objectFromResponse,
  onboardingObject,
  setValue,
} from "./onboarding details components/onboardingObjects";
import DynamicFields from "./onboarding details components/FormTwo";

export default function DpOnBoardingDetails() {
  const axios = useAxios();
  const { loader } = useComponent();
  const { connectionID } = useParams();
  const brandID = queryString.parse(window.location.search).brandID;

  const [brands, setBrands] = useState([]);
  const [fields, setFields] = useState([]);
  const [editState, setEditState] = useState(true);
  const [formData, setFormData] = useState(onboardingObject);
  const [fieldValue, setFieldValue] = useState({ fields: [] });

  useEffect(() => {
    if (connectionID) {
      setEditState(false);
    }

    loader.start(3);

    axios({
      url: "/user/getOnboardingFields",
    }).then((field) => {
      let fielMap = [];
      if (field.status) {
        setFields(field.data);
        if (connectionID) {
          axios({
            url: "/user/getOnboardBrandMappedDetails?brandId=" + brandID,
          }).then((response) => {
            if (response.status) {
              setFormData(objectFromResponse(response.data[0], field.data));
              setFieldValue(setValue(response.data[0], field.data));
              loader.apiComplete();
            }
          });
        } else {
          for (let f of field.data) {
            fielMap.push({
              [f.field_name]: {
                value: "",
                type: "",
                priority: [],
                org_trust: "",
                cpm_price: "",
              },
            });
          }
          setFormData((prev) => ({
            ...prev,
            fields: fielMap,
          }));
          loader.apiComplete();
        }
        loader.apiComplete();
      }
    });

    axios({
      url: "/getAllOnboardingBrands",
    }).then((response) => {
      if (response.status) {
        setBrands(response.data);
        loader.apiComplete();
      } else {
        loader.stop();
      }
    });
  }, [axios, loader, connectionID, brandID]);

  return (
    <Loader>
      <Form
        fieldValue={fieldValue}
        fields={fields}
        brands={brands}
        formData={formData}
        setFormData={setFormData}
        editState={editState}
        setEditState={setEditState}
      />

      {fields.length > 0 && connectionID && (
        <Paper gridSpacing={6}>
          <DynamicFields
            formData={formData}
            setEditState={setEditState}
            setFieldValue={setFieldValue}
            fieldValue={fieldValue}
            fields={fields}
            editState={editState}
          />
        </Paper>
      )}
    </Loader>
  );
}
