import { Fragment } from "react";
import { TbExternalLink } from "react-icons/tb";
import { Grid, IconButton } from "@mui/material";

import { Label, TextField } from "../../../components";

export default function FormTwo({ formData }) {
  return (
    <Fragment>
      <Grid item xs={12}>
        <Label title="Social Media Handles" />
      </Grid>

      <Grid item xs={6}>
        <TextField
          size="small"
          disabled
          value={formData.socialMedia.facebook}
          label={"Facebook Handle"}
          name="socialMedia.facebook"
          placeholder={"Enter your facebook handle"}
          InputProps={{
            endAdornment: !!formData.socialMedia.facebook && (
              <IconButton
                onClick={() =>
                  linkOpen(
                    "https://facebook.com/" +
                      formData.socialMedia.facebook.replace(
                        /(?:https?|ftp):\/\/[\n\S]+/g,
                        ""
                      )
                  )
                }
              >
                <TbExternalLink />
              </IconButton>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled
          size="small"
          value={formData.socialMedia.instagram}
          name="socialMedia.instagram"
          label={"Instagram Handle"}
          placeholder={"Enter your instagram handle"}
          InputProps={{
            endAdornment: !!formData.socialMedia.instagram && (
              <IconButton
                onClick={() =>
                  linkOpen(
                    "https://instagram.com/" +
                      formData.socialMedia.instagram.replace(
                        /(?:https?|ftp):\/\/[\n\S]+/g,
                        ""
                      )
                  )
                }
              >
                <TbExternalLink />
              </IconButton>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          size="small"
          value={formData.socialMedia.twitter}
          disabled
          name="socialMedia.twitter"
          label={"Twitter Handle"}
          placeholder={"Enter your twitter handle"}
          InputProps={{
            endAdornment: !!formData.socialMedia.twitter && (
              <IconButton
                onClick={() =>
                  linkOpen(
                    "https://twitter.com/" +
                      formData.socialMedia.twitter.replace(
                        /(?:https?|ftp):\/\/[\n\S]+/g,
                        ""
                      )
                  )
                }
              >
                <TbExternalLink />
              </IconButton>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled
          size="small"
          name="socialMedia.whatsapp"
          label={"Whatsapp Number"}
          value={formData.socialMedia.whatsapp}
          placeholder={"Enter your whatsapp number"}
          InputProps={{
            maxLength: 10,
            endAdornment: !!formData.socialMedia.whatsapp && (
              <IconButton
                onClick={() =>
                  linkOpen("https://wa.me/" + formData.socialMedia.whatsapp)
                }
              >
                <TbExternalLink />
              </IconButton>
            ),
          }}
        />
      </Grid>
    </Fragment>
  );

  function linkOpen(val) {
    window.open(val, "_blank");
  }
}
