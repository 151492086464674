import { Fragment } from "react";
import { TbExternalLink } from "react-icons/tb";
import { Grid, IconButton } from "@mui/material";

import { Label, TextField } from "../../../components";

export default function FormTwo({ list, formikProps, editState }) {
  return (
    <Fragment>
      <Grid item xs={12}>
        <Label title="Social Media Handles" />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          label={"Facebook Handle"}
          name="socialMedia.facebook"
          placeholder={"Enter your facebook handle"}
          InputProps={{
            endAdornment: !!formikProps.values.socialMedia.facebook &&
              !editState && (
                <IconButton
                  onClick={() =>
                    linkOpen(
                      "https://facebook.com/" +
                        formikProps.values.socialMedia.facebook.replace(
                          /(?:https?|ftp):\/\/[\n\S]+/g,
                          ""
                        )
                    )
                  }
                >
                  <TbExternalLink />
                </IconButton>
              ),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="socialMedia.instagram"
          label={"Instagram Handle"}
          placeholder={"Enter your instagram handle"}
          InputProps={{
            endAdornment: !!formikProps.values.socialMedia.instagram &&
              !editState && (
                <IconButton
                  onClick={() =>
                    linkOpen(
                      "https://instagram.com/" +
                        formikProps.values.socialMedia.instagram.replace(
                          /(?:https?|ftp):\/\/[\n\S]+/g,
                          ""
                        )
                    )
                  }
                >
                  <TbExternalLink />
                </IconButton>
              ),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="socialMedia.twitter"
          label={"Twitter Handle"}
          placeholder={"Enter your twitter handle"}
          InputProps={{
            endAdornment: !!formikProps.values.socialMedia.twitter &&
              !editState && (
                <IconButton
                  onClick={() =>
                    linkOpen(
                      "https://twitter.com/" +
                        formikProps.values.socialMedia.twitter.replace(
                          /(?:https?|ftp):\/\/[\n\S]+/g,
                          ""
                        )
                    )
                  }
                >
                  <TbExternalLink />
                </IconButton>
              ),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="socialMedia.whatsapp"
          label={"Whatsapp Number"}
          placeholder={"Enter your whatsapp number"}
          InputProps={{
            maxLength: 10,
            endAdornment: !!formikProps.values.socialMedia.whatsapp &&
              !editState && (
                <IconButton
                  onClick={() =>
                    linkOpen(
                      "https://wa.me/" + formikProps.values.socialMedia.whatsapp
                    )
                  }
                >
                  <TbExternalLink />
                </IconButton>
              ),
          }}
        />
      </Grid>
    </Fragment>
  );

  function linkOpen(val) {
    window.open(val, "_blank");
  }
}
