import { useState } from "react";
import { useSelector } from "react-redux";

import Form from "./profile details components/Form";

export default function Profile() {
  const { userData } = useSelector((state) => state.memberDetails);

  const [formData, setFormData] = useState(userData);

  return <Form formData={formData} setFormData={setFormData} />;
}
