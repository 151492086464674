import { Fragment, useState } from "react";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  IconButton,
  Tooltip,
  MenuItem,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { InfoOutlined } from "@mui/icons-material";

import ActionButtons from "./ActionButtons";
import { colors } from "../../css components/Style";
import { useAxios, useComponent } from "../../../hooks";
import { PrimaryLoadingButton } from "../../css components/Button";
import { Autocomplete, TextField, ComponentHeader } from "../../../components";

export default function FormOne({
  setEditState,
  formikProps,
  brands,
  editState,
  changeData,
  setChangeData,
}) {
  const axios = useAxios();
  const dispatch = useDispatch();
  const { alert } = useComponent();

  const [loading, setLoading] = useState(false);

  function handleConnection() {
    setLoading(true);
    axios({
      url: "/user/testConnectionDetails",
      method: "POST",
      data: {
        connection_url: formikProps.values.connectionUrl,
        port: formikProps.values.port,
        token: formikProps.values.token,
      },
      disableRedirect: true,
    }).then((res) => {
      if (res.status) {
        formikProps.setValues((prev) => ({
          ...prev,
          connection:
            res.data[0].connection_establishment === "success" ? true : false,
          fieldNames: res.data[1].column_names,
        }));
        dispatch(
          alert.success(res.data[0]?.connection_establishment || "Success")
        );
      } else {
        dispatch(alert.error(res.message));
      }

      setLoading(false);
    });
  }

  return (
    <Fragment>
      <Grid item xs={12}>
        <ComponentHeader
          title={"Brand Details"}
          buttons={[
            <ActionButtons
              formikProps={formikProps}
              editState={editState}
              setEditState={setEditState}
              changeData={changeData}
              setChangeData={setChangeData}
            />,
            <PrimaryLoadingButton
              loading={loading}
              variant="contained"
              onClick={handleConnection}
              disabled={
                !formikProps.values.connectionUrl && !formikProps.values.port
              }
            >
              Test Connection
            </PrimaryLoadingButton>,
          ]}
        />
      </Grid>

      <Grid item xs={4}>
        {!!formikProps.values.connectionID ? (
          <TextField
            label="Brand Name"
            name="brandName"
            formikProps={formikProps}
            disabled={!!formikProps.values.connectionID}
          />
        ) : (
          <Autocomplete
            label="Brand Name"
            name="brandID"
            forcePopupIcon={!editState ? false : true}
            placeholder="Select Brand Name"
            defaults={{
              primaryKey: "brand_id",
              displayLabel: "brand_name",
            }}
            options={brands}
            disabled={!!formikProps.values.connectionID}
            onChange={(_, value, type) => handleChangeBrand(value, type)}
            formikProps={formikProps}
          />
        )}

        <FormControlLabel
          control={
            <Checkbox
              disabled={!editState}
              onChange={(e) => {
                formikProps.setValues((prev) => ({
                  ...prev,
                  csvUpload: e.target.checked,
                }));
              }}
              checked={formikProps.values.csvUpload}
              sx={{
                color: colors.purple,
                "&.Mui-checked": {
                  color: colors.purple,
                },
              }}
            />
          }
          label="Onboarding with CSV"
        />
      </Grid>

      {!formikProps.values.csvUpload && (
        <Fragment>
          <Grid item xs={4}>
            <TextField
              name="connectionUrl"
              placeholder="IP/Domain"
              label="IP/Domain"
              disabled={!editState}
              formikProps={formikProps}
              InputProps={{
                endAdornment: (
                  <InputAdornment position={"end"}>
                    <Tooltip title="Provide either domain or ip:port">
                      <IconButton style={{ padding: 0 }}>
                        <InfoOutlined />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              select
              name="status"
              placeholder="Status"
              label="Status"
              disabled={!editState}
              formikProps={formikProps}
              onChange={(e) => {
                formikProps.setValues((prev) => ({
                  ...prev,
                  status: e.target.value,
                }));
                setChangeData(true);
              }}
            >
              <MenuItem value="ACTIVE">Active</MenuItem>
              <MenuItem value="INACTIVE">In Active</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <TextField
              multiline
              name="token"
              placeholder="Token"
              label="Token"
              disabled={!editState}
              formikProps={formikProps}
            />
          </Grid>
        </Fragment>
      )}

      <Grid item xs={12}>
        <Typography variant="h6">Prime Identifier</Typography>
      </Grid>

      <Grid item xs={4}>
        <TextField
          required
          name="mobile"
          placeholder="Mobile"
          label="Mobile"
          disabled={!editState}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6">Other Identifier</Typography>
      </Grid>

      <Grid item xs={4}>
        <TextField
          name="email"
          placeholder="Email"
          label="Email"
          disabled={!editState}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          name="gaid"
          placeholder="GAID"
          label="GAID"
          disabled={!editState}
          formikProps={formikProps}
        />
      </Grid>
    </Fragment>
  );

  function handleChangeBrand(value, type) {
    if (type === "selectOption") {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        brandID: value.brand_id,
      }));
    } else {
      value = {
        brand_id: "",
        brand_name: "",
      };

      formikProps.setValues((prevVal) => ({
        ...prevVal,
        brandID: value.brandID,
      }));
    }
  }
}
