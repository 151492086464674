import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  channel: Yup.string().required("Agent ID is required"),
  wabaID: Yup.string().required("Please select a channel"),
  isActive: Yup.string().required("Please select a channel"),
  cloudAPI: Yup.string().required("Please select a Cloud API"),
  phoneNumberID: Yup.string().required("Phone Number ID is required"),
});

export default validationSchema;
