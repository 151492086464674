import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { Fragment, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ComponentHeader } from "../../../components";
import {
  PrimaryLoadingButton,
  Accept,
  CancelButton,
} from "../../css components/Button";
import { useAxios } from "../../../hooks";
import pages from "../../../constants/pages";
import Fields from "./dynamic fields components/Field";
import { alert } from "../../../redux/slices/alertSlice";

export default function DynamicFields({
  formData,
  fields,
  fieldValue,
  setFieldValue,
}) {
  var initialState = fieldValue;

  const axios = useAxios();
  const navigate = useNavigate();
  const dispatchEvent = useDispatch();

  const [editState, setEditState] = useState(false);
  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState({
    org_trust: {},
    cpm_price: {},
  });

  const formReducer = (state, action) => {
    switch (action.type) {
      case "UPDATE_FIELD":
        return {
          ...state,
          [action.payload.name]: {
            ...state[action?.payload?.name],
            [action.payload.key]: action.payload.value,
          },
        };
      case "MULTI_DROP_DOWN":
        return {
          ...state,
          [action.payload.name]: {
            ...state[action?.payload?.name],
            [action.payload.key]:
              typeof action.payload.value === "string"
                ? action.payload.value.split(",")
                : action.payload.value,
          },
        };
      case "CLEAR_VALUES":
        return {
          ...state,
          [action.payload.name]: {
            value: "",
            type: "",
            org_trust: "",
            cpm_price: "",
            priority: [],
          },
        };

      case "UPDATE_NUMBER_FIELD":
        let value = parseInt(action.payload.value);
        if (value < 0 || value > action.payload.num) {
          setMessage({
            ...message,
            [action.payload.key]: { [action.payload.index]: true },
          });
          return {
            ...state,
            [action.payload.name]: {
              ...state[action?.payload?.name],
            },
          };
        }

        setMessage({ ...message, [action.payload.key]: {} });
        return {
          ...state,
          [action.payload.name]: {
            ...state[action?.payload?.name],
            [action.payload.key]: action.payload.value,
          },
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(formReducer, initialState);

  return (
    <Fragment>
      <Grid item xs={12}>
        <ComponentHeader
          title={"Dynamic Fields"}
          buttons={[
            <Fragment>
              <Grid item style={{ alignSelf: "center" }}>
                {editState && (
                  <CancelButton
                    variant="outlined"
                    onClick={() => setEditState(!editState)}
                  >
                    Cancel
                  </CancelButton>
                )}
              </Grid>

              <Grid item>
                {!editState ? (
                  <Accept
                    style={{ marginLeft: "5px" }}
                    size="large"
                    variant="contained"
                    onClick={() => setEditState(!editState)}
                  >
                    Edit
                  </Accept>
                ) : (
                  <PrimaryLoadingButton
                    type="submit"
                    loading={loading}
                    variant="contained"
                    fullWidth
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </PrimaryLoadingButton>
                )}
              </Grid>
            </Fragment>,
          ]}
        />
      </Grid>

      <Fields
        message={message}
        state={state}
        dispatch={dispatch}
        initialState={initialState}
        setFieldValue={setFieldValue}
        fieldValue={fieldValue}
        fields={fields}
        editState={editState}
      />
    </Fragment>
  );

  function handleSubmit() {
    setLoading(true);
    axios({
      url: "/user/onboarding",
      method: "POST",
      data: {
        brand_id: formData.brandID,
        connectionDetails: {
          connection_url: formData.connectionUrl,
          status: formData.status,
          token: formData.token,
          csv_upload: formData.csvUpload,
        },
        fieldMappings: state,
      },
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        navigate(
          pages.dpOnBoardingDetails.route +
            response.data.id +
            "?brandID=" +
            response?.data?.brand_id
        );
        setEditState(false);

        dispatchEvent(
          alert({
            type: "success",
            message: response?.message?.displayMessage,
          })
        );
      }
      setLoading(false);
    });
  }
}
