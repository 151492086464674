import styled from "styled-components";
import { Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Loader } from "../../components";
import { useAxios, useComponent } from "../../hooks";
import SelectRole from "./login user components/SelectRole";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 0 20px;
`;

export default function Welcome() {
  const axios = useAxios();
  const { loader } = useComponent();

  const [brands, setBrands] = useState([]);

  const userID = sessionStorage.getItem("userID");
  const authToken = sessionStorage.getItem("authToken");
  const businessEntityID = sessionStorage.getItem("businessEntityId");

  const { siteLogo } = useSelector((state) => state.renderUIDetails);

  useEffect(() => {
    loader.start(1);
    axios({
      url: "/brand?businessEntityId=" + businessEntityID,
    }).then((response) => {
      if (response.status) {
        let brandList = [];
        for (const brand of response?.data) {
          brandList.push({
            ID: brand.brand_id,
            about: brand.about,
            brandName: brand.brand_name,
            logo: !!brand.logo
              ? brand.logo
              : "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/NaN_logo.png",
            languages: brand.languages,
            locations: brand.audience_location,
            category: brand.site_category,
            mau: brand.mau,
            onlineStore: brand.online_store,
            website: brand.website,
            socialMedia: brand.social_media_handles,
            brandType: brand.brand_type_id,
            isBrandVerified: brand.is_brand_verified,
          });
        }

        setBrands(brandList);
        loader.apiComplete();
      }
    });
  }, [axios, businessEntityID, loader]);

  return (
    <Wrapper>
      <div className="wrapper-container">
        <div style={{ textAlign: "center" }}>
          <img className="logo-image" src={siteLogo} alt="logo" />
          {brands.length > 0 ? (
            <Fragment>
              <Typography className="who">Who's using?</Typography>
              <Typography className="choose-brand">
                Please choose your preferred brand and indicate your role for
                seamless processing of your selection.
              </Typography>
            </Fragment>
          ) : (
            <Fragment>
              <Typography className="who">Enhance your experience</Typography>
              <Typography className="choose-brand">
                Add brands to your account to better target your audience!
              </Typography>
            </Fragment>
          )}
        </div>
      </div>

      <Loader height={"75%"}>
        <SelectRole
          brands={brands}
          authToken={authToken}
          businessEntityID={businessEntityID}
          userID={userID}
        />
      </Loader>
    </Wrapper>
  );
}
