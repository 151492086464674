import React, { Fragment } from "react";

import { TextField } from "../../../../components";
import { Grid } from "@mui/material";

const TextFieldNumber = React.memo(
  ({ state, message, field, index, editState, dispatch }) => {
    const handleFieldChange = (value, name, key, index, num) => {
      dispatch({
        type: "UPDATE_NUMBER_FIELD",
        payload: { value, name, key, index, num },
      });
    };

    return (
      <Fragment>
        <Grid item xs={2}>
          <TextField
            size="small"
            value={state?.[field.field_name]?.org_trust}
            type="number"
            label={"Organisational Trust"}
            disabled={!editState}
            error={!!message.org_trust[index]}
            helperText={
              !!message.org_trust[index] && "It should be in between 0 & 5"
            }
            name={field.field_name}
            onChange={(e) =>
              handleFieldChange(
                e.target.value,
                field.field_name,
                "org_trust",
                index,
                5
              )
            }
          />
        </Grid>

        <Grid item xs={2}>
          <TextField
            size="small"
            type="number"
            value={state?.[field.field_name]?.cpm_price}
            label={"CPM Price"}
            disabled={!editState}
            error={!!message.cpm_price[index]}
            helperText={
              !!message.cpm_price[index] && "It should be in between 0 & 1000"
            }
            name={field.field_name}
            onChange={(e) =>
              handleFieldChange(
                e.target.value,
                field.field_name,
                "cpm_price",
                index,
                1000
              )
            }
          />
        </Grid>
      </Fragment>
    );
  }
);

export default TextFieldNumber;
