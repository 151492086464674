import { CgEye } from "react-icons/cg";
import { MdEdit } from "react-icons/md";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { titleCase } from "change-case-all";
import { Chip, Grid, IconButton, Tooltip, Typography } from "@mui/material";

import pages from "../../../constants/pages";
import {
  HeadingEighteen,
  TextFourteenBlack,
  colors,
} from "../../css components/Style";
import Paper from "../../../components/Paper";

const BrandName = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  opacity: 0.4;
  text-transform: capitalize;
`;

const Icon = styled(IconButton)`
  border: 1px solid ${colors.purple};
  color: ${colors.purple};
  height: 35px;
  width: 35px;
  margin: 0 5px;
  padding: 0 5px;
  &:hover {
    border: 1px solid ${colors.purple};
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
`;

export default function List({ campaigns, component, categorize }) {
  const navigate = useNavigate();

  function convertDate(d) {
    let date = new Date(d);

    return (
      date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
    );
  }

  function handleRedirection(campaign, mode) {
    if (component === "request") {
      navigate(
        pages.campaignRequestDetails.route +
          campaign.sender_campaignId +
          "?brandID=" +
          campaign.sender_brandId +
          "&mode=" +
          mode
      );
    }

    if (component === "superadmin") {
      navigate(
        pages.superAdminCampaignDetails.route +
          campaign.campaign_id +
          "?brandID=" +
          campaign.brand_id +
          "&mode=" +
          mode,
        { state: { categorize: categorize } }
      );
    }

    if (component === "accepted") {
      navigate(
        pages.campaignAcceptedDetails.route +
          campaign.campaign_id +
          "?brandID=" +
          campaign.brand_id +
          "&mode=" +
          mode
      );
    }

    if (component === "") {
      navigate(
        pages.campaignDetails.route + campaign.campaign_id + "?mode=" + mode
      );
    }
  }

  const onImageError = (e) => {
    e.target.src =
      "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_placeholder.png";
  };

  return (
    <Grid container spacing={2}>
      {campaigns.map((campaign) => (
        <Grid item xs={12}>
          <Paper style={{ padding: "0px" }} noGrid={true}>
            <Grid container spacing={0}>
              <Grid item alignSelf="center" style={{ paddingRight: "20px" }}>
                <div style={{ position: "relative", height: "115.8px" }}>
                  <img
                    className="campaign-img"
                    src={
                      campaign.previewImage ||
                      "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_placeholder.png"
                    }
                    onError={onImageError}
                    alt="!"
                  />
                  <StatusChip campaign={campaign} />
                </div>
              </Grid>

              <Grid item xs style={{ padding: "10px 10px 0" }}>
                <Grid container spacing={3}>
                  <Grid item>
                    <HeadingEighteen color={colors.purple}>
                      {campaign.campaign_name
                        ? titleCase(campaign.campaign_name)
                        : "N.A."}
                    </HeadingEighteen>
                  </Grid>

                  <Grid item xs />

                  <Grid item>
                    {component !== "" && (
                      <BrandName style={{ textAlign: "end" }}>
                        {campaign.brand.name}
                      </BrandName>
                    )}
                    {component !== "" && campaign.brand.agency && (
                      <BrandName>Agency :{campaign.brand.agency}</BrandName>
                    )}
                  </Grid>

                  <Grid item xs={12} />

                  <Grid item xs={3}>
                    <TextFourteenBlack>Campaign Period</TextFourteenBlack>
                    <HeadingEighteen>
                      {!!campaign.start_duration
                        ? convertDate(campaign.start_duration) +
                          " - " +
                          convertDate(campaign.end_duration)
                        : "N.A."}
                    </HeadingEighteen>
                  </Grid>

                  <Grid item xs={2}>
                    <TextFourteenBlack>Conversion Type</TextFourteenBlack>
                    <HeadingEighteen>
                      {campaign.conversion_type || "N.A."}
                    </HeadingEighteen>
                  </Grid>

                  <Grid item xs={3}>
                    <TextFourteenBlack>
                      {campaign.status === "ACTIVE"
                        ? "Final User Count"
                        : "Expected User Count"}
                    </TextFourteenBlack>
                    <HeadingEighteen>
                      {campaign.user_Count || "N.A"}
                    </HeadingEighteen>
                  </Grid>

                  <Grid item xs={2}>
                    <TextFourteenBlack>Budget</TextFourteenBlack>
                    <HeadingEighteen>
                      {campaign.min_budget + "-" + campaign.max_budget || "N.A"}
                    </HeadingEighteen>
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    alignSelf={"center"}
                    display={"flex"}
                    justifyContent={"flex-end"}
                  >
                    <Tooltip title="View Campaign">
                      <Icon>
                        <CgEye
                          onClick={() => handleRedirection(campaign, "view")}
                          style={{ height: "20px", width: "20px" }}
                        />
                      </Icon>
                    </Tooltip>

                    {component === "" &&
                      campaign.status !== "ACTIVE" &&
                      campaign.status !== "INITIATED" && (
                        <Tooltip title="Edit Campaign">
                          <Icon
                            onClick={() => handleRedirection(campaign, "edit")}
                          >
                            <MdEdit style={{ height: "20px", width: "20px" }} />
                          </Icon>
                        </Tooltip>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );

  function StatusChip({ campaign }) {
    let color;
    if (campaign.status === "REQUESTED" || campaign.status === "CREATED") {
      color = colors.yellow;
    }

    if (campaign.status === "OFFER_ACCEPTED" || campaign.status === "ACTIVE") {
      color = colors.green;
    }

    if (campaign.status === "INITIATED") {
      color = colors.purple;
    }

    if (campaign.status === "REJECTED") {
      color = colors.error;
    }

    return (
      <Chip
        style={{
          position: "absolute",
          left: "-13px",
          top: "9px",
          fontSize: "13px",
          backgroundColor: color,
          color: "#fff",
        }}
        label={campaign.status.replace(/_/g, " ")}
      />
    );
  }
}
