import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { CgEye } from "react-icons/cg";
import BrandDetailsDialog from "./brand details dialog/BrandDetailsDialog";

export default function RelatedBrandList({ formData, brands }) {
  const [brandData, setBrandData] = useState({
    data: {},
    state: false,
  });

  return (
    <Fragment>
      {brands.map((brand) => (
        <Grid item xs={6}>
          <div className="agencies-brand-div">
            <Typography className="brand-name-agency">
              {brand.brandName}
            </Typography>

            <Tooltip title="View Brand Details">
              <IconButton
                onClick={() =>
                  setBrandData({ component: "", state: true, data: brand })
                }
              >
                <CgEye className="eye-icon" />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>
      ))}

      <BrandDetailsDialog details={brandData} setDetails={setBrandData} />
    </Fragment>
  );
}
