import React from "react";
import Table from "mui-datatables";
import styled from "styled-components/macro";
import { get } from "lodash";

const MUIDataTable = styled(Table)`
  .MuiTableCell-head {
    text-transform: capitalize;
    background: ${({ theme }) => {
      return theme.palette.background.default;
    }};
  }

  .MuiTableCell-body {
    cursor: ${(props) =>
      Boolean(
        get(props, "options.onRowClick") || get(props, "options.onCellClick")
      )
        ? "pointer"
        : ""};
  }
`;

export default function DataTable(props) {
  const options = {
    selectableRows: "none",
    rowsPerPageOptions: [10, 25, 50, 100],
    enableNestedDataAccess: ".",
    elevation: 1,
    responsive: "simple",
    tableBodyMaxHeight: "100vh",
    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
  };

  return (
    <React.Fragment>
      <MUIDataTable {...props} options={{ ...options, ...props.options }} />
    </React.Fragment>
  );
}
