import { Avatar } from "@mui/material";

export default function TextAvatar({
  userName,
  size,
  borderRadius = "50%",
  ...props
}) {
  const userInitials =
    (userName?.split(" ")?.[0]?.[0] || "") +
    (userName?.split(" ")?.[1]?.[0] || "");

  const colorIndex =
    ((userInitials.charCodeAt(0) || 0) + (userInitials.charCodeAt(1) || 0)) % 4;

  return (
    <Avatar
      style={{
        padding: "5px 5px",
        cursor: props.onClick && "pointer",
        height: size?.height || "40px",
        width: size?.width || "40px",
        fontSize: size?.fontSize || "15px",
        lineHeight: size?.lineHeight || "31px",
        fontWeight: size?.fontWeight || "500",
        color: size?.color || "#fff",
        textTransform: "uppercase",
        opacity: 0.8,
        background: backgroundColors[colorIndex],
        borderRadius: borderRadius,
      }}
      {...props}
    >
      {userInitials}
    </Avatar>
  );
}

const backgroundColors = ["#0190d0", "#901d81", "#f7b519", "#28967e"];
