import { useDispatch } from "react-redux";
import { Fragment, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { CssBaseline } from "@mui/material";

import GlobalStyle from "../components/GlobalStyle";
import { renderUserInterface } from "../redux/slices/renderUISlice";

export default function BlankLayout() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(renderUserInterface());
  }, [dispatch]);

  return (
    <Fragment>
      <CssBaseline />
      <GlobalStyle />

      <Outlet />
    </Fragment>
  );
}
