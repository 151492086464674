import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { IoIosArrowForward } from "react-icons/io";

import {
  TextFourteenBlack,
  TextFifteenYellow,
  colors,
} from "../../css components/Style";
import pages from "../../../constants/pages";
import { Paper, TextAvatar } from "../../../components";

const Link = styled(Typography)`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: ${colors.purple};
  padding-right: 27px;
  cursor: pointer;
  padding-right: 0px;
`;

const ImageDiv = styled.img`
  height: 60px;
  width: 60px;
`;

const ArrowIcon = styled(IoIosArrowForward)`
  position: relative;
  top: 6px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: ${colors.purple};
  cursor: pointer;
`;

export const HeadingEighteen = styled(Typography)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 15px;
  overflow-wrap: break-word;
`;

export default function UserList({ usersData }) {
  const navigate = useNavigate();

  return (
    <Grid container spacing={3}>
      {usersData.users.map((user) => (
        <Grid item xs={12} key={user.user_id}>
          <Paper style={{ padding: "0px" }} noGrid={true}>
            <Grid container spacing={0}>
              <Grid item alignSelf="center" paddingRight="25px" pl="25px">
                {user.logo ? (
                  <ImageDiv src={usersData.brand.brand_logo} alt="!" />
                ) : (
                  <TextAvatar
                    size={{
                      height: "60px",
                      width: "60px",
                      fontSize: "20px",
                    }}
                    borderRadius={"50%"}
                    userName={user.given_name}
                  />
                )}
              </Grid>

              <Grid item xs style={{ padding: "10px" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} justifyContent={"end"} display="flex">
                    <svg
                      style={{ position: "relative", margin: "9px 9px" }}
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="5"
                        cy="5"
                        r="5"
                        fill={
                          user.account_status === 0
                            ? "#FFBB0B"
                            : user.account_status === 1
                            ? "#5AC35F"
                            : "#FF460B"
                        }
                      />
                    </svg>
                    <TextFifteenYellow
                      color={
                        user.account_status === 0
                          ? "#FFBB0B"
                          : user.account_status === 1
                          ? "#5AC35F"
                          : "#FF460B"
                      }
                    >
                      {user.account_status === 0 && "Unverified"}
                      {user.account_status === 1 && "Accepted"}
                      {user.account_status === 2 && "Blocked"}
                      {user.account_status === 3 && "Rejected"}
                      {user.account_status === 4 && "Suspended"}
                      {user.account_status === 5 && "Deleted"}
                    </TextFifteenYellow>
                  </Grid>

                  <Grid item xs={2.5}>
                    <TextFourteenBlack> User Name </TextFourteenBlack>
                    <HeadingEighteen>{user.given_name}</HeadingEighteen>
                  </Grid>

                  <Grid item xs={2.5}>
                    <TextFourteenBlack> Brand </TextFourteenBlack>
                    <HeadingEighteen>
                      {usersData.brand.brand_name}
                    </HeadingEighteen>
                  </Grid>

                  <Grid item xs={2.5}>
                    <TextFourteenBlack>Role</TextFourteenBlack>
                    <HeadingEighteen>{user.brand_user_role}</HeadingEighteen>
                  </Grid>

                  <Grid item xs={2.5}>
                    <TextFourteenBlack>Email</TextFourteenBlack>
                    <HeadingEighteen>{user.email}</HeadingEighteen>
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    alignSelf={"end"}
                    justifyContent="end"
                    display="flex"
                    alignSelf={"center"}
                  >
                    <Link
                      onClick={() =>
                        navigate(pages.userDetails.route + user.user_id)
                      }
                    >
                      Manage
                    </Link>

                    <ArrowIcon />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}
