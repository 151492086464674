import { Grid, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";

import Transaction from "./Transaction";
import AddMoneyDialog from "./AddMoneyDialog";
import { Loader, PageHeader } from "../../components";
import { useAxios, useComponent } from "../../hooks";
import { PrimaryButton } from "../css components/Button";

export default function Credit() {
  const axios = useAxios();
  const { loader } = useComponent();

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState({ totalCount: 0, lastPage: false });

  const [addMoney, setAddMoney] = useState(false);
  const [creditInfo, setCreditInfo] = useState({
    coins: "0",
    creditHistory: [],
  });
  const [creditsAdded, setCreditAdded] = useState(false);

  useEffect(() => {
    loader.start();

    axios({
      url: `/GetWalletDetails?page=${page}&limit=25`,
    }).then((response) => {
      if (response.status) {
        if (
          response.data.walletWithHistory.credits[0]?.WalletHistory.length > 0
        ) {
          setCreditInfo({
            coins: response.data.walletWithHistory.credits[0]?.coins || "0",
            creditHistory:
              response.data.walletWithHistory.credits[0]?.WalletHistory,
          });

          setLastPage({
            totalCount: response.data.totalCount,
            lastPage:
              response.data.walletWithHistory.credits[0]?.WalletHistory.length <
              10,
          });
        } else {
          setCreditInfo({
            coins: "0",
            creditHistory: [],
          });

          setLastPage({ totalCount: response.data.totalCount, lastPage: true });
        }

        loader.stop();
      } else {
        loader.stop();
      }
    });
  }, [axios, page, loader]);

  useEffect(() => {
    if (creditsAdded) {
      axios({
        url: "/GetWalletDetails?page=" + page + "&limit=25",
      }).then((response) => {
        if (response.status) {
          if (
            response.data.walletWithHistory.credits[0]?.WalletHistory.length > 0
          ) {
            setCreditInfo({
              coins: response.data.walletWithHistory.credits[0]?.coins || "0",
              creditHistory:
                response.data.walletWithHistory.credits[0]?.WalletHistory,
            });

            setLastPage({
              totalCount: response.data.totalCount,
              lastPage:
                response.data.walletWithHistory.credits[0]?.WalletHistory
                  .length < 10,
            });

            setCreditAdded(false);
          } else {
            setCreditInfo({
              coins: "0",
              creditHistory: [],
            });

            setLastPage(true);

            setCreditAdded(false);
          }
        }
      });
    }
  }, [creditsAdded, page, axios]);

  return (
    <Fragment>
      <Loader>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PageHeader
              pageName={"Credits"}
              pageHeader="credits"
              buttons={[
                <PrimaryButton
                  onClick={() => setAddMoney(true)}
                  variant="contained"
                >
                  Add Credits
                </PrimaryButton>,
              ]}
            />
          </Grid>

          <Grid item xs={12}>
            <div className="credit-box">
              <Typography style={{ fontSize: "18px" }}>
                Current Credits &nbsp;
                {creditInfo.coins}
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Transaction
              creditInfo={creditInfo}
              page={page}
              setPage={setPage}
              lastPage={lastPage}
            />
          </Grid>
        </Grid>
      </Loader>

      <AddMoneyDialog
        setCreditAdded={setCreditAdded}
        addMoney={addMoney}
        setAddMoney={setAddMoney}
      />
    </Fragment>
  );
}
