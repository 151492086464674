import React from "react";

import { TextField } from "../../../../components";

const Input = React.memo(({ state, field, editState, dispatch }) => {
  const handleFieldChange = (value, name, key) => {
    dispatch({ type: "UPDATE_FIELD", payload: { value, name, key } });
  };

  // function debounce(func, timeout = 2000) {
  //   let timer;
  //   return (...args) => {
  //     clearTimeout(timer);
  //     timer = setTimeout(() => {
  //       func.apply(this, args);
  //     }, timeout);
  //   };
  // }

  // const processChange = debounce((...args) =>
  //   handleFieldChange(args[0], args[1], args[2])
  // );

  return (
    <TextField
      size="small"
      name={field.field_name}
      label={field.field_label}
      value={state?.[field.field_name]?.value}
      placeholder={"Enter " + field.field_label}
      inputProps={{
        onChange: (e) =>
          handleFieldChange(e.target.value, field.field_name, "value"),
      }}
      disabled={!editState}
    />
  );
});
export default Input;
