import React from "react";
import { MenuItem } from "@mui/material";

import { TextField } from "../../../../components";

const SingleDropdown = React.memo(({ field, state, editState, dispatch }) => {
  const handleFieldChange = (value, name, key) => {
    dispatch({ type: "UPDATE_FIELD", payload: { value, name, key } });
  };

  return (
    <TextField
      size="small"
      value={state?.[field.field_name]?.type}
      label={"Select Field Type"}
      disabled={!editState}
      select
      onChange={(e) =>
        handleFieldChange(e.target.value, field.field_name, "type")
      }
    >
      <MenuItem disabled value={undefined}>
        Select Value
      </MenuItem>
      <MenuItem value="string">String</MenuItem>
      <MenuItem value="number">Number</MenuItem>
      <MenuItem value="date">Date</MenuItem>
    </TextField>
  );
});

export default SingleDropdown;
