import { Fragment } from "react";
import { Grid, Button, Typography as MuiTypography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BiErrorCircle } from "react-icons/bi";

const Icon = styled(BiErrorCircle)`
  width: 100px;
  height: 100px;
  color: red;
`;

const Typography = styled(MuiTypography)`
  text-align: center;
`;

export default function APIError({ disableHomeButton }) {
  const loader = useSelector((state) => state.loaderDetails);
  const navigate = useNavigate();

  return (
    <Fragment>
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <Icon />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5">
            Something went wrong while loading the data.
          </Typography>
        </Grid>

        {!!loader.traceID && (
          <Grid item xs={12}>
            <Typography variant="h4" color="primary">
              Trace ID for the failed API is <strong>{loader.traceID}</strong>
            </Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Please try again or contact admin for more details!
          </Typography>
        </Grid>

        {!disableHomeButton && (
          <Grid item>
            <Button variant="contained" onClick={() => navigate("/")}>
              Home
            </Button>
          </Grid>
        )}

        <Grid item>
          <Button variant="contained" onClick={() => window.location.reload()}>
            Retry
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
}
