import { useEffect, useState } from "react";

import { useAxios, useComponent } from "../../hooks";
import Form from "./dynamic ui details components/Form";
import {
  dynamicUiObjects,
  objectFromResponse,
} from "./dynamic ui details components/dynamicUiObjects";
import { Loader } from "../../components";

export default function DynamicUI() {
  const axios = useAxios();
  const { loader } = useComponent();

  const [editState, setEditState] = useState(false);
  const [formData, setFormData] = useState(dynamicUiObjects);

  useEffect(() => {
    loader.start();
    axios({
      url: "get/dynamicDashboard",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setFormData(objectFromResponse(response.data));
        loader.stop();
      }
    });
  }, [axios, loader]);

  return (
    <Loader>
      <Form
        formData={formData}
        setFormData={setFormData}
        editState={editState}
        setEditState={setEditState}
      />
    </Loader>
  );
}
