import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";

import { Loader, Paper } from "../../components";
import pages from "../../constants/pages";
import { useAxios, useComponent } from "../../hooks";
import { alert } from "../../redux/slices/alertSlice";
import Form from "./brand details components/Form";
import {
  brandDetailsObject,
  objectFromResponse,
} from "./brand details components/brandDetailObject";
import { signInMember, signOutMember } from "../../redux/slices/memberSlice";
import { colors } from "../css components/Style";
import { CancelButton } from "../css components/Button";

export default function AgencyEmptyDetails() {
  const axios = useAxios();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loader } = useComponent();

  const {
    isAdminVerified,
    parentBrandVerified,
    location,
    languages,
    categories,
    agencyID,
  } = useSelector((state) => state.memberDetails);

  const [editState, setEditState] = useState(false);
  const [formData, setFormData] = useState(brandDetailsObject);
  const [list, setList] = useState({
    categories: categories || [],
    locations: location || [],
    languages: languages || [],
  });

  const brandID =
    useSelector((state) => state.memberDetails?.activeBrand.ID) ||
    queryString.parse(window.location.search).brandID;

  const multiBrand = queryString.parse(window.location.search).newMultiBrand;

  useEffect(() => {
    if (!brandID) {
      setEditState(true);
    }
  }, [brandID]);

  useEffect(() => {
    loader.start(!isAdminVerified || parentBrandVerified === 0 ? 4 : 1);

    if (!isAdminVerified || parentBrandVerified === 0) {
      axios({
        url: "/brand/getAllBusinessCategories",
        method: "GET",
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          setList((prev) => ({
            ...prev,
            categories: response.data,
          }));
          loader.apiComplete();
        }
      });

      axios({
        url: "/getAllCountries",
        method: "GET",
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          setList((prev) => ({
            ...prev,
            locations: response.data,
          }));
          loader.apiComplete();
        }
      });

      axios({
        url: "/getAllLanguages",
        method: "GET",
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          setList((prev) => ({
            ...prev,
            languages: response.data,
          }));
          loader.apiComplete();
        }
      });
    }

    if (!!brandID && !multiBrand) {
      axios({
        url: "/getBrand/" + brandID,
        method: "GET",
      }).then((response) => {
        if (response.status) {
          if (response.data?.is_brand_verified === 2) {
            dispatch(
              alert({
                type: "info",
                message: "Brand has been rejected by the admin!",
              })
            );
            dispatch(signInMember());
            navigate(pages.signIn.route);
          }
          setFormData(objectFromResponse(response.data));
          loader.apiComplete(1);
        }
      });
    } else {
      loader.apiComplete();
    }
  }, [
    multiBrand,
    axios,
    brandID,
    loader,
    parentBrandVerified,
    isAdminVerified,
    dispatch,
    navigate,
  ]);

  return (
    <Loader>
      <Paper>
        <Grid item xs>
          <Typography variant="h4" color={colors.green}>
            You haven't created brand for this agency, Please create a brand to
            continue
          </Typography>
        </Grid>

        <Grid item>
          <CancelButton
            variant="outlined"
            onClick={() => {
              dispatch(signOutMember());
              navigate(pages.signIn.route);
            }}
          >
            Logout
          </CancelButton>
        </Grid>
      </Paper>

      <Form
        formData={formData}
        editState={editState}
        setEditState={setEditState}
        list={list}
        setFormData={setFormData}
      />
    </Loader>
  );
}
