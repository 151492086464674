import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import { Button, Menu, MenuItem } from "@mui/material";
import {
  CallToAction,
  Block,
  KeyboardArrowDown,
  Quickreply,
} from "@mui/icons-material";
import _ from "lodash";
import { colors } from "../../../css components/Style";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function ActionButtons({ formikProps, addSample }) {
  const [anchorEl, setAnchorEl] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    var buttonType = event.target.getAttribute("value");
    if (Boolean(buttonType)) {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        templateButtons: setButtonType(prevVal.templateButtons),
      }));

      function setButtonType(templateButtons) {
        templateButtons.type = buttonType;
        if (buttonType === "call_to_action") {
          templateButtons.buttons = [];
          templateButtons.buttons.push({
            button_type: "",
            text: "",
            payload: "",
            url_type: "",
          });
        } else if (buttonType === "quick_reply") {
          templateButtons.buttons = [];
          templateButtons.buttons.push({
            text: "",
          });
        } else {
          templateButtons = {};
        }

        return templateButtons;
      }
    } else {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        templateButtons: setButtonType(prevVal.templateButtons, prevVal),
      }));
      function setButtonType(templateButtons, prevVal) {
        templateButtons = prevVal.templateButtons;
        return templateButtons;
      }
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event, value) => {
    var buttonType = event.target.getAttribute("value");
    if (Boolean(buttonType)) {
      if (buttonType === "none") buttonType = null;
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        templateButtons: setButtonType(prevVal.templateButtons),
      }));
      function setButtonType(templateButtons) {
        templateButtons.type = buttonType;
        if (buttonType === "call_to_action") {
          templateButtons.buttons = [];
          templateButtons.buttons.push({
            button_type: "",
            text: "",
            payload: "",
            url_type: "",
          });
        } else if (buttonType === "quick_reply") {
          templateButtons.buttons = [];
          templateButtons.buttons.push({
            text: "",
          });
        } else {
          templateButtons = {};
        }
        return templateButtons;
      }
    } else {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        templateButtons: setButtonType(prevVal.templateButtons, prevVal),
      }));
      function setButtonType(templateButtons, prevVal) {
        templateButtons = prevVal.templateButtons;
        return templateButtons;
      }
    }
    setAnchorEl(false);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="outlined"
        disableElevation
        disabled={Boolean(formikProps.values.ID) || Boolean(addSample)}
        onClick={handleClick}
        endIcon={<KeyboardArrowDown />}
      >
        {Boolean(formikProps.values?.templateButtons?.type)
          ? _.startCase(formikProps.values?.templateButtons?.type)
          : "None"}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          value={"call_to_action"}
          onClick={(e, v) => handleClose(e, v)}
          disableRipple
        >
          <div style={{ width: "25px" }}>
            <CallToAction style={{ color: colors.purple }} />
          </div>
          Call To Action
          <br />
          This needs phone number, mail or url to take action.
        </MenuItem>
        <MenuItem value={"quick_reply"} onClick={handleClose} disableRipple>
          <div style={{ width: "25px" }}>
            <Quickreply style={{ color: colors.purple }} />
          </div>
          Quick Reply
          <br />
          This will be predefined payloads as quick replies to bot.
        </MenuItem>

        <MenuItem
          value={"none"}
          onClick={(e, v) => handleClose(e, v)}
          disableRipple
          selected
        >
          <div style={{ width: "25px" }}>
            <Block style={{ color: colors.purple }} />
          </div>
          None
          <br />
          There will be no buttons in your template.
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
