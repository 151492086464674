import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  CancelButton,
  Accept,
  Reject,
  PrimaryButton,
  PrimaryLoadingButton,
} from "../../css components/Button";
import { useAxios, useComponent } from "../../../hooks";
import AudienceSizeDialog from "./AudienceSizeDialog";
import pages from "../../../constants/pages";

export default function ActionButton({
  ID = "",
  editState,
  setEditState,
  formikProps,
  audienceSizing,
  setAudienceSizing,
}) {
  const axios = useAxios();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { alert } = useComponent();

  const [audienceSize, setAudienceSize] = useState({
    state: false,
  });
  const [isdownloadAudience, setIsDownloadAudience] = useState(false);

  const [loading, setLoading] = useState({
    REJECTED: false,
    APPROVE: false,
    PENDING: false,
  });

  function handleCancel() {
    if (editState && !!ID) {
      setEditState(!editState);
      formikProps.handleReset();
    } else {
      navigate(-1);
    }
  }

  function downloadAudience() {
    setIsDownloadAudience(true);
    axios({
      url: "/download/audience/" + formikProps.values.audienceID,
    }).then((response) => {
      if (response.status) {
        window.location.href = response.data;
        dispatch(alert.success("Audience File downloaded successfully!"));
      }
      setIsDownloadAudience(false);
    });
  }

  return (
    <Fragment>
      <CancelButton
        onClick={handleCancel}
        variant="outlined"
        style={{ marginRight: "10px" }}
      >
        {"Back"}
      </CancelButton>

      <PrimaryLoadingButton
        loading={isdownloadAudience}
        style={{ marginRight: "10px" }}
        size="large"
        variant="contained"
        onClick={() => downloadAudience()}
      >
        Download Audience
      </PrimaryLoadingButton>

      {formikProps.values.status === "ACTIVE" && (
        <PrimaryButton
          loading={isdownloadAudience}
          style={{ marginRight: "10px" }}
          size="large"
          variant="contained"
          onClick={() =>
            navigate(
              pages.addCampaignStats.route + formikProps.values.campaignID
            )
          }
        >
          Add Campaign Stats
        </PrimaryButton>
      )}
      {formikProps.values.permissionSettings?.set_audience_size && (
        <PrimaryButton
          style={{ marginRight: "10px" }}
          size="large"
          variant="contained"
          onClick={() =>
            setAudienceSize({
              ...audienceSize,
              state: true,
            })
          }
        >
          Set Audience Size
        </PrimaryButton>
      )}
      {formikProps.values.campaignAdminStatus === 0 && (
        <Reject
          style={{ marginRight: "10px" }}
          size="large"
          variant="contained"
          loading={loading.REJECTED}
          onClick={() => handleCampaignStatus("REJECTED")}
        >
          Reject
        </Reject>
      )}
      {/* {formikProps.values.campaignAdminStatus === 1 && (
        <Pending
          style={{ marginRight: "10px" }}
          size="large"
          loading={loading.PENDING}
          variant="contained"
        >
          Pause
        </Pending>
      )} */}
      {formikProps.values.campaignAdminStatus === 0 && (
        <Accept
          style={{ marginRight: "10px" }}
          size="large"
          variant="contained"
          loading={loading.APPROVE}
          onClick={() => handleCampaignStatus("APPROVE")}
        >
          Active
        </Accept>
      )}
      <AudienceSizeDialog
        audienceSizing={audienceSizing}
        formikProps={formikProps}
        audienceSize={audienceSize}
        setAudienceSize={setAudienceSize}
        setAudienceSizing={setAudienceSizing}
      />
    </Fragment>
  );

  function handleCampaignStatus(status) {
    setLoading((prev) => ({
      ...prev,
      [status]: true,
    }));
    axios({
      url: "/user/campaigns/changeAdminCampaignStatus",
      method: "PUT",
      disableRedirect: true,
      data: {
        campaignId: formikProps.values.campaignID,
        status: status,
      },
    }).then((response) => {
      if (response.status) {
        formikProps.setValues((prev) => ({
          ...prev,
          campaignAdminStatus: 1,
        }));

        dispatch(alert.success(`Campaign ${status} successfully!`));
      }

      setLoading((prev) => ({
        ...prev,
        [status]: true,
      }));
    });
  }
}
