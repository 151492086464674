import React from "react";
import { Grid, MenuItem } from "@mui/material";

import Paper from "./../../../../components/Paper";
import TextField from "../../../../components/TextField";
import ComponentHeader from "./../../../../components/ComponentHeader";
import TextFieldWithCopy from "./../../../../components/TextFieldWithCopy";

export default function FormDisplay({ formikProps, editState }) {
  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          headerName="Bot Details"
          id={formikProps.values.botID}
        />
      </Grid>

      <Grid item xs={6}>
        <TextFieldWithCopy
          label="Agent ID"
          name="agentID"
          disabled
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Phone Number ID"
          name="phoneNumberID"
          required
          formikProps={formikProps}
          disabled={!editState}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          select
          label="Channel"
          name="channel"
          required
          formikProps={formikProps}
          disabled={!editState}
        >
          <MenuItem value={"whatsapp"}>WhatsApp</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs={6}>
        <TextField
          select
          label="Bot Status"
          name="isActive"
          required
          formikProps={formikProps}
          disabled={!editState}
        >
          <MenuItem value={true}>Active</MenuItem>
          <MenuItem value={false}>Inactive</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="WABA ID"
          name="wabaID"
          required
          formikProps={formikProps}
          disabled={!editState}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          select
          label="Cloud API"
          name="cloudAPI"
          required
          formikProps={formikProps}
          disabled={!editState}
        >
          <MenuItem value={true}>True</MenuItem>
          <MenuItem value={false}>False</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Namespace"
          name="namespace"
          disabled
          formikProps={formikProps}
        />
      </Grid>
    </Paper>
  );
}
