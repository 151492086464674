import { useDispatch } from "react-redux";
import { Fragment, useEffect } from "react";
import { CssBaseline } from "@mui/material";
import styled from "styled-components/macro";
import { Navigate, Outlet } from "react-router-dom";
import PerfectScrollBar from "react-perfect-scrollbar";

import pages from "../constants/pages";
import GlobalStyle from "../components/GlobalStyle";
import { renderUserInterface } from "../redux/slices/renderUISlice";

// 85px
const ScrollBar = styled(PerfectScrollBar)`
  height: calc(100% - 8px) !important;
  display: flex;

  > * {
    flex: 1;
  }
`;

const MainContentWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export default function EmptyLayout() {
  const dispatch = useDispatch();
  const sessionStorageToken = sessionStorage.getItem("authToken");
  const businessEntityId = sessionStorage.getItem("businessEntityId");

  useEffect(() => {
    dispatch(renderUserInterface());
  }, [dispatch]);

  if (!(sessionStorageToken && businessEntityId)) {
    return <Navigate to={`${pages.signIn.route}`} />;
  }

  return (
    <Fragment>
      <CssBaseline />
      <GlobalStyle />

      {/* <Navbar /> */}

      <ScrollBar>
        <MainContentWrapper>
          <Outlet />
        </MainContentWrapper>
      </ScrollBar>
    </Fragment>
  );
}
