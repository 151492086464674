import { Box } from "@mui/material";
import styled from "styled-components/macro";
import { useOutlet } from "react-router-dom";

const Wrapper = styled(Box)`
  flex-grow: 1;
  padding: 10px;
`;

export default function MainContent() {
  const element = useOutlet();

  // const { pageID, disableAuth } = get(
  //   element,
  //   "props.children.props.children.props",
  //   ""
  // );

  return <Wrapper>{element}</Wrapper>;
}
