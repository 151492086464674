import { useMemo, useRef, useState } from "react";

import { Grid } from "@mui/material";

import FormOne from "./FormOne";
import FormTwo from "./FormTwo";
import FormThree from "./FormThree";
import {
  ButtonFooter,
  ComponentHeader,
  PageHeader,
  Paper,
} from "../../../components";
import FloatingEditActionButtons from "../../../components/FloatingEditActionButton";
import EditButtons from "../../../components/EditButtons";
import { CancelButton } from "../../css components/Button";
import { useNavigate } from "react-router-dom";
import { ConstructionOutlined } from "@mui/icons-material";

export default function FormDisplay({
  edit,
  fields,
  setEdit,
  editState,
  setEditState,
  formikProps,
  component,
  categories,
  location,
  languages,
  sliderValue,
  setSliderValue,
  dialogDetails,
  handleClose,
  percentageSliderValue,
  setPercentageSliderValue,
}) {
  let mandatoryTrueCount = 0;

  let mandatoryFalseCount = 0;

  let count = useRef(true);
  const navigate = useNavigate();

  let [oldMandatoryFalseCount, setOldMandatoryFalseCount] = useState(0);

  for (const key in formikProps.values) {
    if (
      formikProps.values.hasOwnProperty(key) &&
      typeof formikProps.values[key] === "object" &&
      formikProps.values[key].hasOwnProperty("mandatory") &&
      formikProps.values[key]?.value !== undefined &&
      formikProps.values[key]?.value !== null &&
      formikProps.values[key]?.value !== "" &&
      formikProps.values[key]?.value.length !== 0 &&
      formikProps.values[key]?.value.min !== ""
    ) {
      if (
        key !== "audience_group" &&
        key !== "percentage" &&
        key !== "approx_mau"
      ) {
        if (formikProps.values[key].mandatory === false) {
          mandatoryFalseCount++;
        } else {
          mandatoryTrueCount++;
        }
      }
    }
  }

  const handleMandatoryTrueCount = useMemo(() => {
    formikProps.setFieldValue("mandatoryTrueCount", mandatoryTrueCount);
  }, [mandatoryTrueCount]);

  return (
    <Grid container spacing={2}>
      {handleMandatoryTrueCount}
      <Grid item xs={12}>
        {component !== "dialog" && (
          <PageHeader
            pageName={"Audience"}
            pageHeader={
              component === ""
                ? "Let's create audience group"
                : formikProps.values.audience_group?.value
            }
            buttons={[
              formikProps.values.isEditable ? (
                <EditButtons
                  editState={editState}
                  setEditState={setEditState}
                />
              ) : (
                <CancelButton variant="outlined" onClick={() => navigate(-1)}>
                  Back
                </CancelButton>
              ),
            ]}
          />
        )}
      </Grid>

      {/* {component === "dialog" && (
          <Fragment>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#02a0fc",
                }}
              >
                Audience Details
              </Typography>
            </Grid>

            <Grid item xs={6} textAlign="end">
              {dialogDetails.open === "create" && (
                <Button
                  size="large"
                  variant="contained"
                  style={{ borderRadius: "40px" }}
                  onClick={formikProps.handleSubmit}
                  disabled={!formikProps.isValid}
                >
                  Save
                </Button>
              )}
            </Grid>
          </Fragment>
        )} */}

      <Grid item xs={12}>
        <Paper gridSpacing={6}>
          <Grid item xs={12}>
            <ComponentHeader
              title={"Audience"}
              subtitle={
                formikProps.values.ID
                  ? "Audience group details"
                  : "Let's create audience group"
              }
            />
          </Grid>

          <FormOne
            fields={fields}
            formikProps={formikProps}
            editState={editState}
            setEditState={setEditState}
            edit={edit}
            setEdit={setEdit}
            mandatoryFalseCount={mandatoryFalseCount}
            percentageSliderValue={percentageSliderValue}
            count={count}
            setPercentageSliderValue={setPercentageSliderValue}
            oldMandatoryFalseCount={oldMandatoryFalseCount}
            setOldMandatoryFalseCount={setOldMandatoryFalseCount}
          />

          <FormTwo
            fields={fields}
            categories={categories}
            location={location}
            languages={languages}
            formikProps={formikProps}
            editState={editState}
            setEditState={setEditState}
            setEdit={setEdit}
            sliderValue={sliderValue}
            setSliderValue={setSliderValue}
            mandatoryFalseCount={mandatoryFalseCount}
            percentageSliderValue={percentageSliderValue}
            count={count}
            setPercentageSliderValue={setPercentageSliderValue}
            oldMandatoryFalseCount={oldMandatoryFalseCount}
            setOldMandatoryFalseCount={setOldMandatoryFalseCount}
          />
        </Paper>

        <Paper>
          <FormThree formikProps={formikProps} editState={editState} />
        </Paper>

        {formikProps.values.isEditable && !Boolean(handleClose) && (
          <FloatingEditActionButtons
            ID={formikProps.values.campaignID}
            editState={editState}
            setEditState={setEditState}
            formikProps={formikProps}
          />
        )}

        {editState && (
          <Grid item xs={12}>
            <ButtonFooter
              ID={formikProps.values.ID}
              editState={editState}
              setEditState={setEditState}
              formikProps={formikProps}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
