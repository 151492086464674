import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { Paper as MuiPaper, Grid, Typography, Link } from "@mui/material";

import { colors } from "../../pages/css components/Style";

const Paper = styled(MuiPaper)`
  margin: 2px 10px;
  padding: 15px 10px;
`;

const GridContainer = styled(Grid)`
  height: 100%;
  align-items: center;
`;

export default function Footer() {
  const uiDetails = useSelector((state) => state.renderUIDetails);

  return (
    <Paper>
      <GridContainer container>
        <Grid item xs>
          <Link color={colors.purple} href="mailto:adtech@verismart.ai">
            Contact Us
          </Link>
        </Grid>

        <Grid item xs>
          <Typography variant="subtitle2" align="right">
            {`© ${new Date().getFullYear()} - ${uiDetails.siteCopyright}`}
          </Typography>
        </Grid>
      </GridContainer>
    </Paper>
  );
}
