import React from "react";
import "./WhatsappPreview.css";

import {
  FaGrinAlt,
  FaArrowLeft,
  FaPaperPlane,
  FaImage,
  FaPlayCircle,
  FaFileAlt,
} from "react-icons/fa";
import { BsFileEarmarkArrowDown } from "react-icons/bs";
import { Typography } from "@mui/material";

import parse from "html-react-parser";

export default function WhatsappPreview({ formikProps, values, component }) {
  var templateBodyMessage = formikProps.templateBody.text;
  var templateHeaderMessage = formikProps.templateHeader?.text;
  function textToHtmlFormat(string) {
    const htmlFormat = [
      {
        symbol: "*",
        tag: "b",
      },
      {
        symbol: "_",
        tag: "em",
      },
      {
        symbol: "~",
        tag: "del",
      },
      {
        symbol: "`",
        tag: "code",
      },
      {
        symbol: "\n",
        tag: "br",
      },
    ];

    htmlFormat.forEach(({ symbol, tag }) => {
      if (!string) return;

      const regex = new RegExp(`\\${symbol}([^${symbol}]*)\\${symbol}`, "gm");
      const match = string.match(regex);
      if (!match) return;

      match.forEach((m) => {
        let formatted = m;
        for (let i = 0; i < 2; i++) {
          formatted = formatted.replace(symbol, `<${i > 0 ? "/" : ""}${tag}>`);
        }
        string = string.replace(m, formatted);
      });
    });
    return string;
  }

  function handleClick(url) {
    window.location.href = url;
  }

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "sticky",
          top: 0,
          marginLeft: "10px",
        }}
      >
        <div className="outer-body" id="div">
          <div className="chat-box-whatsapp">
            <div className="user-bar">
              <div className="back">
                <FaArrowLeft />
              </div>
              <div className="user-avatar">
                <img
                  src="https://dc-chat-media.s3.ap-south-1.amazonaws.com/1c25502e214b4b21a5ff635d613d21a3/1632118671542/manAvatar2.jpg"
                  alt="1"
                />
              </div>

              <div className="Username">
                <Typography>Jhon Doe</Typography>
                <Typography className="status">online</Typography>
              </div>
            </div>

            <div className="conversation">
              <div className="message-box">
                {formikProps.templateHeader?.type === "text" &&
                  templateHeaderMessage}
                {formikProps.templateHeader?.type === "image" && (
                  <div align="center" className="img-preview">
                    {formikProps.templateHeader?.example.length === 0 ? (
                      <FaImage style={{ margin: "20px auto" }} />
                    ) : (
                      <img
                        src={formikProps.templateHeader.example[0].example}
                        alt={"."}
                        style={{
                          height: "inherit",
                          width: "inherit",
                        }}
                      />
                    )}
                  </div>
                )}

                {formikProps.templateHeader?.type === "video" && (
                  <div align="center" className="img-preview">
                    {formikProps.templateHeader?.example.length === 0 ? (
                      <FaPlayCircle style={{ margin: "20px auto" }} />
                    ) : (
                      <video
                        src={formikProps.templateHeader.example[0].example}
                        controls
                        style={{
                          height: "inherit",
                          width: "inherit",
                        }}
                      />
                    )}
                  </div>
                )}

                {formikProps.templateHeader?.type === "document" && (
                  <div align="center" class="img-preview">
                    {formikProps.templateHeader?.example.length === 0 ? (
                      <FaFileAlt style={{ margin: "20px auto" }} />
                    ) : (
                      <BsFileEarmarkArrowDown
                        style={{ marginTop: "20px", cursor: "pointer" }}
                        onClick={() => {
                          handleClick(
                            formikProps.templateHeader.example[0].example
                          );
                        }}
                      />
                    )}
                  </div>
                )}

                <Typography variant="body1" mt={2}>
                  {Boolean(formikProps.templateBody?.text) &&
                    parse(textToHtmlFormat(templateBodyMessage))}
                </Typography>

                <Typography id="whatsapp-demo-message-footer">
                  {formikProps.templateFooter?.text}
                </Typography>

                <div id="whatsapp-demo-message-button" align="center">
                  {formikProps.templateButtons?.buttons?.map((item, index) => (
                    <React.Fragment key={index}>
                      <Typography className="wt-buttons">
                        {item.text}
                      </Typography>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
            <div className="msg-bar">
              <div className="input-box">
                <FaGrinAlt
                  style={{ margin: "10px", color: "#6f7a7f" }}
                  className="grinEmoticon"
                />
                <Typography
                  style={{ color: "#6f7a7f" }}
                  className="text-input-box"
                >
                  Type a message
                </Typography>
              </div>
              <div className="send-button">
                <FaPaperPlane />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
