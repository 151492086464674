import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import styled from "styled-components/macro";
import { colors } from "./Style";

export const PrimaryButton = styled(Button)`
  font-size: 16px;
  font-weight: 500;
  height: 40px;
`;

export const PrimaryLoadingButton = styled(LoadingButton)`
  font-size: 16px;
  font-weight: 500;
  height: 40px;
`;

export const CancelButton = styled(Button)`
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  border-color: #bdbdbd;
  color: #bdbdbd;

  &:hover {
    border: 1px solid #bdbdbd;
  }
`;

export const CancelLoadingButton = styled(LoadingButton)`
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  border-color: #bdbdbd;
  color: #bdbdbd;

  &:hover {
    border: 1px solid #bdbdbd;
  }
`;

export const ErrorButton = styled(Button)`
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  border-color: ${colors.error};
  color: ${colors.error};

  &:hover {
    border: 1px solid ${colors.error};
  }
`;

export const Accept = styled(LoadingButton)`
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  background: ${colors.green};

  &:hover {
    background: ${colors.green};
  }
`;

export const Reject = styled(LoadingButton)`
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  color: #fff;
  background: ${colors.error};

  &:hover {
    background: ${colors.error};
  }
`;

export const Pending = styled(LoadingButton)`
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  background: ${colors.yellow};

  &:hover {
    background: ${colors.yellow};
  }
`;

export const AcceptUpload = styled(LoadingButton)`
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  background: ${colors.blue};

  &:hover {
    background: ${colors.blue};
  }
`;
