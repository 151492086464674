import { useEffect, useState } from "react";
import queryString from "query-string";
import { useNavigate, useParams } from "react-router-dom";

import { useAxios } from "../../../hooks";
import { Loader, PageHeader, Paper } from "../../../components";
import { Grid, Typography } from "@mui/material";
import { CancelButton } from "../../css components/Button";
import Table from "./analytic table components/Table";
import { colors } from "../../css components/Style";
import { titleCase } from "change-case-all";

export default function CampaignAnalytics() {
  const axios = useAxios();
  const navigate = useNavigate();

  const [analyticData, setAnalyticData] = useState([]);

  const { campaignID } = useParams();
  const campaignName = queryString.parse(window.location.search)?.campaignName;

  useEffect(() => {
    axios({
      url: "/get/stats/" + campaignID,
    }).then((response) => {
      if (response.status) {
        if (response.data.length === 0) {
          setAnalyticData([]);
        } else {
          setAnalyticData(response.data[0].channels);
        }
      }
    });
  }, [axios, campaignID]);

  return (
    <Loader>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageHeader
            title="Campaign Analytics"
            pageName={"Campaign Analytics"}
            pageHeader={campaignName}
            buttons={[
              <CancelButton variant="outlined" onClick={() => navigate(-1)}>
                Back
              </CancelButton>,
            ]}
          />
        </Grid>
        {analyticData.length === 0 ? (
          <Grid item xs={12} textAlign={"center"}>
            <Typography variant="h3" color={colors.green}>
              No stats found
            </Typography>
          </Grid>
        ) : (
          analyticData.map((analytic, index) => (
            <Grid item xs={12} key={index}>
              <Paper>
                {Object.keys(analytic).map(
                  (key) =>
                    key !== "channel_id" && (
                      <Grid item xs={12} key={key}>
                        <Typography variant="h4" color={colors.purple}>
                          {titleCase(key?.replace(/_/g, " "))}
                        </Typography>
                      </Grid>
                    )
                )}
                <Grid item xs={12}>
                  <Table campaignID={campaignID} data={[analytic]} />
                </Grid>
              </Paper>
            </Grid>
          ))
        )}
      </Grid>

      {/* {Boolean(broadcastReportData.length) && (
        <Grid container spacing={2} style={{ justifyContent: "space-evenly" }}>
          <Grid item xs={12}>
            <Paper>
              <Grid item xs={12} display={"flex"}>
                <FaWhatsapp className="wa-icon" />
                <Title variant="h5">Whatsapp Insights</Title>
              </Grid>

              <Grid item xs={12}>
                <BroadcastReportTable
                  broadcastReportData={broadcastReportData}
                />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      )} */}
    </Loader>
  );
}
