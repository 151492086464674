import React from "react";
import styled from "styled-components/macro";
import { DialogContent, Dialog, Grid } from "@mui/material";

import Form from "./Form";
import { brandDetailsObject } from "../../brand components/brand details components/brandDetailObject";

const Card = styled(Dialog)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export default function BrandDetailsDialog({ details, setDetails }) {
  const handleClose = () => {
    if (details.component === "connection") {
      setDetails((prev) => ({
        component: "connection",
        data: brandDetailsObject,
        state: false,
      }));
    } else {
      setDetails((prev) => ({ ...prev, state: false }));
    }
  };

  return (
    <Card
      open={details?.state}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "800px!important",
          padding: "20px 24px",
        },
      }}
    >
      <DialogContent>
        <Grid container spacing={3}>
          <Form formData={details} handleClose={handleClose} />
        </Grid>
      </DialogContent>
    </Card>
  );
}
