import React from "react";
import styled from "styled-components/macro";
import { HighlightOff } from "@mui/icons-material";
import {
  DialogContent,
  Dialog,
  Grid,
  Typography,
  Avatar,
  IconButton,
} from "@mui/material";

import { colors } from "../../../css components/Style";
import { ComponentHeader, TextField } from "../../../../components";
import { CancelButton } from "../../../css components/Button";

const Card = styled(Dialog)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const ImageAvatar = styled(Avatar)`
  height: 130px;
  width: 130px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`;

export default function BrandDetailsDialog({ details, setDetails }) {
  const handleClose = () => {
    setDetails((prev) => ({ data: {}, state: false }));
  };

  return (
    <Card
      open={details?.state}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "800px!important",
          padding: "20px 24px",
        },
      }}
    >
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Typography
              style={{ display: "flex" }}
              variant="h4"
              color={colors.purple}
            >
              {details?.data?.brandName}
              {"  "}
              <Typography
                variant="h5"
                color={
                  details?.data?.isBrandVerified === 1
                    ? colors.green
                    : details?.data?.isBrandVerified === 0
                    ? colors.yellow
                    : colors.error
                }
              >
                {details?.data?.isBrandVerified === 1
                  ? "(APPROVED)"
                  : details?.data?.isBrandVerified === 0
                  ? "(REQUESTED)"
                  : "(REJECTED)"}
              </Typography>
            </Typography>
          </Grid>

          <Grid style={{ paddingTop: "0" }} item xs={4} textAlign="end">
            <IconButton padding="0px" onClick={handleClose}>
              <HighlightOff />
            </IconButton>
          </Grid>

          <Grid item xs={6} textAlign="center" display="flex">
            <ImageAvatar
              src={details?.data?.logo}
              sx={{ width: "100px", height: "100px" }}
            ></ImageAvatar>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1">{details?.data?.about}</Typography>
          </Grid>

          <Grid item xs={6}>
            <TextField
              size="small"
              disabled
              value={details?.data?.onlineStore}
              name="onlineStore"
              placeholder="Online Store"
              label="Online Store"
              inputProps={{ "aria-label": "Without label" }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled
              size="small"
              value={details?.data?.website}
              name="website"
              label="Website"
              placeholder="Website"
              inputProps={{ "aria-label": "Without label" }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled
              size="small"
              value={details?.data?.mau}
              name="monthlyActiveUsers"
              label="Monthly Active Users"
              placeholder="Monthly Active Users"
              inputProps={{ "aria-label": "Without label" }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled
              label="Location"
              size="small"
              value={details?.data?.locations
                ?.map((loc) => loc.country_name)
                .join(",")}
              name="audienceLocation"
              placeholder="Select Location"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled
              label="Language"
              size="small"
              value={details?.data?.languages
                ?.map((loc) => loc.language)
                .join(",")}
              name="audienceLanguages"
              placeholder="Select Language"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              size="small"
              value={details?.data?.category?.map((cat) => cat).join(",")}
              disabled
              name="siteCategory"
              label="Category"
            />
          </Grid>

          <Grid item xs={12}>
            <ComponentHeader title="Social Media Handles" />
          </Grid>

          <Grid item xs={6}>
            <TextField
              size="small"
              disabled
              value={details?.data?.socialMedia?.facebook}
              name="socialMedia.facebook"
              label="Facebook URL"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled
              size="small"
              value={details?.data?.socialMedia?.instagram}
              name="socialMedia.instagram"
              label="Instagram URL"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              size="small"
              disabled
              value={details?.data?.socialMedia?.twitter}
              name="socialMedia.twitter"
              label="Twitter"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              size="small"
              disabled
              value={details?.data?.socialMedia?.whatsapp}
              name="socialMedia.whatsapp"
              label="Whatsapp Number"
            />
          </Grid>

          <Grid item xs />

          <Grid item>
            <CancelButton variant="outlined" onClick={() => handleClose()}>
              Cancel
            </CancelButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Card>
  );
}
