import {
  Grid,
  Stepper,
  Step,
  Box,
  Typography,
  StepButton,
} from "@mui/material";
import { Fragment, useState } from "react";

import ViewAgreement from "./ViewAgreement";
import { useAxios } from "../../../hooks";
import { colors } from "../../css components/Style";
import OtpVerification from "./OtpVerification";
import { ComponentHeader, Paper } from "../../../components";
import { PrimaryLoadingButton } from "../../css components/Button";

export default function Agreement({
  agencyID,
  setEditState,
  formikProps,
  brandID,
}) {
  const axios = useAxios();

  const [step, setStep] = useState(
    formikProps.values?.getAgreement && formikProps.values?.signAgreement
      ? 2
      : formikProps.values?.getAgreement
      ? 1
      : 0
  );

  const [loading, setLoading] = useState({});
  const [verifyOtp, setVerifyOTP] = useState(false);
  const [viewAgreement, setViewAgreement] = useState({
    data: {},
    state: false,
  });

  function handleGetAgreement() {
    setLoading({ 0: true });
    if (
      Object.keys(viewAgreement.data).length === 0 ||
      viewAgreement.data.is_verified !== false
    ) {
      axios({
        url: "/get/company/agreement",
        method: "POST",
        data: {
          company_id: formikProps.values.ID,
          type: "COMPANY_REGISTRATION",
        },
      }).then((response) => {
        if (response.status) {
          setStep(1);
          formikProps.setValues((prev) => ({
            ...prev,
            getAgreement: true,
          }));
          setViewAgreement({
            data: response.data,
            state: true,
          });
        }

        setLoading({ 0: false });
      });
    } else {
      setViewAgreement({
        data: viewAgreement.data,
        state: true,
      });
      setLoading({ 0: false });
    }
  }

  function otpVerification() {
    // axios({
    //   url: "/send/otp",
    //   method: "POST",
    //   disableRedirect: true,
    //   data: {
    //     mobile_number: formikProps.values.companyContact,
    //     msg_type: "agreement",
    //     brand_id: parseInt(brandID),
    //   },
    // }).then((response) => {
    //   if (response.status) {
    //     dispatch(
    //       alert({
    //         type: "success",
    //         message: "OTP sent successfully",
    //       })
    //     );
    //     setLoading({ 1: false });

    //   }

    setVerifyOTP(true);
  }

  function handleGetSingedAgreement() {
    setLoading({ 1: true });

    if (
      Object.keys(viewAgreement.data).length === 0 &&
      Object.keys(formikProps.values.viewAgreement).length === 0
    ) {
      axios({
        url: "/sign/company/agreement?type=COMPANY_REGISTRATION",
        method: "POST",
        data: {
          company_id: formikProps.values.ID,
          type: "COMPANY_REGISTRATION",
          signed: true,
        },
      }).then((response) => {
        if (response.status) {
          setViewAgreement({
            data: response.data,
            state: true,
          });
        }
        setLoading({ 1: false });
      });
    } else {
      setViewAgreement({
        data: formikProps.values.viewAgreement,
        state: true,
      });
      setLoading({ 1: false });
    }
  }

  const steps = [
    {
      label: "View Agreement",
      button: true,
      function: () => handleGetAgreement(),
    },

    {
      label: step === 2 ? "View Signed Agreement" : "Sign Agreement",
      button: true,
      function: () =>
        step === 2 ? handleGetSingedAgreement() : otpVerification(),
    },
  ];

  return (
    <Fragment>
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader
            title={`Business Agreement ${
              Boolean(
                Object?.keys(formikProps?.values?.viewAgreement ?? {})
                  .length !== 0
              )
                ? "(Signed)"
                : "(Unsigned)"
            }`}
            buttons={[
              Boolean(
                Object?.keys(formikProps?.values?.viewAgreement ?? {})
                  .length !== 0
              ) && (
                <PrimaryLoadingButton
                  loading={loading[2]}
                  onClick={() => handleGetSingedAgreement()}
                  variant="contained"
                >
                  View Signed Agreement
                </PrimaryLoadingButton>
              ),
            ]}
          />
        </Grid>

        {!Boolean(
          Object.keys(formikProps.values.viewAgreement ?? {}).length !== 0
        ) && (
          <Grid item xs={12}>
            <Box sx={{ width: "100%" }}>
              <Stepper alternativeLabel activeStep={step}>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepButton color={colors.purple}>
                      <Typography variant="h6">{label.label}</Typography>
                      <br />
                      <PrimaryLoadingButton
                        loading={loading[index]}
                        onClick={() => label.function()}
                        size="small"
                        variant="contained"
                        disabled={
                          index === 0
                            ? Boolean(
                                Object.keys(
                                  formikProps.values.viewAgreement ?? {}
                                ).length !== 0
                              )
                            : !(
                                index === 1 &&
                                formikProps.values.verifyNumber &&
                                formikProps.values.gstVerified &&
                                formikProps.values.getAgreement
                              )
                        }
                      >
                        {label.label}
                      </PrimaryLoadingButton>
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Grid>
        )}
      </Paper>

      <ViewAgreement
        step={step}
        setStep={setStep}
        formikProps={formikProps}
        viewAgreement={viewAgreement}
        setViewAgreement={setViewAgreement}
      />

      <OtpVerification
        agencyID={agencyID}
        setEditState={setEditState}
        setStep={setStep}
        brandID={brandID}
        formikProps={formikProps}
        setVerifyOTP={setVerifyOTP}
        verifyOTP={verifyOtp}
        setSignLoading={setLoading}
      />
    </Fragment>
  );
}
