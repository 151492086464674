import { Formik } from "formik";

import FormDisplay from "./FormDisplay";

export default function Form({
  audiences,
  formData,
  editState,
  setEditState,
  audienceData,
  categories,
  connections,
  brandDetails,
  component,
  setBrandDetails,
  setConnections,
  preferredChannels,
  setRetry,
  retry,
  audienceSizing,
  setAudienceSizing,
}) {
  return (
    <Formik enableReinitialize initialValues={formData} validateOnMount>
      {(formikProps) => (
        <FormDisplay
          preferredChannels={preferredChannels}
          audienceSizing={audienceSizing}
          setConnections={setConnections}
          editState={editState}
          setEditState={setEditState}
          categories={categories}
          brandDetails={brandDetails}
          audienceData={audienceData}
          formikProps={formikProps}
          audiences={audiences}
          connections={connections}
          component={component}
          setBrandDetails={setBrandDetails}
          setRetry={setRetry}
          retry={retry}
          setAudienceSizing={setAudienceSizing}
        />
      )}
    </Formik>
  );
}
