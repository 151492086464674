import React, { Fragment } from "react";
import { Grid, Typography } from "@mui/material";

import { TextField, Label } from "../../../../components";

export default function AddBodyVariable({ formikProps }) {
  return (
    <React.Fragment>
      {formikProps.values.templateBody.count_of_variable !== 0 && (
        <Grid item xs={12}>
          <Typography variant="caption1">Body Variable</Typography>
        </Grid>
      )}
      {Array(formikProps.values?.templateBody?.count_of_variable)
        .fill()
        .map((_, index) => (
          <Fragment>
            <Grid item xs={4}>
              <Label
                title={"Body Variable Value " + (index + 1)}
                formikProps={formikProps}
                required={true}
                name={"templateFooter.text"}
              />
            </Grid>

            <Grid key={index} item mt={1} xs={6}>
              <TextField
                formikProps={formikProps}
                disabled={Boolean(formikProps.values.ID)}
                name={"templateBody.example[" + index + "].example"}
              />
            </Grid>
          </Fragment>
        ))}
    </React.Fragment>
  );
}
