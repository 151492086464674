import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import React, { Fragment, useState } from "react";

import { useAxios, useAlert } from "../../../hooks";
import {
  PrimaryLoadingButton,
  CancelButton,
  Reject,
  Pending,
  CancelLoadingButton,
  PrimaryButton,
} from "../../css components/Button";
import { useNavigate } from "react-router-dom";

export default function ActionButtons({
  action,
  setAction,
  formikProps,
  editState,
  setEditState,
  accountStatus,
  setAccountStatus,
}) {
  const navigate = useNavigate();
  const axios = useAxios();
  const { alert } = useAlert();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleGoBack = () => {
    window.history.back();
  };

  const handleCancel = () => {
    setAction({ delete: true, suspend: true });
  };

  function deleteBrandDetails() {
    setAction((prev) => ({
      ...prev,
      delete: false,
    }));
  }

  function suspendBrandDetails() {
    setAction((prev) => ({
      ...prev,
      suspend: false,
    }));
  }

  function getStatus() {
    if (!action.delete) {
      return "DELETED";
    } else if (!action.suspend) {
      return "SUSPENDED";
    } else {
      return "VERIFIED";
    }
  }

  function changeUserStatus() {
    setLoading(true);
    axios({
      url: "/user/accountAction",
      method: "POST",
      data: {
        email: formikProps.values.email,
        user_id: formikProps.values.userID,
        status: getStatus(),
      },
    }).then((response) => {
      if (response.status) {
        dispatch(
          alert({
            message: response.data,
            type: "success",
          })
        );

        setAction({ delete: true, suspend: true });
        setAccountStatus(!accountStatus);

        formikProps.setValues((prev) => ({
          ...prev,
          accountStatus: setStatusValue(prev.accountStatus),
        }));
        function setStatusValue(val) {
          let status = getStatus();
          if (status === "DELETED") {
            val = 5;
          } else if (status === "SUSPENDED") {
            val = 4;
          } else {
            val = 1;
          }
          return val;
        }

        setLoading(false);
      }
    });
  }

  return (
    <Grid container mb="10px" alignItems="center">
      <Grid
        item
        xs={12}
        container
        justifyContent="flex-end"
        style={{ gap: "5px" }}
      >
        {!accountStatus && action.delete && action.suspend && (
          <Fragment>
            <Grid item>
              <Reject variant="contained" onClick={deleteBrandDetails}>
                Delete
              </Reject>
            </Grid>

            <Grid item>
              <Pending variant="contained" onClick={suspendBrandDetails}>
                Suspend
              </Pending>
            </Grid>
          </Fragment>
        )}

        {!action.delete && (
          <Fragment>
            <Grid item>
              <CancelButton variant="outlined" onClick={handleCancel}>
                Cancel
              </CancelButton>
            </Grid>

            <Grid item>
              <PrimaryLoadingButton
                loading={loading}
                variant="contained"
                onClick={changeUserStatus}
              >
                Confirm
              </PrimaryLoadingButton>
            </Grid>
          </Fragment>
        )}

        {!action.suspend && (
          <Fragment>
            <Grid item>
              <CancelLoadingButton variant="outlined" onClick={handleCancel}>
                Cancel
              </CancelLoadingButton>
            </Grid>

            <Grid item>
              <PrimaryButton
                variant="contained"
                color="primary"
                onClick={changeUserStatus}
              >
                Confirm
              </PrimaryButton>
            </Grid>
          </Fragment>
        )}

        {accountStatus && formikProps.values.accountStatus === 4 && (
          <Fragment>
            <Grid item>
              <CancelButton
                variant="outlined"
                onClick={handleGoBack}
                style={{ color: "#BDBDBD" }}
              >
                Cancel
              </CancelButton>
            </Grid>

            <Grid item>
              <PrimaryButton
                variant="contained"
                color="primary"
                onClick={changeUserStatus}
              >
                Reinstate
              </PrimaryButton>
            </Grid>
          </Fragment>
        )}

        {accountStatus && formikProps.values.accountStatus === 5 && (
          <Fragment>
            <Grid item>
              <CancelButton variant="outlined" onClick={handleGoBack}>
                Cancel
              </CancelButton>
            </Grid>
          </Fragment>
        )}

        {!accountStatus && action.delete && action.suspend && (
          <Fragment>
            <CancelButton
              style={{ marginRight: "0" }}
              variant="outlined"
              onClick={handleAllCancel}
            >
              {editState ? "Cancel" : "Back"}
            </CancelButton>

            {Boolean(editState !== undefined) &&
              (editState ? (
                <PrimaryButton
                  variant="contained"
                  onClick={formikProps.handleSubmit}
                  disabled={!formikProps.dirty || !formikProps.isValid}
                  loading={formikProps.isSubmitting}
                >
                  Save
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  variant="contained"
                  onClick={() => setEditState(!editState)}
                >
                  Edit
                </PrimaryButton>
              ))}
          </Fragment>
        )}
      </Grid>
    </Grid>
  );

  function handleAllCancel() {
    if (editState && formikProps.values.ID) {
      setEditState(!editState);
      formikProps.handleReset();
    } else {
      navigate(-1);
    }
  }
}
