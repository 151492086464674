import { CgEye } from "react-icons/cg";
import { Fragment, useState } from "react";
import { Grid, IconButton, InputAdornment } from "@mui/material";

import { ComponentHeader, TextField } from "../../../../components";
import CreativeDialog from "../creative components/CreativeDialog";
import { PrimaryButton } from "../../../css components/Button";
import { HeadingEighteen, colors } from "../../../css components/Style";

export default function FormFifth({ formikProps, component }) {
  const [editState, setEditState] = useState(true);

  const [detail, setDetail] = useState({
    state: false,
    campaignDetails: formikProps.values,
    data: {
      format: "",
      creative: "",
      fileUrl: "",
      friendlyName: "",
      fileName: "",
      adSize: "",
      adTag: "",
    },
    id: "",
  });

  return (
    <Fragment>
      <Grid item xs={12}>
        <ComponentHeader
          title={"Creatives"}
          subtitle={
            component === ""
              ? "Let's add creatives to your campaign"
              : "Creatives"
          }
        />
      </Grid>

      {formikProps.values.creatives.length === 0 ? (
        <Grid item xs={12}>
          <HeadingEighteen color={colors.purple}>
            No creative found!
          </HeadingEighteen>
        </Grid>
      ) : (
        formikProps.values.creatives.map((creative) => (
          <Fragment key={creative.creativeID}>
            <Grid item xs={4}>
              <TextField
                value={creative.friendlyName}
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position={"end"}>
                      <IconButton
                        onClick={() => viewCreative(creative)}
                        style={{ padding: "0" }}
                      >
                        <CgEye style={{ color: colors.purple }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Fragment>
        ))
      )}

      {component !== "superadmin" && component !== "request" && (
        <Grid item xs={12}>
          <PrimaryButton variant="contained" onClick={() => addCreative()}>
            Add Creative
          </PrimaryButton>
        </Grid>
      )}

      <CreativeDialog
        formikProps={formikProps}
        detail={detail}
        setDetail={setDetail}
        editState={editState}
        setEditState={setEditState}
        component={component}
      />
    </Fragment>
  );

  function addCreative() {
    setEditState(true);
    setDetail((prev) => ({
      ...prev,
      data: {
        format: "",
        creative: "",
        fileUrl: "",
        friendlyName: "",
        fileName: "",
        adSize: "",
        adTag: "",
      },
      state: true,
      campaignDetails: formikProps.values,
      id: "",
    }));
  }

  function viewCreative(creatives) {
    setEditState(false);
    setDetail((prev) => ({
      ...prev,
      state: true,
      campaignDetails: formikProps.values,
      id: creatives.creativeID.toString(),
      data: {
        creativeID: creatives.creativeID,
        format: creatives.format || "",
        creative: creatives.creative || "",
        friendlyName: creatives.friendlyName || "",
        adSize: creatives.adSize || "",
        adTag: creatives.adTag || "",
        sourceType: creatives.sourceType || "",
        fileUrl: creatives.fileUrl || "",
      },
    }));
  }
}
