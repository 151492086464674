import * as Yup from "yup";
import { Formik } from "formik";
import { Fragment, useState } from "react";
import { Button, Grid } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";

import RedirectDialog from "./RedirectDialog";
import { useAlert, useAxios } from "../../../hooks";
import { FileUpload, TextField } from "../../../components";

export default function Form() {
  const axios = useAxios({
    baseURL: "cpass_api",
  });
  const alert = useAlert();
  const dispatch = useDispatch();
  const { activeBrand } = useSelector((state) => state.memberDetails);

  const [popUp, setPopUp] = useState(false);
  const [redirectURL, setRedirectURL] = useState("");
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const [formData] = useState({ name: "", phoneNo: "", logo: "" });

  return (
    <Formik
      initialValues={formData}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(formData, { setSubmitting }) => {
        axios({
          url: "/embedded_signup/redirect",
          method: "POST",
          data: {
            name: formData.name,
            logo: formData.logo,
            phone_number: formData.phoneNo,
            brand_id: activeBrand.ID,
          },
          disableRedirect: true,
        }).then((resp) => {
          if (resp.status) {
            setRedirectURL(resp?.data);

            setPopUp(true);
          } else {
            dispatch(
              alert({ type: "error", message: resp.message.displayMessage })
            );
          }
        });
      }}
    >
      {(formikProps) => {
        const handleChangePhoneNumber = (value, country, e) => {
          if (value.length <= 3) {
            setValidPhoneNumber(true);
          } else {
            setValidPhoneNumber(false);
          }
          if (
            e.target.className === "country" ||
            e.target.className === "country-name"
          ) {
            formikProps.setValues((prevVal) => ({
              ...prevVal,
              phoneNo: "+" + country.dialCode + "",
            }));
          } else {
            formikProps.setValues((prevVal) => ({
              ...prevVal,
              phoneNo: value,
            }));
          }
        };

        return (
          <Fragment>
            <Grid item xs={6}>
              <TextField
                name="name"
                label={"Profile Name"}
                formikProps={formikProps}
              />
            </Grid>

            <Grid item xs={6}>
              <PhoneInput
                value={formikProps.values.phoneNo}
                onChange={(value, country, e) =>
                  handleChangePhoneNumber(value, country, e)
                }
                inputProps={{
                  name: "phoneNo",
                }}
                onBlur={formikProps.handleBlur}
                inputStyle={{
                  width: "100%",
                  fontSize: "12px",
                  color: "currentColor",
                  borderColor: !validPhoneNumber ? "#4076d2" : "#f44336",
                  padding: "17.5px 14px 18.5px 58px",
                  cursor: "default",
                }}
              />
              {validPhoneNumber && (
                <span className="validationError">Number is Required</span>
              )}
            </Grid>

            <Grid item xs={6}>
              <FileUpload
                name="logo"
                formikProps={formikProps}
                placeholder="Upload Logo"
                allowedExtension={".jpeg,.jpg,.png,.gif,.svg"}
                folderPath={
                  "public/WhatsappOnboarding/" + new Date().getTime() + "_"
                }
              />
            </Grid>

            <Grid item xs={12} display={"flex"} justifyContent={"end"}>
              <Button
                variant="contained"
                onClick={() => formikProps.handleSubmit()}
                disabled={!formikProps.isValid || !formikProps.dirty}
              >
                Submit
              </Button>
            </Grid>

            <RedirectDialog
              popUp={popUp}
              setPopUp={setPopUp}
              formikProps={formikProps}
              redirectURL={redirectURL}
            />
          </Fragment>
        );
      }}
    </Formik>
  );
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name Is Required"),
  phoneNo: Yup.string()
    .matches(/^[0-9]{8,13}$/, "Must be exactly 10 digits")
    .required("Phone Number Is Required"),
  logo: Yup.string().required("Logo Is Required"),
});
