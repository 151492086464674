import * as Yup from "yup";

export const logInDetailsObject = {
  email: "",
  password: "",
  rememberMe: false,
};

export function objectFromFormData(formData) {
  return {
    email: formData.email,
    password: formData.password,
  };
}

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .matches(
      /^[a-zA-Z0-9._%+-]+@(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/gm,
      "Only accepts business emails"
    )
    .max(255)
    .required("Email is required"),
  password: Yup.string().max(255).required("Password is required"),
});

export function setBrandData(data, response) {
  sessionStorage.setItem("ladder", JSON.stringify(data?.steps));
  sessionStorage.setItem("userID", parseInt(data.user[0]?.user_id));
  sessionStorage.setItem("authToken", data.token);
  sessionStorage.setItem("businessEntityId", data.user[0]?.business_entity_id);

  sessionStorage.setItem("brandID", response.data.brands[0]?.brand_id);
}

export function setAgencyData(data, response) {
  sessionStorage.setItem("ladder", JSON.stringify(data?.steps));
  sessionStorage.setItem("userID", parseInt(data.user[0]?.user_id));
  sessionStorage.setItem("authToken", data.token);
  sessionStorage.setItem("businessEntityId", data.user[0]?.business_entity_id);

  sessionStorage.setItem("agencyID", data?.agency[0]?.agency_id);
}
