import { Fragment, useState, useRef } from "react";
import { InputAdornment, Button } from "@mui/material";
import { UploadFile } from "@mui/icons-material";
import styled from "styled-components";
import { get } from "lodash";
import { useDispatch } from "react-redux";
import { RiErrorWarningLine, RiCheckboxCircleLine } from "react-icons/ri";

import { uploadToS3, validateFileUpload } from "../utils";
import TextField from "./TextField";
import { useAlert } from "../hooks";

const TextFieldWithCSS = styled(TextField)`
  * {
    ${(props) => {
      return { cursor: !props.disabled && "pointer" };
    }}
  }
`;

/*
if incase there is no formik props then pass formikProps as
formikProps={{
  setFieldValue: (_, value) => setValue(value),
  values: { fileName },
  setFieldTouched: () => {},
}}
*/

export default function FileUploadNew({
  folderPath,
  maxSize,
  bucket = {},
  allowedExtension = "*",
  formikProps,
  ...props
}) {
  const dispatch = useDispatch();
  const { alert } = useAlert();

  const [uploadState, setUploadState] = useState("initial");

  const uploadedFiles = useRef([]);
  const inputRef = useRef(null);

  return (
    <Fragment>
      <TextFieldWithCSS
        placeholder="No file chosen"
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <Button
                size="small"
                variant="contained"
                disabled={props.disabled}
                startIcon={<UploadFile />}
                onClick={() => {
                  inputRef.current.click();
                }}
              >
                Upload
              </Button>
            </InputAdornment>
          ),
          endAdornment: uploadState !== "initial" && (
            <InputAdornment position="end">
              {uploadState === "success" ? (
                <RiCheckboxCircleLine size={20} style={{ color: "green" }} />
              ) : (
                <RiErrorWarningLine size={20} style={{ color: "red" }} />
              )}
            </InputAdornment>
          ),
        }}
        formikProps={formikProps}
        {...props}
      />

      <TextField
        type="file"
        inputRef={inputRef}
        onClick={handleFileClick}
        onChange={props.onChange || handleFileChange}
        style={{ display: "none" }}
        inputProps={{
          accept: allowedExtension,
        }}
      />
    </Fragment>
  );

  function handleFileClick() {
    formikProps.setFieldTouched(props.name, true);
  }

  function handleFileChange({ target }) {
    const file = get(target, "files[0]", "");

    const existingFile = uploadedFiles.current.find(
      (uploadedFile) =>
        uploadedFile.name === file.name &&
        uploadedFile.size === file.size &&
        uploadedFile.lastModified === file.lastModified
    );

    if (!!existingFile) {
      formikProps.setFieldValue(props.name, existingFile.link);

      return;
    }

    const fileValidity = validateFileUpload({
      file,
      allowedExtension,
      maxSize: maxSize * 1024,
    });

    if (!fileValidity.status) {
      dispatch(alert({ type: "error", message: fileValidity.message }));

      formikProps.setFieldValue(props.name, {});

      setUploadState("failed");

      return;
    }

    const uploadBucket = {
      name: "adn-media",
      region: "ap-south-1",
      identityPoolID: "ap-south-1:16fa1fcf-7cdd-4593-9ee0-6f621526defc",
      ...bucket,
    };

    uploadToS3({
      bucket: uploadBucket,
      filePath: folderPath + file.name,
      file,
    }).then((response) => {
      if (response.status) {
        setUploadState("success");

        uploadedFiles.current.push({
          name: file.name,
          size: file.size,
          lastModified: file.lastModified,
          link:
            "https://" +
            uploadBucket.name +
            ".s3.ap-south-1.amazonaws.com/" +
            folderPath +
            file.name,
        });

        formikProps.setFieldValue(props.name, {
          name: file.name,
          URL:
            "https://" +
            uploadBucket.name +
            ".s3.ap-south-1.amazonaws.com/" +
            folderPath +
            file.name,
        });
      } else {
        dispatch(alert({ type: "error", message: "Error Uploading File." }));

        formikProps.setFieldValue(props.name, {});

        setUploadState("failed");
      }
    });
  }
}
