import React from "react";
import styled from "styled-components/macro";
import { LinearProgress } from "@mui/material";

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    width: 40%;
  }
`;

export default function WebsiteLoader() {
  return (
    <Wrapper>
      <LinearProgress />
    </Wrapper>
  );
}
