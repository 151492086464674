import styled from "styled-components";
import { Helmet } from "react-helmet-async";

import Form from "./log in components/Form";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e5cce200;
  height: 100%;
  padding: 0 30px;
`;

export default function LogIn() {
  return (
    <Wrapper>
      <Helmet title="Sign In" />

      <Form />
    </Wrapper>
  );
}
