import { Navigate, Outlet } from "react-router-dom";
import pages from "../constants/pages";

export default function RoleBaseNavigation({ role, roles = [], ...props }) {
  return roles.includes(role) ? (
    <Outlet />
  ) : (
    <Navigate to={pages.permissionDenied.route} />
  );
}
