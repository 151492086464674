import { Fragment } from "react";
import { Grid } from "@mui/material";

import FormOne from "./FormOne";
import FormTwo from "./FormTwo";

import ActionButton from "./ActionButton";
import AdminAgencyStatus from "./AdminAgencyStatus";
import { ComponentHeader, PageHeader, Paper } from "../../../components";
import RelatedBrandList from "./RelatedBrandsList";

export default function FormDisplay({ brands, formData, setFormData }) {
  return (
    <Fragment>
      <Grid item xs={12}>
        <PageHeader
          title="Agency Details"
          pageName={"Agency Details"}
          pageHeader="Take action on agency"
          buttons={[
            <ActionButton
              formData={formData}
              ID={formData.agencyID}
              setFormData={setFormData}
            />,
          ]}
        />
      </Grid>

      <AdminAgencyStatus formData={formData} />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper gridSpacing={3}>
            <FormOne formData={formData} />

            <FormTwo formData={formData} />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <Grid item xs={12}>
              <ComponentHeader
                title={"Brands"}
                subtitle={"Agencies registered brand"}
              />
            </Grid>

            <RelatedBrandList brands={brands} formData={formData} />
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
}
