import { Fragment, useEffect, useState } from "react";
import { Button, Grid, Pagination, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { useAxios } from "../../hooks";
import EmptyList from "../misc/EmptyList";
import { colors } from "../css components/Style";
import List from "./unverified agencies list/List";
import { Loader, PageHeader } from "../../components";
import SortBy from "./unverified agencies list/SortBy";
import Search from "./unverified agencies list/Search";

export default function UnverifiedAgenciesList() {
  const axios = useAxios();

  const [page, setPage] = useState(1);
  const [agencies, setAgencies] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [agencyStatus, setAgencyStatus] = useState();
  const [lastPage, setLastPage] = useState({
    totalCount: 0,
    lastPage: false,
  });

  let sortList = [
    { value: "", label: "ALL", color: colors.blue },
    { value: 1, label: "Verified", color: colors.green },
    { value: 0, label: "Unverified", color: colors.yellow },
    { value: 2, label: "Rejected", color: colors.error },
  ];

  useEffect(() => {
    axios({
      url:
        "/getAllAgency?page=" +
        page +
        "&limit=10" +
        (sortBy !== "" ? "&is_agency_verified=" + sortBy : "") +
        (Boolean(searchKey) ? "&search=" + encodeURIComponent(searchKey) : ""),
      method: "GET",
      disableRedirect: false,
    }).then((response) => {
      if (response.status) {
        setAgencies(response.data.parsedAgency);

        setLastPage({
          totalCount: response.data.totalCount,
          lastPage: response.data.parsedAgency.length < 10,
        });
      } else {
        setAgencies([]);

        setLastPage({
          totalCount: response.data?.totalCount || 0,
          lastPage: false,
        });
      }
    });
  }, [axios, agencyStatus, page, sortBy, searchKey]);

  function handlePagination(step, index) {
    setPage((prev) =>
      step === "index" ? index : prev + (step === "next" ? 1 : -1)
    );
  }

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageHeader
            icons={[
              <SortBy
                sortList={sortList}
                sortBy={sortBy}
                setPage={setPage}
                setSortBy={setSortBy}
              />,
              <Search
                searchKey={searchKey}
                setPage={setPage}
                setSearchKey={setSearchKey}
              />,
            ]}
            pageName={"Agencies"}
            pageHeader={"List of agencies"}
          />
        </Grid>
      </Grid>

      <Loader height="75%">
        {agencies.length > 0 ? (
          <List agencies={agencies} setAgencyStatus={setAgencyStatus} />
        ) : (
          <EmptyList title={"No new brands"} />
        )}
      </Loader>

      {!!lastPage.totalCount && (
        <Grid item container display={"flex"} justifyContent={"center"}>
          <Pagination
            count={Math.ceil(lastPage.totalCount / 10)}
            variant="outlined"
            color="secondary"
            page={page}
            onChange={(e, value) => handlePagination("index", value)}
          />
        </Grid>
      )}
    </Fragment>
  );
}
