import { Formik } from "formik";

import FormDisplay from "./FormDisplay";

export default function Form({ formData }) {
  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {}}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit}>
          <FormDisplay formikProps={formikProps} />
        </form>
      )}
    </Formik>
  );
}
