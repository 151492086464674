import { useDispatch } from "react-redux";
import React, { useState } from "react";
import {
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useAxios } from "../../../hooks";
import { TextField } from "../../../components";
import { TextFifteenYellow, colors } from "../../css components/Style";
import {
  CancelButton,
  PrimaryLoadingButton,
} from "../../css components/Button";
import { alert } from "../../../redux/slices/alertSlice";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "400px" },
  div: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
  },
}));

export default function VerifyOTPDialog({
  verifyOTP,
  setVerifyOTP,
  formikProps,
  brandID,
  agencyID,
}) {
  const axios = useAxios();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState({
    resend: false,
    verify: false,
  });

  const handleClose = () => {
    formikProps.setValues({
      ...formikProps.values,
      otp: "",
    });
    setVerifyOTP(false);
  };

  return (
    <React.Fragment>
      <MUIDialog
        classes={{ paper: classes.paper }}
        open={verifyOTP}
        onClose={handleClose}
      >
        <DialogContent>
          <Grid container spacing={8}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <TextFifteenYellow style={{ color: colors.green }}>
                Verify OTP
              </TextFifteenYellow>
            </Grid>

            <Grid item xs={12}>
              <TextField
                formikProps={formikProps}
                type="number"
                name="otp"
                size="small"
                label="Enter OTP"
                placeholder="Enter OTP"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography>
                OTP sent on Mobile No : {formikProps.values.companyContact}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions style={{ padding: "24px" }}>
          <Grid container spacing={2}>
            <Grid item>
              <CancelButton
                onClick={handleClose}
                variant="outlined"
                color="primary"
              >
                Cancel
              </CancelButton>
            </Grid>

            <Grid item xs />

            <Grid item>
              <PrimaryLoadingButton
                loading={loading.resend}
                variant="outlined"
                onClick={resendOTP}
                color="primary"
                autoFocus
              >
                Resend OTP
              </PrimaryLoadingButton>
            </Grid>

            <Grid item>
              <PrimaryLoadingButton
                loading={loading.verify}
                variant="contained"
                color="primary"
                autoFocus
                disabled={!formikProps.values.otp}
                onClick={verificationOTP}
              >
                Verify OTP
              </PrimaryLoadingButton>
            </Grid>
          </Grid>
        </DialogActions>
      </MUIDialog>
    </React.Fragment>
  );

  function verificationOTP() {
    setLoading({ ...loading, verify: true });
    axios({
      url: "/verify/otp",
      method: "POST",
      disableRedirect: true,
      data: {
        mobile_number: formikProps.values.companyContact,
        msg_type: "registration",
        otp: formikProps.values.otp,
        ...(!!brandID && { brand_id: parseInt(brandID) }),
        ...(!!agencyID && { agency_id: parseInt(agencyID) }),
      },
    }).then((response) => {
      if (response.status) {
        formikProps.setValues((prev) => ({
          ...prev,
          verifyNumber: true,
          otp: "",
        }));

        dispatch(
          alert({
            type: "success",
            message: "Phone Number Verified Successfully",
          })
        );
        setVerifyOTP(false);
      }

      setLoading({ ...loading, verify: false });
    });
  }

  function resendOTP() {
    setLoading({ ...loading, resend: true });

    axios({
      url: "/send/otp",
      method: "POST",
      disableRedirect: true,
      data: {
        mobile_number: formikProps.values.companyContact,
        msg_type: "registration",
        resend: true,
        brand_id: parseInt(brandID),
      },
    }).then((response) => {
      if (response.status) {
        dispatch(
          alert({
            type: "success",
            message: "OTP sent successfully",
          })
        );
      }
      setLoading({ ...loading, resend: false });
    });
  }
}
