import styled from "styled-components";
import { titleCase } from "change-case-all";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RiMenuFoldFill, RiMenuUnfoldFill } from "react-icons/ri";
import { Avatar, Paper, Grid, IconButton } from "@mui/material";

import pages from "../../constants/pages";
import { colors } from "../../pages/css components/Style";
import DropDownButton from "./navbar components/DropDownButton";

const Wrapper = styled(Paper)`
  min-height: 64px;
  border-radius: 0px;
  background: #fff;
  padding: 10px;
`;

const GridContainer = styled(Grid)`
  height: 100%;
  align-items: center;
  padding-inline: 10px;
  gap: 6px;
`;

const ImageDiv = styled.div`
  width: 150px;
  height: 40px;
`;

const Image = styled.img`
  height: inherit;
  width: inherit;
`;

export default function Navbar({
  sidebarState,
  toggleSideBar,
  setSelectedMenu,
}) {
  const navigate = useNavigate();
  const uiDetails = useSelector((state) => state.renderUIDetails);

  const { activeBrand, role, dashboardPermissions, userData, agency } =
    useSelector((state) => state.memberDetails);

  return (
    <Wrapper elevation={2}>
      <GridContainer container>
        <Grid item alignSelf={"center"} textAlign={"center"}>
          <IconButton onClick={toggleSideBar} style={{ color: colors.purple }}>
            {sidebarState.static ? <RiMenuFoldFill /> : <RiMenuUnfoldFill />}
          </IconButton>
        </Grid>

        <Grid
          item
          alignSelf={"center"}
          textAlign={"center"}
          style={{ cursor: "pointer" }}
        >
          <ImageDiv
            onClick={() => {
              const isAdminBrand = dashboardPermissions.some(
                (page) => page.ID === "administrator-brands"
              );

              const path = isAdminBrand
                ? pages.accountVerification.route
                : pages.brandDetails.route;

              navigate(path);
              setSelectedMenu(path);
            }}
          >
            <Image src={uiDetails.siteLogo} alt="!" />
          </ImageDiv>
        </Grid>

        <Grid item xs />
        <Grid
          item
          style={{ marginRight: "57px" }}
          alignSelf={"center"}
          textAlign={"center"}
        >
          {(role === "NORMAL_USER" || role === "AGENCY") && (
            <div
              className="brand-switch-logo"
              onClick={() => navigate(pages.welcomePage.route)}
            >
              <Avatar
                style={{
                  height: "25px",
                  width: "25px",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
                src={role === "AGENCY" ? agency.agencyLogo : activeBrand.logo}
              />
              {role === "AGENCY"
                ? titleCase(agency.agencyName)
                : titleCase(activeBrand.brandName)}
            </div>
          )}
        </Grid>

        <Grid item alignSelf={"center"} textAlign={"center"}>
          <DropDownButton />
        </Grid>
      </GridContainer>
    </Wrapper>
  );
}
