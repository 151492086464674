import React from "react";
import { TableCell, Typography } from "@mui/material";

import { DataTable } from "../../../../components";

export default function Table({ data }) {
  const columns = [];
  const tableData = [];
  const fields = [];

  const options = {
    search: false,
    sort: false,
    filter: false,
    print: false,
    download: false,
    viewColumns: true,
    pagination: false,
  };

  for (let channel of data) {
    Object.keys(channel).map(
      (key) =>
        key !== "channel_id" && tableData.push(...channel[key]["stats_data"])
    );
  }

  for (let channel of tableData) {
    Object.keys(channel).map(
      (tableData, index) =>
        !fields.includes(tableData) && fields.push(tableData)
    );
  }

  for (let [index, field] of fields.entries()) {
    columns.push({
      name: field,
      label: field?.replace(/_/g, " "),
      options: {
        display: index > 7 ? false : true,
        customHeadRender: ({ index, ...column }) => {
          return (
            <TableCell key={index}>
              <div style={{ display: "flex" }}>
                <Typography className="table-cell-text">
                  {column.label}
                </Typography>
              </div>
            </TableCell>
          );
        },
        customBodyRender: (value) => {
          return value ? (
            <Typography style={{ alignContent: "center", fontSize: "14px" }}>
              {value}
            </Typography>
          ) : (
            "Not Available"
          );
        },
      },
    });
  }

  return (
    <React.Fragment>
      <DataTable columns={columns} options={options} data={tableData} />
    </React.Fragment>
  );
}
