import React, { useState } from "react";
import {
  Grid,
  MenuItem,
  InputAdornment,
  Tooltip,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import { Info } from "@mui/icons-material";

import {
  Autocomplete,
  ComponentHeader,
  Paper,
  TextField,
  Label,
} from "../../../components";
import TestTemplate from "../whatsapp interface/TestTemplate";

export default function FormDisplay({
  formikProps,
  agentID,
  language,
  category,
  addSample,
  botDetails,
  setAddSample,
}) {
  const [popUp, setPopUp] = useState(false);
  const handleChangeLanguage = (value, type) => {
    if (type === "selectOption") {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        templateLanguage: setTemplateLanguage(prevVal.templateLanguage),
      }));

      function setTemplateLanguage(templateLanguage) {
        templateLanguage.code_id = value.id;
        return templateLanguage;
      }
    } else {
      value = {
        _id: "",
        language: "",
      };
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        templateLanguage: setTemplateLanguage(prevVal.templateLanguage),
      }));
      function setTemplateLanguage(templateLanguage) {
        templateLanguage.code_id = value.id;
        return templateLanguage;
      }
    }
  };
  return (
    <React.Fragment>
      <Paper className="topElement">
        <Grid item xs={12}>
          <ComponentHeader
            title="Template Details"
            subtitle="WhatsApp message templates are specific message formats that
            businesses use to send out notifications or customer care messages
            to people."
          />
        </Grid>

        <Grid style={{ paddingTop: "0px" }} item xs={12}>
          {botDetails?.authentication?.whatsapp_server === "normal" && (
            <Typography style={{ fontSize: "13px", color: "#0c5460" }}>
              <Typography variant="button" style={{ color: "#ff0000" }}>
                Important:
              </Typography>{" "}
              Please register only the{" "}
              <Typography variant="button" color="primary">
                Approved Templates
              </Typography>{" "}
              here or else they'll be not of use.
            </Typography>
          )}
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Template Name"
            name="templateName"
            required
            disabled={Boolean(formikProps.values.ID) || Boolean(addSample)}
            formikProps={formikProps}
            inputProps={{
              maxLength: 512,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {formikProps.values?.templateName.length}/512
                  <IconButton>
                    <Tooltip title="When creating a message template, it is mandatory to give it a name. It is advised that the name reflect the template’s content. This name must only contain lowercase letters, numbers, and underscores.">
                      <Info />
                    </Tooltip>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <Autocomplete
            label="Template Language"
            name="templateLanguage.code_id"
            defaults={{
              primaryKey: "id",
              displayLabel: "language",
            }}
            options={language}
            disabled={Boolean(formikProps.values.ID) || Boolean(addSample)}
            onChange={(_, value, type) => handleChangeLanguage(value, type)}
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Template Status"
            select
            required
            name="status"
            disabled={Boolean(formikProps.values.ID) || Boolean(addSample)}
            formikProps={formikProps}
          >
            <MenuItem value={true}>Active</MenuItem>
            <MenuItem value={false}>Inactive</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Template Category"
            select
            required
            name="category"
            disabled={Boolean(formikProps.values.ID) || Boolean(addSample)}
            formikProps={formikProps}
          >
            {category.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.display_name}
                <br />
                {item.description}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
