import { Grid, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import FormOne from "./FormOne";
import {
  ButtonFooter,
  ComponentHeader,
  PageHeader,
  Paper,
} from "../../../components";
import FormThree from "./FormThree";
import FloatingEditActionButtons from "../../../components/FloatingEditActionButton";
import EditButtons from "../../../components/EditButtons";
import { colors } from "../../css components/Style";
import { CancelButton } from "../../css components/Button";

export default function FormDisplay({
  agencyID,
  brandID,
  formikProps,
  editState,
  setEditState,
}) {
  const { state } = useLocation();
  const navigate = useNavigate();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PageHeader
          pageName={"Company Details"}
          pageHeader={
            !formikProps.values.brandID
              ? "Let's add company details"
              : formikProps.values.companyName
          }
          buttons={[
            formikProps.values.is_brand_verified === 1 ||
            formikProps.values.is_agency_verified === 1 ? (
              <CancelButton variant="outlined" onClick={() => navigate(-1)}>
                Back
              </CancelButton>
            ) : (
              <EditButtons editState={editState} setEditState={setEditState} />
            ),
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        <Paper gridSpacing={4}>
          <Grid item xs={12}>
            <ComponentHeader title={"Company Details"} />
          </Grid>
          {formikProps.values.is_brand_verified === 1 && (
            <Grid item xs={12}>
              <Typography variant="h6" color={colors.green}>
                This brand has been verified by admin.
              </Typography>
            </Grid>
          )}

          <FormOne
            agencyID={agencyID}
            brandID={brandID}
            formikProps={formikProps}
            editState={editState}
          />
          <FormThree
            agencyID={agencyID}
            brandID={brandID}
            formikProps={formikProps}
            editState={editState}
          />

          {((formikProps.values.brandID !== null &&
            formikProps.values.is_brand_verified !== 1) ||
            (formikProps.values.agencyID !== null &&
              formikProps.values.is_agency_verified !== 1)) && (
            <FloatingEditActionButtons
              ID={formikProps.values.brandID}
              editState={editState}
              setEditState={setEditState}
              formikProps={formikProps}
            />
          )}
        </Paper>

        {editState && (
          <Grid item xs={12}>
            <ButtonFooter
              // disabled={
              //   !formikProps.values.verifyNumber ||
              //   !formikProps.values.gstVerified
              // }
              editState={editState}
              route={state}
              setEditState={setEditState}
              ID={formikProps.values.ID}
              formikProps={formikProps}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
