import { BiWallet } from "react-icons/bi";
import styled from "styled-components/macro";
import { Avatar, Grid, Typography } from "@mui/material";

import { Paper } from "../../components";
import {
  HeadingEighteen,
  TextFourteenBlack,
  colors,
} from "../css components/Style";
import convertDateTimeUTC from "../../utils/convertUTC";
import { titleCase } from "change-case-all";

export default function TransactionList({ creditInfo }) {
  const Header = styled(Typography)`
    font-size: 16px;
    font-weight: 500;
  `;

  const Image = styled(Avatar)`
    height: 55px;
    width: 55px;
    background: #fff;
    border: 1px solid;
  `;

  const OuterCard = styled(Paper)`
    display: flex;
    gap: 20px;
    margin: 8px 0;
    padding: 10px;
  `;

  return (
    <Grid container spacing={0}>
      {creditInfo?.creditHistory?.map((credit, index) => (
        <Grid item xs={12} key={index}>
          <OuterCard elevation={1}>
            <Grid item>
              <Image
                style={{
                  borderColor:
                    credit.action === "credit" ? colors.green : colors.error,
                }}
              >
                <BiWallet
                  style={{
                    fontSize: "25px",

                    color:
                      credit.action === "credit" ? colors.green : colors.error,
                  }}
                />
              </Image>
            </Grid>

            <Grid item xs>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextFourteenBlack>Transaction ID</TextFourteenBlack>
                  <HeadingEighteen>
                    {credit.action === "credit" ? credit.transaction_id : "#"}
                  </HeadingEighteen>
                </Grid>

                <Grid item xs={2}>
                  <TextFourteenBlack>Action</TextFourteenBlack>
                  <HeadingEighteen>{credit.title}</HeadingEighteen>
                </Grid>

                <Grid item xs={3}>
                  <TextFourteenBlack>Date</TextFourteenBlack>
                  <HeadingEighteen>
                    {convertDateTimeUTC(credit.created_at)}
                  </HeadingEighteen>
                </Grid>

                <Grid item xs={2}>
                  <TextFourteenBlack>Total Credit</TextFourteenBlack>
                  <HeadingEighteen>{credit.amount}</HeadingEighteen>
                </Grid>

                <Grid item xs={1}>
                  <TextFourteenBlack>Credit</TextFourteenBlack>
                  <HeadingEighteen
                    style={{
                      color: colors.green,
                    }}
                  >
                    {credit.credit_amt > 0 && credit.credit_amt}{" "}
                  </HeadingEighteen>

                  <HeadingEighteen
                    style={{
                      color: colors.error,
                    }}
                  >
                    {credit.debit_amt > 0 && credit.debit_amt}{" "}
                  </HeadingEighteen>
                </Grid>

                <Grid item xs={1}>
                  <TextFourteenBlack>Status</TextFourteenBlack>

                  <HeadingEighteen
                    style={{
                      color:
                        credit.action === "credit"
                          ? colors.green
                          : colors.error,
                    }}
                  >
                    {titleCase(credit.action)}
                  </HeadingEighteen>
                </Grid>
              </Grid>
            </Grid>
          </OuterCard>
        </Grid>
      ))}
    </Grid>
  );
}
