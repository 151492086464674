import React from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";

import { Typography } from "@mui/material";
import { PrimaryButton } from "../css components/Button";

const Wrapper = styled.div`
  height: 50%;
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function EmptyList({ button, title, route, href }) {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Typography component="h3" variant="h3" align="center" gutterBottom>
        {title}
      </Typography>

      {!!button && (
        <PrimaryButton
          onClick={() => navigate(route)}
          variant="contained"
          mt={2}
          href={href}
        >
          {button}
        </PrimaryButton>
      )}
    </Wrapper>
  );
}

export default EmptyList;
