import { Fragment } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Divider, MenuItem as MuiMenuItem, Typography } from "@mui/material";

import { useAxios } from "../../../hooks";
import { colors } from "../../../pages/css components/Style";
import { alert } from "../../../redux/slices/alertSlice";

const MenuItem = styled(MuiMenuItem)`
  min-width: 200px;
`;

export default function DropDownMenu({
  setLoading,
  formikProps,
  setMenuAnchor,
}) {
  const axios = useAxios();
  const dispatch = useDispatch();

  return (
    <Fragment>
      <MenuItem onClick={() => handleDownloadFullReport()}>
        <Typography variant="h6" color={colors.purple}>
          Download Report
        </Typography>
      </MenuItem>

      <Divider style={{ marginBlock: 7 }} />

      <MenuItem onClick={() => downloadChannelReport()}>
        <Typography variant="h6" color={colors.purple}>
          Download Channel Report
        </Typography>
      </MenuItem>
    </Fragment>
  );
  function handleDownloadFullReport() {
    setMenuAnchor(null);
    setLoading(true);
    let url = "/brand/campaign/report/" + formikProps.values?.campaignID;

    axios({
      url: url,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        window.location.href = response?.data?.reportUrl;
        dispatch(
          alert({
            type: "success",
            message: "Report was successfully downloaded.",
          })
        );
        setLoading(false);
      }
    });
  }

  function downloadChannelReport() {
    setMenuAnchor(null);
    setLoading(true);
    axios({
      url:
        "/campaign/reports/channelwiseReport?campaignId=" +
        formikProps.values?.campaignID,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        window.location.href = response?.data?.reportUrl;
        dispatch(
          alert({
            type: "success",
            message: "Report was successfully downloaded.",
          })
        );
        setLoading(false);
      }
    });
  }
}
