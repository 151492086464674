import { Formik } from "formik";
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import FormDisplay from "./FormDisplay";
import pages from "../../../constants/pages";
import {
  objectFromFormData,
  signUpDetailsObject,
  validationSchema,
} from "./signUpDetailsObjects";
import { useAxios, useAlert } from "../../../hooks";
import VerifyDialog from "./verify account components/VerifyDialog";

export default function Form() {
  const { alert } = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [detail, setDetail] = useState(false);
  const [loginNavigation, setLoginNavigation] = useState(false);

  const axios = useAxios({ disableAuthToken: true });

  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={signUpDetailsObject}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(formData, { setSubmitting, resetForm }) => {
          var data = objectFromFormData(formData);
          axios({
            url: "/auth/signup",
            method: "POST",
            data: data,
            disableRedirect: true,
          })
            .then(async (res) => {
              if (res.status) {
                dispatch(
                  alert({
                    type: "success",
                    message: "User signed up successfully",
                  })
                );
                if (!res.data.is_email_verified) {
                  setDetail("Check your Mail box to verify your email.");

                  resetForm();
                  setLoginNavigation(true);
                } else {
                  navigate(pages.signIn.route);
                }
              } else {
                dispatch(
                  alert({
                    type: "error",
                    message: res.message,
                  })
                );
              }
              setSubmitting(false);
            })
            .catch((err) => {
              console.error(err);
            });
        }}
      >
        {(formikProps) => (
          <form>
            <FormDisplay formikProps={formikProps} />
          </form>
        )}
      </Formik>

      <VerifyDialog
        detail={detail}
        setDetail={setDetail}
        loginNavigation={loginNavigation}
        setLoginNavigation={setLoginNavigation}
      />
    </Fragment>
  );
}
