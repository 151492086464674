import { Grid } from "@mui/material";

import DynamicForm from "./DynamicForm";
import { PageHeader } from "../../../components";
import EditButtons from "../../../components/EditButtons";

export default function FormDisplay({
  editState,
  setEditState,
  formikProps,
  campaigns,
  channels,
}) {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <PageHeader
          pageName={"Add Campaign Stats"}
          pageHeader={"Add stats to the campaign"}
          buttons={[
            <EditButtons editState={editState} setEditState={setEditState} />,
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={6}>
          <DynamicForm
            editState={editState}
            setEditState={setEditState}
            channels={channels}
            formikProps={formikProps}
            campaigns={campaigns}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
