import { Formik } from "formik";
import { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";

import FormDisplay from "./FormDisplay";
import {
  changePasswordObject,
  validationSchema,
  objectFromFormData,
} from "./changePasswordObjects";
import { useAxios, useAlert } from "../../hooks";
import { useNavigate } from "react-router-dom";
import pages from "../../constants/pages";
import { signOutMember } from "../../redux/slices/memberSlice";

export default function Form() {
  const axios = useAxios();
  const { alert } = useAlert();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userID } = useSelector((state) => state.memberDetails);

  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={changePasswordObject}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(formData, { setSubmitting }) => {
          axios({
            url: "/user/auth/changePassword/" + userID,
            method: "PUT",
            data: objectFromFormData(formData),
          })
            .then((response) => {
              if (response.status) {
                dispatch(
                  alert({
                    message: "Password changed successfully!",
                    type: "success",
                  })
                );
                setTimeout(() => {
                  dispatch(signOutMember());
                }, 1500);
                // navigate(pages.brandDetails.route);
              } else {
                dispatch(
                  alert({
                    message: response.error,
                    type: "error",
                  })
                );
              }
              setSubmitting(false);
            })
            .catch(() => {
              setSubmitting(false);
            });
        }}
      >
        {(formikProps) => (
          <FormDisplay userID={userID} formikProps={formikProps} />
        )}
      </Formik>
    </Fragment>
  );
}
