import React from "react";
import { get } from "lodash";
import styled from "styled-components/macro";
import { Avatar, Grid, Typography } from "@mui/material";

import { uploadToS3 } from "../../../utils";
import { colors } from "../../css components/Style";
import { ErrorButton } from "../../css components/Button";
import { ComponentHeader, TextField } from "../../../components";
import { CgAsterisk } from "react-icons/cg";

const ImageAvatar = styled(Avatar)`
  height: 130px;
  width: 130px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`;

const AllowedImage = styled(Typography)`
  color: #333;
  font-size: 15px;
  opacity: 0.8;
  margin-top: 15px;
`;

export default function FormOne({ formikProps, editState }) {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <ComponentHeader title={"Agency Details"} subtitle={""} />
      </Grid>

      <Grid item textAlign="center" display="flex">
        <ImageAvatar src={formikProps.values.avatar} />
      </Grid>

      {editState && (
        <Grid item alignSelf={"center"}>
          <div style={{ display: "flex" }}>
            <input
              disabled={!editState}
              accept=".jpg,.jpeg,.png"
              style={{ display: "none" }}
              id="avatar-button-file"
              multiple
              onChange={(event) => handleFileChange(event, "avatar")}
              type="file"
            />
            <label htmlFor="avatar-button-file">
              <div
                className="add-new-logo"
                variant="contained"
                style={{ marginRight: "10px", backgroundColor: colors.purple }}
              >
                Add New Logo
              </div>
            </label>

            {formikProps.values.avatar && (
              <ErrorButton
                variant="outlined"
                onClick={() => {
                  formikProps.setValues((prev) => ({
                    ...prev,
                    avatar: "",
                  }));
                }}
              >
                Reset
              </ErrorButton>
            )}
          </div>

          <AllowedImage>
            Allowed JPG, GIF or PNG
            {!formikProps.values.brandAvatar && (
              <CgAsterisk style={{ color: colors.error }} />
            )}
            .
          </AllowedImage>
        </Grid>
      )}

      <Grid item xs={12}></Grid>

      <Grid item xs={4}>
        <TextField
          label="Agency Name"
          disabled={!editState}
          formikProps={formikProps}
          placeholder="Agency Name"
          name="agencyName"
          required
          inputProps={{ maxLength: 35 }}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          required
          label="Website"
          disabled={!editState}
          formikProps={formikProps}
          name="website"
          placeholder="Website"
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          label="About"
          disabled={!editState}
          formikProps={formikProps}
          placeholder="About"
          name="about"
          rows={3}
          required
          multiline
        />
      </Grid>
    </React.Fragment>
  );

  function handleFileChange({ target }, name) {
    let folderPath = "company_document/" + new Date().getTime() + "_";
    const file = get(target, "files[0]", "");
    let fileName = file?.name?.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, "");
    const uploadBucket = {
      name: "adn-media",
      region: "ap-south-1",
      identityPoolID: "ap-south-1:16fa1fcf-7cdd-4593-9ee0-6f621526defc",
    };

    uploadToS3({
      bucket: uploadBucket,
      filePath: folderPath + fileName,
      file,
    }).then((response) => {
      if (response.status) {
        formikProps.setFieldValue(
          [name],
          "https://" +
            uploadBucket.name +
            ".s3.ap-south-1.amazonaws.com/" +
            folderPath +
            fileName
        );
      }
    });
  }
}
