import React from "react";
import {
  Button,
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export default function Dialog({
  dialogState,
  setDialogState,
  handleAgree,
  dialogContent,
}) {
  const handleClose = () => {
    setDialogState({ ...dialogState, state: false });
  };

  return (
    <React.Fragment>
      <MUIDialog open={dialogState.state} onClose={handleClose}>
        <DialogTitle style={{ paddingBottom: "4px" }}>
          {dialogContent.title}
        </DialogTitle>

        <DialogContent>
          <DialogContentText>{dialogContent.body}</DialogContentText>
        </DialogContent>

        <DialogActions style={{ padding: "0px 24px 16px" }}>
          {Boolean(dialogContent.primaryButton) && (
            <Button
              onClick={() => {
                handleAgree();
                handleClose();
              }}
              variant="contained"
              color="primary"
            >
              {dialogContent.primaryButton}
            </Button>
          )}

          {Boolean(dialogContent.dangerButton) && (
            <Button
              onClick={() => {
                handleAgree();
                handleClose();
              }}
              variant="contained"
              style={{ background: "#d32f2f", color: "#fff" }}
            >
              {dialogContent.dangerButton}
            </Button>
          )}

          <Button
            onClick={handleClose}
            variant="outlined"
            color="primary"
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </MUIDialog>
    </React.Fragment>
  );
}
