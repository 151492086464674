import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";

import { useAxios } from "../../hooks";
import Business from "../../assets/business-removebg.png";
import Logo from "../../assets/auth carousel pics/verismart-background.png";
import pages from "../../constants/pages";
import { alert } from "../../redux/slices/alertSlice";

export default function Form({ code }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const axios = useAxios({ disableAuthToken: true });

  return (
    <div className="wrapper-verify">
      <div className="outer-verify-div">
        <div className="line-box">
          <div className="green-line"></div>
          <div className="purple-line"></div>
          <div className="yellow-line"></div>
        </div>

        <Grid container spacing={3} padding={"0 10px 0"} display={"block"}>
          <Grid item container xs={12} justifyContent={"center"}>
            <img src={Logo} className="image-verify-logo" alt="logo" />
          </Grid>

          <Grid
            item
            container
            xs={12}
            textAlign={"start"}
            margin={"25px 0 40px 0"}
          >
            <div className="image-text-div">
              <div
                className="image-business"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={
                    "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/2c3a1f49ab5f419da2b5542744edc32f/1716461856528_email.png"
                  }
                  className="business-image"
                  alt="!"
                />
              </div>

              <Typography className="business-heading" textAlign={"center"}>
                <Typography className="congrat-text">
                  {" "}
                  You are almost there! Welcome to the Verismart Ad Tech
                  Platform!{" "}
                </Typography>

                <Typography className="congrat-text-2">
                  Please click the button below to verify your email account and
                  proceed to the next steps. We’re excited to have you with us!
                </Typography>
                <Button
                  onClick={handleVerify}
                  variant="contained"
                  className="verify-btn"
                >
                  CLICK TO VERIFY
                </Button>
              </Typography>
            </div>
          </Grid>

          {/* <Grid item xs={12} display={"flex"} justifyContent={"end"}>
            <Copyright /> <Typography>Verismart</Typography>
          </Grid> */}
        </Grid>
      </div>
    </div>
  );

  function handleVerify() {
    axios({
      url: "/user/verifyEmail",
      method: "POST",
      data: {
        code: code,
      },
    }).then((res) => {
      if (res.status) {
        dispatch(
          alert({
            type: "success",
            message: res.message.displayMessage,
          })
        );
        navigate(pages.signIn.route);
      }
    });
  }
}
