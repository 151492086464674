import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { Grid, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";

import { useAxios } from "../../hooks";
import { PageHeader, Paper } from "../../components";
import Table from "./onboarding list component/Table";
import { PrimaryButton } from "../css components/Button";
import OnboardingDialog from "./onboarding dialog components/OnboardingDialog";

export default function WhatsappOnboarding() {
  const axios = useAxios({
    baseURL: "cpass_api",
  });
  const { activeBrand } = useSelector((state) => state.memberDetails);

  const [dataTable, setDataTable] = useState([]);
  const [onboardDialog, setOnboardDialog] = useState(false);

  useEffect(() => {
    axios({
      url: "/embedded_signup/phone-numbers/" + activeBrand.ID,
      disableRedirect: true,
    }).then((resp) => {
      if (resp.status) {
        setDataTable(resp.data || []);
      }
    });
  }, [axios, activeBrand.ID]);

  return (
    <Fragment>
      <PageHeader
        pageName={"WhatsApp Onboarding"}
        buttons={[
          <PrimaryButton
            variant="contained"
            onClick={() => setOnboardDialog(true)}
          >
            Onboard New Number <AddIcon />
          </PrimaryButton>,
        ]}
      />

      {Boolean(dataTable.length) ? (
        <Paper>
          <Grid item xs={12}>
            <Table dataTable={dataTable} />
          </Grid>
        </Paper>
      ) : (
        <Grid item xs={12} mt={25}>
          <Typography variant="h3" textAlign={"center"}>
            No Bot available
          </Typography>
        </Grid>
      )}

      <OnboardingDialog
        onboardDialog={onboardDialog}
        setOnboardDialog={setOnboardDialog}
      />
    </Fragment>
  );
}
