import styled from "styled-components";
import { Grid, IconButton, Paper, Tooltip } from "@mui/material";

import { TextAvatar } from "../../../components";
import {
  HeadingEighteen,
  TextFourteenBlack,
  colors,
} from "../../css components/Style";
import { useNavigate } from "react-router-dom";
import pages from "./../../../constants/pages";
import { CgEye } from "react-icons/cg";
import { convertDate } from "../../../utils";

const Card = styled(Grid)`
  display: flex;
  gap: 20px;
`;

const OuterCard = styled(Paper)`
  display: flex;
  gap: 20px;
  margin: 8px 0;
  padding: 10px;
`;

const Icon = styled(IconButton)`
  border: 1px solid ${colors.purple};
  color: ${colors.purple};
  height: 35px;
  width: 35px;
  margin: 0 5px;
  padding: 0 5px;
  &:hover {
    border: 1px solid ${colors.purple};
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
`;

export default function Table({ dataTable }) {
  const navigate = useNavigate();

  return (
    <Grid container spacing={0}>
      {dataTable.map((item, index) => (
        <Grid item xs={12} key={index}>
          <OuterCard elevation={1}>
            <Grid item alignSelf="center" paddingRight={"20px"}>
              <TextAvatar
                size={{ height: "40px", width: "40px" }}
                userName={item?.name}
              />
            </Grid>

            <Grid item xs>
              <Card container>
                <Grid item xs={1}>
                  <TextFourteenBlack>Bot Name</TextFourteenBlack>

                  <HeadingEighteen>{item?.name}</HeadingEighteen>
                </Grid>

                <Grid item xs={2}>
                  <TextFourteenBlack>Phone Number</TextFourteenBlack>

                  <HeadingEighteen>{item?.phone_number}</HeadingEighteen>
                </Grid>

                <Grid item xs={2}>
                  <TextFourteenBlack>Created At</TextFourteenBlack>

                  <HeadingEighteen>
                    {convertDate(item?.created_at)}
                  </HeadingEighteen>
                </Grid>

                <Grid item xs={2}>
                  <TextFourteenBlack>Created By</TextFourteenBlack>

                  <HeadingEighteen>{item?.created_by}</HeadingEighteen>
                </Grid>

                <Grid item xs={2}>
                  <TextFourteenBlack>status</TextFourteenBlack>

                  <HeadingEighteen>{item?.status}</HeadingEighteen>
                </Grid>

                <Grid item xs={1}>
                  <Tooltip title="View Bot Details">
                    <Icon>
                      <CgEye
                        onClick={() =>
                          navigate(pages.botDetailsPage.route + item?.agent_id)
                        }
                        style={{ height: "20px", width: "20px" }}
                      />
                    </Icon>
                  </Tooltip>
                </Grid>
              </Card>
            </Grid>
          </OuterCard>
        </Grid>
      ))}
    </Grid>
  );
}
