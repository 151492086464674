import React from "react";
import styled from "styled-components/macro";
import { Grid, Typography, MenuItem } from "@mui/material";

import { Paper, TextField } from "../../../components";
import { colors } from "../../css components/Style";

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: ${colors.purple};
`;

const Menu = styled(MenuItem)`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
`;

export default function FormOne({ formikProps, editState, brands }) {
  return (
    <React.Fragment>
      <Paper gridSpacing={6}>
        <Grid item xs={12}>
          <MainHeading>Brand Affiliation</MainHeading>
        </Grid>

        <Grid item xs={6}>
          <TextField
            select
            formikProps={formikProps}
            name="brandID"
            disabled={!editState}
            sx={{
              "& .MuiSvgIcon-root": {
                display: !editState && "none",
              },
            }}
            SelectProps={{
              displayEmpty: true,
            }}
            label="Brand"
          >
            <Menu value="" disabled>
              Select Brand
            </Menu>
            {brands.map((brand) => (
              <Menu key={brand.brand_id} value={brand.brand_id}>
                {brand.brand_name}
              </Menu>
            ))}
          </TextField>
        </Grid>
      </Paper>

      <Paper gridSpacing={6}>
        <Grid item xs={12}>
          <MainHeading>Add New User</MainHeading>
        </Grid>

        <Grid item xs={6}>
          <TextField
            disabled={!editState}
            name="givenName"
            placeholder="Given Name"
            label="Given Name"
            formikProps={formikProps}
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            disabled={!editState}
            name="familyName"
            placeholder="Family Name"
            label="Family Name"
            formikProps={formikProps}
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            disabled={!editState}
            name="email"
            placeholder="Email"
            label="Email"
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            type="password"
            disabled={!editState}
            name="password"
            placeholder="********"
            label="Password"
            formikProps={formikProps}
            value={"********"}
          />
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
