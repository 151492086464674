import React from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { Avatar, Grid, Tooltip } from "@mui/material";

import pages from "../../../constants/pages";
import { TextField } from "../../../components";

const ImageAvatar = styled(Avatar)`
  height: 130px;
  width: 130px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`;

export default function FormOne({ formData }) {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      {/* <Paper gridSpacing={6}> */}
      <Grid item xs={6} textAlign="center" display="flex">
        <ImageAvatar src={formData.brandAvatar} />
      </Grid>

      <Grid item xs={6} display={"flex"} justifyContent={"end"}>
        {formData.agency?.agency_name && (
          <Tooltip title="View Agency Details">
            <div
              className="agency-name-brand"
              onClick={() =>
                navigate(
                  pages.unverifiedAgenciesDetails.route +
                    "?agencyID=" +
                    formData.agency?.agency_id
                )
              }
            >
              Agency - {formData.agency?.agency_name}
            </div>
          </Tooltip>
        )}
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Brand Name"
          disabled
          value={formData.brandName}
          placeholder="Brand Name"
          name="brandName"
          size="small"
        />
        <TextField
          size="small"
          value={formData.onlineStore}
          disabled
          name="onlineStore"
          placeholder="Online Store"
          label="Online Store"
          sx={{ marginTop: "30px" }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          size="small"
          value={formData.about}
          disabled
          placeholder="About"
          label="About"
          name="about"
          rows={5}
          multiline
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          size="small"
          value={formData.website}
          disabled
          name="website"
          placeholder="Website"
          label="Website"
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          size="small"
          multiline
          name="siteCategory"
          label="Site Category"
          disabled
          value={formData.siteCategory.map((cat) => cat.name).join(",")}
        />
      </Grid>
    </React.Fragment>
  );
}
