import { Formik } from "formik";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Grid, Checkbox, Typography } from "@mui/material";
import { ToggleOffOutlined, ToggleOnOutlined } from "@mui/icons-material";

import pages from "../../../../constants/pages";
import {
  AutocompleteMultiSelect,
  Label,
  Autocomplete,
  TextField,
} from "../../../../components";
import { colors } from "../../../css components/Style";
import { useAxios, useComponent } from "../../../../hooks";

const Heading = styled(Typography)`
  font-size: 15px;
  font-weight: 500;
  line-height: 27px;
  align-self: center;
`;

export default function AudienceForm({
  audienceData,
  component,
  formikProps,
  editState,
}) {
  const axios = useAxios();
  const navigate = useNavigate();
  const { loader } = useComponent();
  const [fields, setFields] = useState([]);

  useEffect(() => {
    // loader.start(2);
    axios({
      url: "/adtech/getAllDynamicFields",
      method: "GET",
      disableRedirect: true,
    })
      .then((response) => {
        if (response.status) {
          setFields(response.data);
        }
      })
      .catch((err) => console.error(err));
  }, [axios, loader]);

  return (
    <Fragment>
      <Formik enableReinitialize initialValues={audienceData}>
        {(formikProps) => (
          <Fragment>
            <Grid item xs={12}>
              <TextField
                label="Audience Group Name"
                formikProps={formikProps}
                name="audience_group.value"
                placeholder="Audience Group Name"
                disabled
              />
            </Grid>

            {fields.map(
              (field, index) =>
                audienceData.hasOwnProperty([field.name]) && (
                  <Fragment>
                    {/* <Grid item xs={12}>
                <DpCount>
                  {formikProps.values[field.name]?.userCount > 0 &&
                    `${
                      formikProps.values[field.name]?.userCount
                    } Million Users`}
                </DpCount>
              </Grid> */}

                    {field.control_id === 3 && (
                      <Grid item xs={6}>
                        <Mandatory field={field} />

                        <TextField
                          value={audienceData[field.name]?.value}
                          label={field.label}
                          name={`${field.name}.value`}
                          placeholder={field.label}
                          disabled
                        />
                      </Grid>
                    )}

                    {field.control_id === 1 && (
                      <Fragment>
                        <Grid item xs={3}>
                          <Heading
                            style={{
                              padding: "12px 0",
                              color: formikProps.values[field.name]?.mandatory
                                ? "red"
                                : "gray",
                            }}
                          >
                            &nbsp;
                          </Heading>

                          <TextField
                            size="small"
                            label={field.options[0].label}
                            value={
                              audienceData[field.name]?.value[
                                field.options[0]["value"]
                              ]
                            }
                            name={`${field.name}.value.${field.options[0]["value"]}`}
                            placeholder={field.options[0].label}
                            disabled
                          />
                        </Grid>

                        <Grid item container xs={3}>
                          <Grid
                            item
                            display={"flex"}
                            alignItems={"center"}
                            marginLeft={"auto"}
                          >
                            <Heading
                              sx={{
                                padding: "12px 0",
                                color: formikProps.values[field.name]?.mandatory
                                  ? "red"
                                  : "gray",
                              }}
                            >
                              Mandatory
                            </Heading>

                            <Checkbox
                              style={{ display: "flex", justifyContent: "end" }}
                              disabled
                              name={`${field.name}.mandatory`}
                              checked={
                                formikProps.values[field.name]?.mandatory
                              }
                              icon={<ToggleOffOutlined fontSize="large" />}
                              checkedIcon={
                                <ToggleOnOutlined
                                  fontSize="large"
                                  style={{ color: "red" }}
                                />
                              }
                            />
                          </Grid>

                          <TextField
                            size="small"
                            label={field.options[1].label}
                            value={
                              audienceData[field.name]?.value[
                                field.options[1]["value"]
                              ]
                            }
                            name={`${field.name}.value.${field.options[1]["value"]}`}
                            placeholder={field.options[1].label}
                            disabled
                          />
                        </Grid>
                      </Fragment>
                    )}

                    {field.control_id === 2 && field.multi_select && (
                      <Grid item xs={6}>
                        <Mandatory field={field} />

                        <AutocompleteMultiSelect
                          label={field.label}
                          name={`${field.name}.value`}
                          value={audienceData[field.name]?.value}
                          disabled
                          defaults={{
                            primaryKey: "value",
                            displayLabel: "label",
                          }}
                          options={field.options}
                          formikProps={formikProps}
                          placeholder={field.placeholder}
                        />
                      </Grid>
                    )}

                    {field.control_id === 2 && !field.multi_select && (
                      <Grid item xs={6}>
                        <Mandatory field={field} />

                        <Autocomplete
                          label={field.label}
                          name={`${field.name}.value`}
                          placeholder={field.placeholder}
                          defaults={{
                            primaryKey: "value",
                            displayLabel: "label",
                          }}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              display: "none",
                            },
                          }}
                          formikProps={formikProps}
                          options={field.options}
                          value={audienceData[field.name]?.value}
                        />
                      </Grid>
                    )}

                    {field.control_id === 4 && (
                      <Grid item xs={6}>
                        <Mandatory field={field} />

                        <DesktopDatePicker
                          label={field.label}
                          name={`${field.name}.value`}
                          formikProps={formikProps}
                          disabled
                          value={formikProps.values[field.name]?.value}
                          onChange={(newValue) => {
                            let date = new Date(newValue);

                            let d =
                              date.getFullYear() +
                              "-" +
                              "0" +
                              (date.getMonth() + 1) +
                              "-" +
                              date.getDate();

                            formikProps.setValues((prevVal) => ({
                              ...prevVal,
                              [field.name]: {
                                ...prevVal[field.name],
                                value: d,
                              },
                            }));
                          }}
                          renderInput={(params) => (
                            <TextField
                              disabled
                              name={`${field.name}.value`}
                              formikProps={formikProps}
                              {...params}
                            />
                          )}
                        />
                      </Grid>
                    )}
                  </Fragment>
                )
            )}

            {audienceData?.additionalFields?.map((field, index) => (
              <Fragment>
                <Grid style={{ color: colors.purple }} item xs={10}>
                  <Label title={"Additional Field " + (index + 1)} />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    size="small"
                    label="Key"
                    disabled
                    value={field.key}
                    name={"additionalFields[" + index + "].key"}
                    placeholder="key"
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    size="small"
                    disabled
                    label="Value"
                    value={field.value}
                    name={"additionalFields[" + index + "].value"}
                    placeholder="Value"
                  />
                </Grid>

                {/* {editState && (
                  <Grid item xs={2} alignSelf={"center"}>
                    <Tooltip title={"Delete additional field" + index}>
                      <IconButton
                        style={{ color: colors.error }}
                        onClick={() => handleDelete(index)}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )} */}
              </Fragment>
            ))}

            <Grid item xs={10} display={"flex"} justifyContent={"start"}>
              <Typography
                style={{ color: colors.purple }}
                variant="h4"
                fontWeight={"600"}
                marginRight={"10%"}
              >
                {" "}
                Non-Mandatory Field Count Percentage
              </Typography>

              <Heading style={{ color: colors.purple }}>
                {audienceData.percentage?.value || 0}
              </Heading>
            </Grid>
          </Fragment>
        )}
      </Formik>

      {component === "" && !!formikProps.values.campaignID && editState && (
        <Grid item xs={12}>
          <Typography
            onClick={() =>
              navigate(
                pages.audienceDetails.route + audienceData.ID + "?mode=edit"
              )
            }
            style={{ color: colors.purple, cursor: "pointer" }}
          >
            Edit Audience Group Details
          </Typography>
        </Grid>
      )}
    </Fragment>
  );

  function Mandatory({ field }) {
    return (
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Heading
          style={{
            color: audienceData[field.name]?.mandatory ? "red" : "gray",
          }}
        >
          Mandatory
        </Heading>

        <Checkbox
          disabled
          name={`${field.name}.mandatory`}
          checked={audienceData[field.name]?.mandatory}
          icon={<ToggleOffOutlined fontSize="large" />}
          checkedIcon={
            <ToggleOnOutlined
              fontSize="large"
              style={{
                color: audienceData[field.name]?.mandatory ? "red" : "gray",
              }}
            />
          }
        />
      </div>
    );
  }
}
