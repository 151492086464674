import React from "react";
import styled from "styled-components/macro";
import { DialogContent, Dialog, Grid, Typography } from "@mui/material";
import { titleCase } from "change-case-all";
import { colors } from "../../../css components/Style";

const Card = styled(Dialog)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const Link = styled(Typography)`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: ${colors.blue};
  cursor: pointer;
`;

export default function StatusDialog({ detail, setDetail }) {
  const handleClose = () => {
    setDetail({ state: false });
  };

  return (
    <Card
      open={detail.state}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "800px!important",
        },
      }}
    >
      <DialogContent>
        <Grid container spacing={3} style={{ padding: "20px" }}>
          <Grid item xs={12} display="flex">
            <Link color="#333">Hey! Your campaign has been &nbsp; </Link>
            <Link
              style={{
                color:
                  detail.connection?.requestStatus === "REQUESTED"
                    ? colors.yellow
                    : detail.connection?.requestStatus === "REJECTED"
                    ? colors.error
                    : colors.green,
              }}
            >
              {detail.connection?.requestStatus}.
            </Link>
          </Grid>

          {!!detail.connection?.note && (
            <Grid item xs={12} display="flex">
              <Typography variant="h5">Note : &nbsp; </Typography>
              <Typography variant="h5">
                {titleCase(detail.connection?.note)}
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Card>
  );
}
