import React, { Fragment } from "react";
import { Grid, Typography } from "@mui/material";

export default function ComponentHeader({
  icons = [],
  title,
  subtitle,
  buttons = [],
}) {
  return (
    <Grid
      container
      style={{ minHeight: "35px", marginTop: "4px" }}
      alignItems="center"
    >
      <Grid item xs={4}>
        <Typography style={{ fontSize: "18px", fontWeight: "600" }}>
          {title}
        </Typography>

        {!!subtitle && (
          <Typography
            variant="subtitle2"
            gutterBottom
            style={{ color: "#808080b3" }}
          >
            {subtitle}
          </Typography>
        )}
      </Grid>

      <Grid
        item
        xs={8}
        justifyContent="flex-end"
        style={{ gap: "5px" }}
        container
      >
        {icons.length > 0 &&
          icons.map((icon, index) => (
            <Grid alignSelf={"center"} item key={index}>
              {icon}
            </Grid>
          ))}

        {buttons.length > 0 &&
          buttons.map((button, index) => (
            <Fragment key={index}>{button}</Fragment>
          ))}
      </Grid>
    </Grid>
  );
}
