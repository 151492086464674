import { Grid, Button, Box, Typography as MuiTypography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AiOutlineStop } from "react-icons/ai";

const Wrapper = styled(Box)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled(AiOutlineStop)`
  width: 100px;
  height: 100px;
  color: red;
`;

const Typography = styled(MuiTypography)`
  text-align: center;
`;

export default function PermissionError() {
  const navigate = useNavigate();
  const steps = JSON.parse(sessionStorage.getItem("ladder"));

  return (
    <Wrapper>
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <Icon />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h2">Permission Denied</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            You do not have the required permissions to access this page.
          </Typography>

          <Typography variant="h5" gutterBottom>
            Please contact admin for more details!
          </Typography>
        </Grid>

        {steps.step2 && steps.step3 && (
          <Grid item>
            <Button variant="contained" onClick={() => navigate("/")}>
              Home
            </Button>
          </Grid>
        )}

        <Grid item>
          <Button variant="contained" onClick={() => navigate(-2)}>
            Back
          </Button>
        </Grid>
      </Grid>
    </Wrapper>
  );
}
