import { useState, Fragment } from "react";
import { CgProfile } from "react-icons/cg";
import { Menu } from "@mui/material";

import DropDownMenu from "./drop down menu components/DropDownMenu";
import { colors } from "../../../pages/css components/Style";
import { useSelector } from "react-redux";

export default function DropDownButton() {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const { role } = useSelector((state) => state.memberDetails);

  return (
    <Fragment>
      <CgProfile
        style={{
          height: "30px",
          width: "30px",
          color: colors.purple,
          cursor: "pointer",
        }}
        onClick={(event) => {
          setMenuAnchor(event.currentTarget);
        }}
      />

      <Menu
        style={{ marginTop: "15px" }}
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <DropDownMenu role={role} setMenuAnchor={setMenuAnchor} />
      </Menu>
    </Fragment>
  );
}
