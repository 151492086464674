import { useState } from "react";
import { Info } from "@mui/icons-material";
import styled from "styled-components/macro";
import { Grid, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { Paper, TextAvatar } from "../../../components";
import {
  HeadingEighteen,
  TextFourteenBlack,
  colors,
} from "../../css components/Style";
import { useAxios, useComponent } from "../../../hooks";
import { brandDetailsObject } from "../../brand components/brand details components/brandDetailObject";
import { PrimaryLoadingButton } from "../../css components/Button";
import StatusDialog from "./dialog components/VerifyDialog";

const Card = styled(Grid)`
  display: flex;
  gap: 20px;
`;

const OuterCard = styled(Paper)`
  display: flex;
  gap: 20px;
  margin: 8px 0;
  padding: 10px;
`;

export default function List({
  users,
  formData,
  campaignID,
  connections,
  connectNowLoading,
  setConnectNowLoading,
  setSentChanges,
}) {
  const axios = useAxios();
  const dispatch = useDispatch();
  const { alert } = useComponent();

  const [loading, setLoading] = useState({});
  const [details, setDetails] = useState({ state: false });
  const [brandDetails, setBrandDetails] = useState({
    data: brandDetailsObject,
    state: false,
  });

  const memberDetails = useSelector((state) => state.memberDetails);

  return (
    <Grid container spacing={0}>
      {connections?.map((connection, index) => (
        <Grid item xs={12} key={index}>
          <OuterCard elevation={1}>
            <Grid item alignSelf="center" paddingRight={"20px"}>
              <TextAvatar
                size={{ height: "40px", width: "40px" }}
                userName={`P ${index + 1}`}
              />
            </Grid>

            <Grid item xs>
              <Card container spacing={2}>
                <Grid item xs={1}>
                  <TextFourteenBlack>MAU</TextFourteenBlack>
                  <HeadingEighteen>{connection?.brand?.mau}</HeadingEighteen>
                </Grid>

                <Grid item xs={1}>
                  <TextFourteenBlack> Location</TextFourteenBlack>
                  <HeadingEighteen sx={{ textTransform: "uppercase" }}>
                    {connection?.brand?.locations?.length > 1
                      ? connection?.brand?.locations
                          .map((loc) => loc.substr(0, 2))
                          .join(", ")
                      : connection?.brand?.locations[0]?.substr(0, 2)}
                  </HeadingEighteen>
                </Grid>

                {/* <Grid item xs={1}>
                  <TextFourteenBlack> Languages</TextFourteenBlack>
                  <HeadingEighteen sx={{ textTransform: "uppercase" }}>
                    {connection.brand.languages.length > 1
                      ? connection?.brand?.languages
                          ?.map((lan) => lan.substr(0, 2))
                          .join(", ")
                      : connection?.brand?.languages[0]?.substr(0, 2)}
                  </HeadingEighteen>
                </Grid> */}

                <Grid item xs={3}>
                  <TextFourteenBlack> Category</TextFourteenBlack>
                  <HeadingEighteen
                    sx={{ textTransform: "uppercase", display: "flex" }}
                  >
                    {connection.brand.category
                      .slice(0, 1)
                      .map((loc) => loc)
                      .join(", ")}
                    {connection.brand.category.length > 1 && (
                      <Tooltip
                        title={connection.brand.category
                          .map((loc) => loc)
                          .join(", ")}
                      >
                        <div className="extra-chip">
                          {connection.brand.category.length}
                        </div>
                      </Tooltip>
                    )}
                  </HeadingEighteen>
                </Grid>

                <Grid item xs={1}>
                  <TextFourteenBlack>
                    {formData.campaignStatus === "ACTIVE"
                      ? "Final User Count"
                      : "Expected User Count"}
                  </TextFourteenBlack>
                  <HeadingEighteen sx={{ textTransform: "uppercase" }}>
                    {connection.proof.usersCount || "N.A"}
                  </HeadingEighteen>
                </Grid>

                <Grid item xs={2}>
                  <TextFourteenBlack> Mandate Match</TextFourteenBlack>
                  <HeadingEighteen sx={{ textTransform: "uppercase" }}>
                    {connection.mandateMatched + "/" + users?.neededMandate}
                  </HeadingEighteen>
                </Grid>

                <Grid
                  item
                  xs
                  alignSelf={"center"}
                  display="flex"
                  justifyContent="end"
                >
                  {!connection?.isRequested ? (
                    formData.campaignStatus !== "ACTIVE" && (
                      <PrimaryLoadingButton
                        loading={loading[index]}
                        variant="contained"
                        onClick={() =>
                          handleConnection(
                            connection.user_id,
                            connection.business_entity_id,
                            index
                          )
                        }
                      >
                        {`Connect`}
                      </PrimaryLoadingButton>
                    )
                  ) : (
                    <HeadingEighteen
                      style={{
                        color:
                          connection.requestStatus === "REQUESTED" ||
                          connection.requestStatus === "PENDING"
                            ? colors.yellow
                            : connection.requestStatus === "REJECTED" ||
                              connection.requestStatus === "MISSED"
                            ? colors.error
                            : colors.green,
                      }}
                    >
                      {connection?.requestStatus === "REJECTED" &&
                        "Offer Rejected"}
                      {connection?.requestStatus === "ACCEPTED" &&
                        "Offer Accepted"}
                      {connection?.requestStatus === "REQUESTED" &&
                        "Offer Requested"}
                      {connection?.requestStatus === "PENDING" &&
                        "Offer Pending"}
                      {connection?.requestStatus === "MISSED" && "Offer Missed"}

                      {!!connection.note && (
                        <Info
                          color="primary"
                          style={{
                            cursor: "pointer",
                            position: "relative",
                            top: "4px",
                          }}
                          onClick={() => handleClick(connection)}
                        />
                      )}
                    </HeadingEighteen>
                  )}
                </Grid>
              </Card>
            </Grid>
          </OuterCard>
        </Grid>
      ))}

      <StatusDialog detail={details} setDetail={setDetails} />
    </Grid>
  );

  function handleConnection(userId, receiverBrandId, index) {
    setLoading({ [index]: true });
    axios({
      url: "/brand?businessEntityId=" + receiverBrandId,
    }).then((response) => {
      if (response.status) {
        axios({
          url: "/user/sendConnectionRequest",
          method: "POST",
          disableRedirect: true,
          data: {
            sender_userId: parseInt(memberDetails.userID),
            sender_brandId: parseInt(memberDetails.activeBrand.ID),
            sender_campaignId: parseInt(campaignID),
            receiver_userId: parseInt(userId),
            receiver_brandId: parseInt(response.data[0]?.brand_id),
          },
        }).then((res) => {
          if (res.status) {
            setSentChanges((prevVal) => ({
              ...prevVal,
              requestSent: response.data[0]?.brand_id,
            }));
            dispatch(alert.success("Connection request sent."));
          }
          setLoading({ [index]: false });
        });
      }
    });
  }

  function handleClick(connection) {
    setDetails({
      state: true,
      connection: connection,
    });
  }
}
