export default function validateFileUpload({
  file,
  allowedExtension,
  maxSize,
}) {
  if (!file) {
    return { status: false, message: "No file uploaded" };
  }

  const uploadedFileExtensions = file.name.split(".");
  const uploadedFileExtension =
    uploadedFileExtensions[uploadedFileExtensions.length - 1].toLowerCase();

  if (!allowedExtension.includes(uploadedFileExtension)) {
    return { status: false, message: "Invalid extension" };
  }

  if (file.size / 1024 > maxSize) {
    return { status: false, message: "File too large" };
  }

  return { status: true };
}
