import { createSlice } from "@reduxjs/toolkit";

import { signInMember, signOutMember } from "./memberSlice";

const alertInitialState = {
  state: false,
  type: "success",
  message: "",
  multiple: [],
};

export const alertSlice = createSlice({
  name: "alert",
  initialState: alertInitialState,
  reducers: {
    alert: (_, { payload }) => ({
      state: true,
      type: payload.type,
      message: payload.message,
      multiple: payload.multiple,
    }),

    closeAlert: (state) => ({
      ...state,
      state: false,
    }),
  },

  extraReducers: {
    [signInMember.rejected]: (state) => ({
      ...state,
      state: true,
      type: "error",
      message: "Your session has expired!",
    }),

    [signOutMember.fulfilled]: (state) => ({
      ...state,
      state: true,
      type: "info",
      message: "You have been logged out!",
    }),
  },
});

export const { alert, closeAlert } = alertSlice.actions;

export default alertSlice.reducer;
