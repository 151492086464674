import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";

import { FileUpload, TextField, Label } from "../../../../components";

export default function AddSampleMedia({ formikProps }) {
  var extensions;
  const { activeBrand } = useSelector((state) => state.memberDetails);

  if (formikProps.values.templateHeader?.type === "image") {
    extensions = ".jpg, .jpeg, .jfif, .pjpeg, .pjp, .png, .svg, .webp,image/*";
  } else if (formikProps.values.templateHeader?.type === "video") {
    extensions = "video/mp4,video/x-m4v,video/*";
  } else {
    extensions = ".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf";
  }

  return (
    <React.Fragment>
      {Boolean(formikProps.values.templateHeader?.type) &&
        Boolean(formikProps.values.templateHeader?.count_of_variable !== 0) && (
          <Grid item xs={12}>
            <Typography variant="caption1">Header Variable</Typography>
          </Grid>
        )}

      {formikProps.values.templateHeader.type === "text" &&
        Boolean(formikProps.values.templateHeader.type) &&
        Array(formikProps.values?.templateHeader?.count_of_variable)
          .fill()
          .map((_, index) => (
            <Fragment>
              <Grid item xs={4}>
                <Label
                  title={"Header Variable Value " + (index + 1)}
                  formikProps={formikProps}
                  required={true}
                  name={"templateHeader.example[" + index + "].example"}
                />
              </Grid>

              <Grid key={index} item mt={1} xs={6}>
                <TextField
                  formikProps={formikProps}
                  disabled={Boolean(formikProps.values.ID)}
                  name={"templateHeader.example[" + index + "].example"}
                />
              </Grid>
            </Fragment>
          ))}

      {formikProps.values.templateHeader.type !== "text" &&
        Boolean(formikProps.values.templateHeader.type) &&
        Array(formikProps.values?.templateHeader?.count_of_variable)
          .fill()
          .map((_, index) => (
            <Fragment>
              <Grid item xs={4}>
                <Label
                  title={"Header Variable Value " + (index + 1)}
                  formikProps={formikProps}
                  required={true}
                  name={"templateFooter.text"}
                />
              </Grid>

              <Grid key={index} item mt={1} xs={6}>
                <FileUpload
                  formikProps={formikProps}
                  name={"templateHeader.example[" + index + "].example"}
                  allowedExtension={extensions}
                  disabled={Boolean(formikProps.values.ID)}
                  maxSize={25}
                  folderPath={"templates/" + activeBrand.ID + "/"}
                />
              </Grid>
            </Fragment>
          ))}
    </React.Fragment>
  );
}
