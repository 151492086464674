export const botDetailsObject = {
  agentID: "",
  channel: "whatsapp",
  phoneNumberID: "",
  createdAt: "",
  isActive: true,
  updatedAt: "",
  wabaID: "",
  cloudAPI: true,
  namespace: "",
};

export function objectFromResponse(responseData) {
  return {
    agentID: responseData.agent_id,
    channel: responseData.channel,
    phoneNumberID: responseData.phone_number_id,
    createdAt: responseData.created_at,
    isActive: responseData.is_active,
    updatedAt: responseData.updated_at,
    wabaID: responseData.waba_id,
    cloudAPI: responseData.cloud_api,
    namespace: responseData.namespace,
  };
}

export function objectFromFormData(formData) {
  return {
    agent_id: formData.agentID,
    channel: formData.channel,
    phone_number_id: formData.phoneNumberID,
    created_at: formData.createdAt,
    is_active: formData.isActive,
    updated_at: formData.updatedAt,
    waba_id: formData.wabaID,
    cloud_api: formData.cloudAPI,
    namespace: formData.namespace,
  };
}
