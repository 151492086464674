import _ from "lodash";
import TextFieldWithIcon from "./TextFieldWithIcon";
import { Tooltip, IconButton } from "@mui/material";
import { FileCopy } from "@mui/icons-material";

export default function TextFieldWithCopy(props) {
  return (
    <TextFieldWithIcon
      {...props}
      icon={
        <Tooltip title={"Copy " + props.label}>
          <IconButton
            style={{ padding: 0, margin: "12px" }}
            color="primary"
            onClick={() => {
              navigator.clipboard.writeText(
                props.value || _.get(props.formikProps.values, props.name)
              );
            }}
          >
            <FileCopy fontSize="small" />
          </IconButton>
        </Tooltip>
      }
    />
  );
}
