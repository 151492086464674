import { Fragment } from "react";
import { Formik } from "formik";

import FormDisplay from "./FormDisplay";

export default function Form({
  formData,
  channels,
  campaigns,
  editState,
  setEditState,
}) {
  return (
    <Formik enableReinitialize initialValues={formData} validateOnMount>
      {(formikProps) => (
        <Fragment>
          <FormDisplay
            channels={channels}
            setEditState={setEditState}
            editState={editState}
            campaigns={campaigns}
            formikProps={formikProps}
          />
        </Fragment>
      )}
    </Formik>
  );
}
