import styled from "styled-components";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import pages from "../../constants/pages";
import { colors } from "../css components/Style";
import { useAxios, useComponent } from "../../hooks";
import { alert } from "../../redux/slices/alertSlice";
import { CancelButton } from "../css components/Button";
import { signOutMember } from "../../redux/slices/memberSlice";
import WelcomeCards from "./login user components/WelcomeCards";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 0 20px;
`;

export default function WelcomeUnverified() {
  const axios = useAxios();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loader } = useComponent();

  const [steps, setStep] = useState({});
  const [status, setStatus] = useState(false);
  const [verification, setVerification] = useState({
    brand: false,
    admin: false,
  });
  const userID = sessionStorage.getItem("userID");
  const brandID = sessionStorage.getItem("brandID");
  const role = sessionStorage.getItem("role");

  const { siteLogo } = useSelector((state) => state.renderUIDetails);

  useEffect(() => {
    axios({
      url: "/user/info?userId=" + userID,
      disableRedirect: true,
    }).then((res) => {
      if (res.status) {
        sessionStorage.setItem("ladder", JSON.stringify(res.data?.steps));
        setStep(res.data.steps);
        if (parseInt(res.data.brand?.brand_id) === parseInt(brandID)) {
          if (res.data.brand?.is_brand_verified === 1) {
            setVerification({
              brand: true,
            });
          }
          if (res.data.brand?.is_brand_verified === 2) {
            dispatch(
              alert({
                type: "info",
                message: "Brand has been rejected by the admin!",
              })
            );
            dispatch(signOutMember());
            navigate(pages.signIn.route);
          }
        }
      }
    });
  }, [axios, userID, loader, dispatch, navigate, brandID]);

  function checkStatus() {
    setStatus(true);
    axios({
      url: "/user/info?userId=" + userID,
      disableRedirect: true,
    }).then((res) => {
      if (res.status) {
        if (parseInt(res.data.brand?.brand_id) === parseInt(brandID)) {
          if (res.data.brand?.is_brand_verified === 0) {
            dispatch(
              alert({
                type: "warning",
                message: "Brand hasn't been approved by the admin yet!",
              })
            );
          }

          if (res.data.brand?.is_brand_verified === 1) {
            setVerification({
              brand: true,
            });

            dispatch(
              alert({
                type: "success",
                message: "Brand has been approved by the admin!",
              })
            );
          }
          if (res.data.brand?.is_brand_verified === 2) {
            dispatch(
              alert({
                type: "info",
                message: "Brand has been rejected by the admin!",
              })
            );
            dispatch(signOutMember());
            navigate(pages.signIn.route);
          }
        }
        setStatus(false);
      }
    });
  }

  return (
    <Wrapper>
      <div className="wrapper-container">
        <div style={{ textAlign: "center" }}>
          <img className="logo-image" src={siteLogo} alt="logo" />
        </div>
      </div>

      <div className="nav-div">
        <Typography className="choose-brand who">
          Let's setup your brand profile
        </Typography>
      </div>

      <WelcomeCards steps={steps} verification={verification} />

      {steps.step2 && steps.step3 && !verification.brand && (
        <div className="admin-status">
          <Typography
            style={{
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            Your {role === "AGENCY" ? "brand" : "account"} is waiting for the
            admin approval!
            <Typography
              style={{ cursor: "pointer" }}
              variant="button"
              color={colors.purple}
              onClick={checkStatus}
              disabled={status}
            >
              {" "}
              Click to check status.
            </Typography>
          </Typography>
        </div>
      )}

      {steps.step2 && steps.step3 && verification.brand && (
        <div className="admin-approve-status">
          <Typography
            style={{
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            Your account has been approved by the admin. Click here to{" "}
            <Typography
              style={{ cursor: "pointer" }}
              variant="button"
              color={colors.purple}
              onClick={() => {
                dispatch(signOutMember());
                navigate(pages.signIn.route);
              }}
            >
              Login
            </Typography>
          </Typography>
        </div>
      )}

      <div className="bottom-div">
        <div></div>

        <div style={{ gap: 5 }}>
          <CancelButton
            style={{ float: "right" }}
            variant="outlined"
            onClick={() => dispatch(signOutMember())}
          >
            Logout
          </CancelButton>
        </div>
      </div>
    </Wrapper>
  );
}
