import { useParams } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import { Fragment, useEffect, useState } from "react";
import { Grid } from "@mui/material";

import EmptyList from "../../misc/EmptyList";
import List from "../campaign list components/List";
import { useAxios, useComponent } from "../../../hooks";
import { Loader, PageHeader } from "../../../components";
import Search from "../campaign list components/superadmin list components/Search";
import SortBy from "../campaign list components/superadmin list components/SortBy";
import Categorize from "../campaign list components/superadmin list components/Categorize";
import BrandList from "../../superAdmin brand components/brand list components/BrandList";
import { getSuperAdminCampaigns } from "../create campaign components/campaign form components/campaignObject";

export default function SuperAdminCampaignList() {
  const axios = useAxios();
  const params = useParams();
  const { loader } = useComponent();

  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("ALL");
  const [searchKey, setSearchKey] = useState("");
  const [lastPage, setLastPage] = useState({ totalCount: 0, lastPage: false });

  const [campaigns, setCampaigns] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [categorize, setCategorize] = useState("Campaign Offers");

  const brandID = params.brandID || "";

  useEffect(() => {
    loader.start();

    if (categorize === "Campaign Offers") {
      let url =
        "/user/brand/getAllCampaigns" +
        `?page=${page}&limit=10` +
        (Boolean(searchKey) ? `&search=${encodeURIComponent(searchKey)}` : "") +
        (sortBy !== "ALL" ? `&campaignStatus=${sortBy}` : "");

      axios({
        url: url,
        method: "GET",
      }).then((response) => {
        if (response.status) {
          if (response.data.formattedCampaigns.length > 0) {
            setLastPage({
              totalCount: response.data.totalCount,
              lastPage: response.data.formattedCampaigns.length < 10,
            });
            setCampaigns(getSuperAdminCampaigns(response));
          } else {
            setLastPage({
              totalCount: response.data.totalCount,
              lastPage: true,
            });
            setCampaigns(response.data.formattedCampaigns);
          }

          loader.stop();
        }
      });
    }

    if (categorize === "Unverified Campaigns") {
      axios({
        url:
          "/user/brand/getUnApprovedCampaigns" +
          `?page=${page}&limit=10` +
          (Boolean(searchKey)
            ? `&search=${encodeURIComponent(searchKey)}`
            : ""),
      }).then((response) => {
        if (response.status) {
          let arr = [];

          for (let key of response.data.formattedCampaigns) {
            let data = {};
            for (let fields of key.fields) {
              data[Object.keys(fields)[0]] = Object.values(fields)[0];
            }
            arr.push({
              ...data,
              brand: {
                name: key.brand.brand_name,
                logo: key.brand.brand_logo,
              },
              previewImage: !!key.preview_image
                ? key.preview_image
                : "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_placeholder.png",
              status: key.status,
              audience_id: key.audience_id,
              brand_id: key.brand_id,
              campaign_id: key.campaign_id,
            });
          }

          setCampaigns(arr);

          if (response.data.formattedCampaigns.length > 0) {
            setLastPage({
              totalCount: response.data.totalCounts,
              lastPage: response.data.formattedCampaigns.length < 10,
            });
          } else {
            setLastPage({
              totalCount: response.data.totalCounts,
              lastPage: true,
            });
          }

          loader.stop();
        }
      });
    }

    if (categorize === "Brands") {
      axios({
        url:
          "/getAllBrands" +
          `?page=${page}&limit=10` +
          (Boolean(searchKey)
            ? `&search=${encodeURIComponent(searchKey)}`
            : ""),
      }).then((response) => {
        if (response.status) {
          setBrandList(response.data.parsedBrands);
          loader.apiComplete();

          if (response.data.parsedBrands.length > 0) {
            setLastPage({
              totalCount: response.data.totalCount,
              lastPage: response.data.parsedBrands.length < 10,
            });
          } else {
            setLastPage({
              totalCount: response.data.totalCount,
              lastPage: true,
            });
          }
        } else {
          loader.stop();
        }
      });
    }

    if (categorize === "Rejected Campaigns") {
      axios({
        url:
          "/user/brand/getRejectedCampaigns" +
          `?page=${page}&limit=10` +
          (Boolean(searchKey)
            ? `&search=${encodeURIComponent(searchKey)}`
            : ""),
      }).then((response) => {
        if (response.status) {
          let arr = [];

          for (let key of response.data.sortedCampaigns) {
            let data = {};
            for (let fields of key.fields) {
              data[Object.keys(fields)[0]] = Object.values(fields)[0];
            }
            arr.push({
              ...data,
              brand: {
                name: key.brand.brand_name,
                logo: key.brand.brand_logo,
              },
              previewImage: !!key.preview_image
                ? +key.preview_image
                : "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_placeholder.png",
              status: key.status,
              audience_id: key.audience_id,
              brand_id: key.brand_id,
              campaign_id: key.campaign_id,
            });
          }

          setCampaigns(arr);

          if (response.data.sortedCampaigns.length > 0) {
            setLastPage({
              totalCount: response.data.totalCounts,
              lastPage: response.data.sortedCampaigns.length < 10,
            });
          } else {
            setLastPage({
              totalCount: response.data.totalCounts,
              lastPage: true,
            });
          }
          loader.stop();
        }
      });
    }
  }, [axios, brandID, loader, categorize, sortBy, searchKey, page]);

  function handlePagination(step, index) {
    setPage((prev) =>
      step === "index" ? index : prev + (step === "next" ? 1 : -1)
    );
  }

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageHeader
            icons={[
              <Categorize
                categorize={categorize}
                setCategorize={setCategorize}
                setPage={setPage}
              />,
              <SortBy
                sortBy={sortBy}
                setSortBy={setSortBy}
                categorize={categorize}
              />,
              <Search
                searchKey={searchKey}
                setPage={setPage}
                setSearchKey={setSearchKey}
              />,
            ]}
            pageHeader={"List of campaigns"}
            pageName={
              categorize === "Campaign Offers"
                ? "Campaign Offers"
                : "Active Campaigns"
            }
          />
        </Grid>
      </Grid>

      <Loader height="75%">
        {categorize === "Campaign Offers" && (
          <List
            categorize={categorize}
            campaigns={campaigns}
            component="superadmin"
          />
        )}

        {categorize === "Brands" && (
          <BrandList brandList={brandList} component="campaign" />
        )}

        {categorize === "Rejected Campaigns" && (
          <List
            categorize={categorize}
            campaigns={campaigns}
            component="superadmin"
          />
        )}

        {categorize === "Unverified Campaigns" && (
          <List
            categorize={categorize}
            campaigns={campaigns}
            component="superadmin"
          />
        )}

        {campaigns.length === 0 && (
          <EmptyList title={"No campaigns request."} />
        )}
      </Loader>

      {!!lastPage.totalCount && (
        <Grid item container display={"flex"} justifyContent={"center"}>
          <Pagination
            count={Math.ceil(lastPage.totalCount / 10)}
            variant="outlined"
            color="secondary"
            page={page}
            onChange={(e, value) => handlePagination("index", value)}
          />
        </Grid>
      )}
    </Fragment>
  );

  function getCampaignOffers() {}
}
