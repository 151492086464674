import * as Yup from "yup";

export const brandDetailsObject = {
  businessEntityId: "",
  brandName: "",
  website: "",
  audienceLocation: [],
  audienceLanguages: [],
  socialMedia: {
    facebook: "",
    instagram: "",
    twitter: "",
    whatsapp: "",
  },
  brandLogo: "",
  about: "",
  onlineStore: "",
  siteCategory: [],
  monthlyActiveUsers: "",
};

export function objectFromFormData(formData, details) {
  return {
    business_entity_id: details.businessEntityID,
    brand_name: formData.brandName,
    website: formData.website,
    audience_location: formData.audienceLocation,
    socialMedia: formData.socialMedia,
    brandLogo: formData.brandLogo,
  };
}

export function objectFromBrandResponse(response) {
  let medias = response?.social_media_handles;

  return {
    brandAvatar: response.logo,
    displayName: response.display_name,
    familyName: response.family_name,
    givenName: response.given_name,
    email: response.email,
    role: response.role,
    password: response.password,
    brandID: response?.brand_id,
    businessEntityId: response.business_entity_id,
    brandName: response.brand_name,
    website: response.website,
    audienceLocation: response?.audience_location,
    audienceLanguages: response?.languages,
    siteCategory: response.site_category,
    socialMedia: {
      facebook: medias.facebook,
      instagram: medias.instagram,
      twitter: medias.twitter,
      whatsapp: medias.whatsapp,
    },
    brandLogo: response.logo,
    about: response.about,
    onlineStore: response.online_store,
    monthlyActiveUsers:
      !response.mau || response.mau === "null" ? "" : response.mau,
    agent: response.agents || [],
  };
}

export const validationSchema = Yup.object().shape({});
