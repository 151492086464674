import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import React, { Fragment, useEffect, useState } from "react";
import { DialogContent, Grid, Drawer } from "@mui/material";

import CreativeForm from "./CreativeForm";
import { creative } from "../campaign form components/campaignObject";
import { useAxios, useComponent } from "../../../../hooks";
import {
  PrimaryButton,
  CancelButton,
  PrimaryLoadingButton,
  Reject,
  Accept,
} from "../../../css components/Button";

export default function CreativeDialog({
  editState,
  setEditState,
  detail,
  setDetail,
  formikProps,
  component,
}) {
  const axios = useAxios();
  const dispatch = useDispatch();
  const { alert } = useComponent();
  const [adSize, setAdSize] = useState([]);

  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleClose = () => {
    setDetail({ state: false, data: {}, id: "" });
  };

  function onDelete() {
    setConfirm(true);
  }

  useEffect(() => {
    axios({
      url: "/adserver/listAdTypeForChannel",
    }).then((response) => {
      if (response.status) {
        setAdSize(response.data.items);
      }
    });
  }, [axios]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDetail({ [anchor]: open, data: {}, id: "" });
  };

  return (
    <Drawer
      PaperProps={{
        sx: { width: "50%" },
      }}
      anchor={"right"}
      open={detail.state}
      onClose={toggleDrawer("right", false)}
    >
      <Formik
        enableReinitialize
        initialValues={detail.data}
        validationSchema={creativeSchema}
        validateOnChange
        onSubmit={(formData, { setSubmitting }) => {
          setSubmitLoading(true);
          let url = "/brand/campaign/create/creatives/";
          let method = "POST";
          if (!!detail.id) {
            url =
              "/brand/campaign/update/creatives?creativeId=" +
              formData.creativeID;
            method = "PUT";
          }

          axios({
            url: url,
            method: method,
            disableRedirect: true,
            data: creative(
              formData,
              detail.campaignDetails.campaignID,
              detail.data
            ),
          }).then((response) => {
            if (response.status) {
              if (!!detail.id) {
                dispatch(alert.success("Creative updated Successfully!"));

                formikProps.setValues((prev) => ({
                  ...prev,
                  creatives: setCreative(prev.creatives),
                }));

                function setCreative(creative) {
                  for (let create of creative) {
                    if (create.creativeID === formData.creativeID) {
                      create.creative = response.data.creative;
                      create.fileUrl = response.data.creative;
                      create.creativeID = response.data.creative_id;
                      create.format = response.data.format;
                      create.friendlyName = response.data.creative_name;
                      create.adSize = response.data.adtype_id;
                      create.sourceType = response.data.source_type;
                      create.adTag = response.data.ad_tag || "";
                    }
                  }
                  return creative;
                }
              } else {
                dispatch(alert.success("Creative Created Successfully!"));

                formikProps.setValues((prev) => ({
                  ...prev,
                  creatives: [
                    ...prev.creatives,
                    {
                      creativeID: response.data.creative_id,
                      fileUrl: response.data.creative,
                      creative: response.data.creative,
                      format: response.data.format,
                      friendlyName: response.data.creative_name,
                      adSize: response.data.adtype_id,
                      sourceType: response.data.source_type,
                      adTag: response.data.ad_tag || "",
                    },
                  ],
                }));
              }

              handleClose();
              setSubmitLoading(false);
            } else {
              setSubmitLoading(false);
            }
          });
          setSubmitting(false);
        }}
      >
        {(dialogFormikProps) => (
          <DialogContent>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <CreativeForm
                  formikProps={formikProps}
                  dialogFormikProps={dialogFormikProps}
                  editState={editState}
                  setEditState={setEditState}
                  handleClose={handleClose}
                  component={component}
                  adSize={adSize}
                  confirm={confirm}
                  setConfirm={setConfirm}
                />
              </Grid>

              {component !== "superadmin" && component !== "request" && (
                <Fragment>
                  {dialogFormikProps.values.creativeID && (
                    <Grid item textAlign="end">
                      <Reject
                        loading={loading}
                        variant="outlined"
                        onClick={() => onDelete(dialogFormikProps)}
                      >
                        Delete
                      </Reject>
                    </Grid>
                  )}

                  <Grid item xs />
                  {editState ? (
                    <Grid item>
                      <PrimaryLoadingButton
                        variant="contained"
                        type="submit"
                        disabled={!dialogFormikProps.isValid}
                        loading={submitLoading}
                        onClick={dialogFormikProps.handleSubmit}
                      >
                        Save
                      </PrimaryLoadingButton>
                    </Grid>
                  ) : (
                    <Grid item>
                      <Accept
                        variant="contained"
                        onClick={() => setEditState(!editState)}
                      >
                        Edit Creative
                      </Accept>
                    </Grid>
                  )}
                  <Grid item>
                    <CancelButton variant="outlined" onClick={handleClose}>
                      Cancel
                    </CancelButton>
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </DialogContent>
        )}
      </Formik>
    </Drawer>
  );

  // function onDelete(dialogFormikProps) {
  //   setLoading(true);
  //   axios({
  //     url:
  //       "/user/brand/campaign/creatives?creativeId=" +
  //       dialogFormikProps.values.creativeID,
  //     method: "DELETE",
  //     disableRedirect: true,
  //   }).then((response) => {
  //     if (response.status) {
  //       setLoading(false);

  //       dispatch(alert.success("Creative deleted successfully."));
  //       formikProps.setValues((prev) => ({
  //         ...prev,
  //         creatives: setCreatives(prev.creatives),
  //       }));

  //       function setCreatives(creatives) {
  //         let arr = creatives.filter(
  //           (creative) =>
  //             creative.creativeID !== dialogFormikProps.values.creativeID
  //         );
  //         return arr;
  //       }
  //       handleClose();
  //     }
  //   });
  // }
}

const creativeSchema = Yup.object().shape({
  format: Yup.string().required("Please select format"),
  friendlyName: Yup.string().required("Creative name is required"),
  adSize: Yup.string().when("format", {
    is: (val) => val !== "ad-tag",
    then: () => Yup.string().required("Fill the field"),
  }),
  adTag: Yup.string().when("format", {
    is: (val) => val === "ad-tag",
    then: () => Yup.string().required("Fill the field"),
  }),
});
