import React from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { Button as MuiButton, Typography } from "@mui/material";
import { spacing } from "@mui/system";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  height: 100%;
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function Page404() {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Helmet title="404 Error" />

      <Typography component="h1" variant="h1" align="center" gutterBottom>
        404
      </Typography>

      <Typography component="h2" variant="h5" align="center" gutterBottom>
        Page not found.
      </Typography>

      <Typography component="h2" variant="body1" align="center" gutterBottom>
        The page you are looking for might have been removed.
      </Typography>

      <Button
        onClick={() => navigate(-1)}
        variant="contained"
        color="secondary"
        mt={2}
      >
        Back
      </Button>
    </Wrapper>
  );
}

export default Page404;
