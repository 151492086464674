import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import renderUI from "./helper functions/api";

export const renderUserInterface = createAsyncThunk(
  "member/renderUI",
  renderUI
);

const alertInitialState = {
  siteLogo: "",
  siteCopyright: "",
  siteColors: {
    primary: "",
    secondary: "",
  },
  emailLogo: "",
  emailPlatform: "",
};

export const renderUISlice = createSlice({
  name: "UI",
  initialState: alertInitialState,
  reducers: {},

  extraReducers: {
    [renderUserInterface.fulfilled]: (_, { payload }) => {
      return payload;
    },
  },
});

export default renderUISlice.reducer;
