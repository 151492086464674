import { Formik } from "formik";

import FormDisplay from "./FormDisplay";
import { useAxios } from "../../../hooks";
import { objectFromFormData, validationSchema } from "./agencyObject";
import { useDispatch, useSelector } from "react-redux";
import { alert } from "../../../redux/slices/alertSlice";
import { useNavigate } from "react-router-dom";
import pages from "../../../constants/pages";

export default function Form({ formData, editState, setEditState }) {
  const axios = useAxios();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const businessEntityID =
    useSelector((state) => state.memberDetails?.businessEntityID) ||
    sessionStorage.getItem("businessEntityId");

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validateOnMount
      validationSchema={validationSchema}
      onSubmit={(formData, { setSubmitting }) => {
        let url = "/user/agency";
        let method = "POST";
        if (formData.agencyID) {
          url = "/user/agency/" + formData.agencyID;
          method = "PUT";
        }
        axios({
          url: url,
          method: method,
          data: objectFromFormData(formData, businessEntityID),
          disableRedirect: true,
        }).then((response) => {
          if (response.status) {
            if (!formData.agencyID) {
              sessionStorage.setItem("agencyID", response.data?.agency_id);
              sessionStorage.setItem("role", "AGENCY");
              navigate(pages.welcomeAsAgencyPage.route);
            }

            if (!!formData.agencyID) {
              setEditState(false);
            }
            dispatch(
              alert({
                type: "success",
                message: response.message.displayMessage,
              })
            );
          }
          setSubmitting(false);
        });
      }}
    >
      {(formikProps) => (
        <FormDisplay
          editState={editState}
          formikProps={formikProps}
          setEditState={setEditState}
        />
      )}
    </Formik>
  );
}
