import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Loader } from "../../components";
import Form from "./user details components/Form";
import {
  userDetailsObject,
  objectFromResponse,
} from "./user details components/userObjects";
import { useAxios, useComponent } from "../../hooks";

export default function UserDetails() {
  const axios = useAxios();
  const params = useParams();
  const { loader } = useComponent();

  const userID = params.userID || "";

  const [brands, setBrands] = useState([]);
  const [editState, setEditState] = useState(true);
  const [formData, setFormData] = useState(userDetailsObject);

  useEffect(() => {
    loader.start(2);

    axios({
      url: "/getAllBrands",
    }).then((res) => {
      if (res.status) {
        setBrands(res.data.parsedBrands);
        loader.apiComplete();
      }
    });

    if (!!userID) {
      setEditState(false);

      axios({
        url: "/user/info?userId=" + userID,
      }).then((response) => {
        if (response.status) {
          setFormData(objectFromResponse(response));
          loader.apiComplete();
        }
      });
    } else {
      loader.stop();
    }
  }, [loader, axios, userID]);

  return (
    <Loader>
      <Form
        brands={brands}
        formData={formData}
        editState={editState}
        setEditState={setEditState}
      />
    </Loader>
  );
}
