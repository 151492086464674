import * as Yup from "yup";
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Grid,
  MenuItem,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Link,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { useAxios } from "../../../hooks";
import { colors } from "../../css components/Style";
import { alert } from "../../../redux/slices/alertSlice";
import { FileUpload, TextField } from "../../../components";
import { PrimaryLoadingButton } from "../../css components/Button";

export default function DynamicForm({
  editState,
  setEditState,
  channels,
  formikProps,
}) {
  const axios = useAxios();
  const dispatch = useDispatch();
  let baseSchema = Yup.object().shape({});

  const [expanded, setExpanded] = useState(0);
  const [loading, setLoading] = useState(false);

  function handleExpanded(key) {
    for (let [index, fields] of channels?.entries()) {
      if (index === key) {
      }
    }

    setExpanded(key);
  }

  return (
    <Fragment>
      {channels.map(
        (channel, index) =>
          channel?.fields?.length > 0 && (
            <Grid item xs={12} key={index}>
              <Accordion
                onChange={() => handleExpanded(index)}
                expanded={expanded === index}
              >
                <AccordionSummary
                  expandIcon={
                    <KeyboardArrowDownIcon
                      style={{
                        color: colors.purple,
                      }}
                    />
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography variant="h4" color={colors.purple}>
                    {channel.channel_name}
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Grid container spacing={2}>
                    {channel.fields.map((field) => (
                      <Fragment>
                        <Grid item xs={6}>
                          {field.control_id === 1 && (
                            <TextField
                              disabled={!editState}
                              label={field.label}
                              placeholder={field.placeholder}
                              formikProps={formikProps}
                              name={
                                "channels[" +
                                index +
                                "]." +
                                channel.key_name +
                                "." +
                                field.field_name
                              }
                              select
                            >
                              {field.options.map((option) => (
                                <MenuItem value={option.key}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        </Grid>

                        {field?.nesting &&
                          !!formikProps.values.channels[index][
                            channel.key_name
                          ] &&
                          !!field[
                            formikProps.values.channels[index][
                              channel.key_name
                            ][field.field_name]
                          ] &&
                          field[
                            formikProps.values.channels[index][
                              channel.key_name
                            ][field.field_name]
                          ]?.map((val) => (
                            <Fragment>
                              {val.control_id === 3 && (
                                <Grid item xs={6}>
                                  <TextField
                                    disabled={!editState}
                                    label={val.label}
                                    placeholder={val.placeholder}
                                    formikProps={formikProps}
                                    name={
                                      "channels[" +
                                      index +
                                      "]." +
                                      channel.key_name +
                                      "." +
                                      val.name
                                    }
                                  />
                                </Grid>
                              )}

                              {val.control_id === 1 && (
                                <Fragment>
                                  {val?.ranges.map((range) => (
                                    <Grid item xs={3}>
                                      <TextField
                                        disabled={!editState}
                                        label={range.label}
                                        placeholder={
                                          val.placeholder + " " + range.label
                                        }
                                        formikProps={formikProps}
                                        name={
                                          "channels[" +
                                          index +
                                          "]." +
                                          channel.key_name +
                                          "." +
                                          range.name
                                        }
                                      />
                                    </Grid>
                                  ))}
                                </Fragment>
                              )}

                              {val.control_id === 5 && (
                                <Grid item xs={6}>
                                  <FileUpload
                                    disabled={!editState}
                                    folderPath={val.upload_folder_path + "/"}
                                    allowedExtension={val.extension || "csv"}
                                    formikProps={formikProps}
                                    value={formikProps.values.channels[index][
                                      channel.key_name
                                    ][val.name]
                                      ?.split("/")
                                      .pop()}
                                    name={
                                      "channels[" +
                                      index +
                                      "]." +
                                      channel.key_name +
                                      "." +
                                      val.name
                                    }
                                    downloadFile={true}
                                    link={
                                      formikProps.values.channels[index][
                                        channel.key_name
                                      ][val.name]
                                    }
                                    placeholder={val.placeholder}
                                  />
                                  {val.sample_file_path && (
                                    <Link
                                      onClick={() => {
                                        window.location.href =
                                          val.sample_file_path;
                                      }}
                                      style={{
                                        paddingTop: "10px",
                                        cursor: "pointer",
                                      }}
                                      variant="subtitle2"
                                      color={colors.purple}
                                    >
                                      Download Sample File
                                    </Link>
                                  )}
                                </Grid>
                              )}
                            </Fragment>
                          ))}
                      </Fragment>
                    ))}
                  </Grid>
                </AccordionDetails>

                <AccordionActions>
                  <Grid item xs />
                  <Grid item>
                    {editState && (
                      <PrimaryLoadingButton
                        loading={loading}
                        onClick={(e) => saveStatsData(e, channel, index)}
                        variant="contained"
                      >
                        Save
                      </PrimaryLoadingButton>
                    )}
                  </Grid>
                </AccordionActions>
              </Accordion>
            </Grid>
          )
      )}
    </Fragment>
  );

  function saveStatsData(e, channel, index) {
    setLoading(true);
    var formData = {
      campaign_id: formikProps.values.campaignID,
      channels: [],
    };

    formData.channels.push(formikProps.values.channels[index]);
    formData.channels[0]["channel_id"] = channel.id;
    formData.channels[0][channel.key_name]["file_type"] = "csv";

    axios({
      url: "/save/channelDetails",
      method: "POST",
      data: formData,
    }).then(function (response) {
      if (response.status) {
        dispatch(
          alert({
            type: "success",
            message: "Channel details saved successfully",
          })
        );
        setLoading(false);
      }
    });
  }
}
