import axios from "axios";

import BASE_URL from "../../../constants/baseURL";

export default async function renderUI() {
  const axiosInstance = axios.create({
    baseURL: BASE_URL.adn_api.url + BASE_URL.adn_api.version,
    headers: {
      "Content-Type": "application/json",
    },
  });

  const UIDetails = await axiosInstance({
    url: "/get/dynamicDashboard",
    method: "GET",
  }).then((response) => response.data.data);

  return {
    siteLogo: UIDetails.site_logo,
    siteCopyright: UIDetails.site_copyright,
    siteColors: {
      primary: UIDetails.site_colors.primary,
      secondary: UIDetails.site_colors.secondary,
    },
    emailLogo: UIDetails.email_logo,
    emailPlatform: UIDetails.email_platform,
  };
}
