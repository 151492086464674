import { Checkbox, MenuItem } from "@mui/material";

import { TextField } from "../../../../components";
import React from "react";

const MultiDropdown = React.memo(({ field, state, dispatch, editState }) => {
  let priority = ["deterministic", "probabilistic", "recency", "accuracy"];

  function renderingValue(value) {
    var arr = [];

    if (value.length > 0) {
      value.map((item) => arr.push(priority.find((x) => x === item)));
    }

    return arr;
  }

  const handleFieldChange = (value, name, key, index, num) => {
    dispatch({
      type: "MULTI_DROP_DOWN",
      payload: { value, name, key },
    });
  };

  return (
    <TextField
      size="small"
      name={field.field_name}
      label={"Select priority"}
      disabled={!editState}
      select
      SelectProps={{
        renderValue: (selected) => renderingValue(selected).join(","),
        multiple: true,
        value: state?.[field.field_name]?.priority || [],
        onChange: (e) =>
          handleFieldChange(e.target.value, field.field_name, "priority"),
      }}
    >
      {priority.map((prio) => (
        <MenuItem
          disabled={
            (prio === "deterministic" &&
              state?.[field.field_name]?.priority?.indexOf("probabilistic") >
                -1) ||
            (prio === "probabilistic" &&
              state?.[field.field_name]?.priority?.indexOf("deterministic") >
                -1)
          }
          value={prio}
        >
          <Checkbox
            checked={state?.[field.field_name]?.priority?.indexOf(prio) > -1}
          />
          {prio}
        </MenuItem>
      ))}
    </TextField>
  );
});

export default MultiDropdown;
