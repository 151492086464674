import { CgEye } from "react-icons/cg";
import { MdEdit } from "react-icons/md";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { Grid, IconButton, Tooltip } from "@mui/material";

import { Chip, Paper, TextAvatar } from "../../../components";
import {
  HeadingEighteen,
  TextFourteenBlack,
  colors,
} from "../../css components/Style";
import pages from "../../../constants/pages";
import { titleCase } from "change-case-all";

const Card = styled(Grid)`
  display: flex;
  gap: 20px;
`;

const OuterCard = styled(Paper)`
  display: flex;
  gap: 20px;
  margin: 8px 0;
  padding: 10px;
`;

const Icon = styled(IconButton)`
  border: 1px solid ${colors.purple};
  color: ${colors.purple};
  height: 35px;
  width: 35px;
  margin: 0 5px;
  padding: 0 5px;
  &:hover {
    border: 1px solid ${colors.purple};
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
`;

export default function List({ templates }) {
  const navigate = useNavigate();

  return (
    <Grid container spacing={0}>
      {templates?.temp_obj.map((template, index) => (
        <Grid item xs={12} key={index}>
          <OuterCard elevation={1}>
            <Grid item alignSelf="center" paddingRight={"20px"}>
              <TextAvatar
                size={{ height: "40px", width: "40px" }}
                userName={template?.name}
              />
            </Grid>

            <Grid item xs>
              <Card container spacing={2}>
                <Grid item xs={2}>
                  <TextFourteenBlack>Template Name</TextFourteenBlack>

                  <HeadingEighteen>{template.name}</HeadingEighteen>
                </Grid>

                <Grid item xs={2}>
                  <TextFourteenBlack>Template Category</TextFourteenBlack>

                  <HeadingEighteen>{template.name}</HeadingEighteen>
                </Grid>

                <Grid item xs={2}>
                  <TextFourteenBlack>Template status</TextFourteenBlack>

                  <HeadingEighteen>
                    <Chip
                      label={
                        template.verified_status_id ===
                        "8360b5eca314424a9d56c37004ccecc9"
                          ? "Approved"
                          : template.verified_status_id ===
                            "531c1247d1ca4329b4eef4596a099b52"
                          ? "Pending"
                          : "Rejected"
                      }
                      style={{
                        background:
                          template.verified_status_id ===
                          "8360b5eca314424a9d56c37004ccecc9"
                            ? colors.green
                            : template.verified_status_id ===
                              "531c1247d1ca4329b4eef4596a099b52"
                            ? colors.yellow
                            : colors.error,
                        color: "#fff",
                      }}
                    />
                  </HeadingEighteen>
                </Grid>

                <Grid item xs={2}>
                  <TextFourteenBlack>Template Header Type</TextFourteenBlack>

                  <HeadingEighteen>
                    {template?.template_header?.type
                      ? titleCase(template?.template_header?.type)
                      : "N.A."}
                  </HeadingEighteen>
                </Grid>

                <Grid item xs={3}>
                  <TextFourteenBlack>Template Body</TextFourteenBlack>

                  <HeadingEighteen>
                    {template.template_body?.text.slice(0, 20) +
                      (template.template_body?.text.length > 20 ? "..." : ".")}
                  </HeadingEighteen>
                </Grid>
              </Card>
            </Grid>

            {/* <Grid
              item
              alignSelf={"center"}
              display={"flex"}
              justifyContent={"flex-end"}
              style={{ width: !!template.isEditable && "20px" }}
            >
              <Tooltip title="Edit Template">
                <Icon
                  onClick={() =>
                    navigate(
                      pages.templateDetails.route + template.id + "?mode=edit"
                    )
                  }
                >
                  <MdEdit style={{ height: "20px", width: "20px" }} />
                </Icon>
              </Tooltip>
            </Grid> */}

            <Grid
              item
              alignSelf={"center"}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <Tooltip title="View Template">
                <Icon
                  onClick={() =>
                    navigate(
                      pages.templateDetails.route + template.id + "?mode=view"
                    )
                  }
                >
                  <CgEye style={{ height: "20px", width: "20px" }} />
                </Icon>
              </Tooltip>
            </Grid>
          </OuterCard>
        </Grid>
      ))}
    </Grid>
  );
}
