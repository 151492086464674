import React from "react";
import { useParams } from "react-router-dom";

import Form from "./Form";

export default function Invitation() {
  const params = useParams();

  const code = params.code || "";

  return <Form code={code} />;
}
