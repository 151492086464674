import { get } from "lodash";
import { Autocomplete, Checkbox } from "@mui/material";

import TextField from "./TextField";

export default function AutocompleteMultiSelect({
  label,
  name,
  defaults,
  options,
  formikProps,
  placeholder,
  required = false,
  ...props
}) {
  var initialValue = [];

  for (let val of get(formikProps.values, name, [])) {
    if (options.some((el) => el[defaults.primaryKey] === val)) {
      initialValue.push(
        options.filter((option) => option[defaults.primaryKey] === val)[0][
          defaults.displayLabel
        ]
      );
    } else {
      initialValue.push("");
    }
  }

  if (!initialValue) {
    initialValue = [];
  }

  function handleTouch() {
    if (formikProps?.touched.length > 0) {
      for (let field in formikProps.touched) {
        if (field !== props.name) {
          formikProps.setTouched({
            ...formikProps.touched,
            [props.name]: true,
          });
        } else {
          formikProps.setTouched({ [props.name]: true });
        }
      }
    } else {
      formikProps.setTouched({ [props.name]: true });
    }
  }

  return (
    <Autocomplete
      size="small"
      multiple
      onClick={handleTouch}
      fullWidth
      disableClearable
      value={initialValue}
      options={options}
      forcePopupIcon={true}
      disableCloseOnSelect
      isOptionEqualToValue={(option, value) =>
        option[defaults.displayLabel] === value
      }
      getOptionLabel={(option) => get(option, defaults.displayLabel)}
      renderTags={(value) =>
        value.map((val) => (value.length === 1 ? val : val)).join(", ")
      }
      renderOption={(props, option, { selected }) => (
        <li {...props} style={{ fontSize: "18px", cursor: "pointer" }}>
          <Checkbox
            checked={
              get(formikProps.values, name, "").indexOf(
                option[defaults.primaryKey]
              ) > -1
            }
          />
          {option[defaults.displayLabel]}
        </li>
      )}
      {...props}
      renderInput={(params) => (
        <TextField
          required={required}
          multiline
          name={name}
          label={label}
          {...params}
          placeholder={
            get(formikProps.values, name, []).length === 0 ? placeholder : ""
          }
          error={Boolean(
            get(formikProps, "touched." + name) &&
              get(formikProps, "errors." + name)
          )}
          helperText={
            get(formikProps, "touched." + name) &&
            get(formikProps, "errors." + name)
          }
          onBlur={get(formikProps, "handleBlur")}
        />
      )}
    />
  );
}
