import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { Typography, Grid, Button } from "@mui/material";

import pages from "../../../constants/pages";
import { TextField } from "../../../components";
import { useAxios, useComponent } from "../../../hooks";

const MainHeading = styled(Typography)`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 42px;
  color: #000000;
`;

const SubHeading = styled(Typography)`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 30px;
  color: #000000;
`;

const CancelButton = styled(Button)`
  border: 1px solid #bdbdbd;
  border-radius: 30px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #bdbdbd;
  padding: 10px 16px;
  margin-right: 10px;
  height: 50px;
  width: 180px;
  &:hover {
    border: 1px solid #bdbdbd;
  }
`;

const PrimaryButton = styled(LoadingButton)`
  border-radius: 30px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  padding: 10px 16px;
  height: 50px;
  width: 180px;
`;

export default function FormDisplay({ handleClose, reject, setReject }) {
  const axios = useAxios();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { alert } = useComponent();

  const [loading, setLoading] = useState(false);

  function handleAddText(text) {
    setReject((prev) => ({
      ...prev,
      rejected_reason: prev.rejected_reason + text,
    }));
  }

  return (
    <React.Fragment>
      <Grid
        item
        xs={12}
        style={{ borderColor: "#8e8e8e", borderBottom: "1px solid #333" }}
      />

      <Grid item xs={12}>
        <MainHeading>Rejecting the offer!</MainHeading>
      </Grid>

      <Grid
        item
        xs={12}
        style={{ borderColor: "#8e8e8e", borderBottom: "1px solid #333" }}
      />

      <Grid item xs={12}>
        <SubHeading>Send a Message to Monetization Partner</SubHeading>
      </Grid>

      <Grid item xs={12}>
        <TextField
          rows={5}
          multiline
          name="rejected_reason"
          onChange={handleChange}
        />
      </Grid>

      <Grid item>
        <Button
          style={{
            padding: "8px 30px",
            fontWeight: "bold",
            color: "#BDBDBD",
            borderColor: "#BDBDBD",
          }}
          variant="outlined"
          onClick={() => handleAddText(" Contact Us in")}
        >
          Contact Us in
        </Button>
      </Grid>

      <Grid item>
        <Button
          style={{
            padding: "8px 30px",
            fontWeight: "bold",
            color: "#BDBDBD",
            borderColor: "#BDBDBD",
          }}
          variant="outlined"
          onClick={() => handleAddText(" Tell us more")}
        >
          Tell us more
        </Button>
      </Grid>

      <Grid item>
        <Button
          style={{
            padding: "8px 30px",
            fontWeight: "bold",
            color: "#BDBDBD",
            borderColor: "#BDBDBD",
          }}
          variant="outlined"
          onClick={() => handleAddText(" Let's Talk")}
        >
          Let's Talk
        </Button>
      </Grid>

      <Grid item xs={12} />

      <Grid item xs />

      <Grid item>
        <CancelButton variant="outlined" onClick={handleClose}>
          Cancel
        </CancelButton>
      </Grid>

      <Grid item>
        <PrimaryButton
          type="submit"
          variant="contained"
          onClick={handleTakeUpOffer}
          loading={loading}
        >
          Reject
        </PrimaryButton>
      </Grid>
    </React.Fragment>
  );

  function handleChange(e) {
    setReject((prev) => ({
      ...prev,
      rejected_reason: e.target.value,
    }));
  }

  function handleTakeUpOffer() {
    setLoading(true);
    axios({
      url: "/user/rejectConnectionRequest",
      method: "POST",
      data: reject.data,
    }).then((res) => {
      if (res.success) {
        setLoading(false);
        dispatch(alert.success("Offer rejected!"));
        navigate(pages.campaignAccepted.route);
      }
      handleClose();
    });
  }
}
