import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Visibility } from "@mui/icons-material";
import { Grid, Typography, InputAdornment, IconButton } from "@mui/material";

import { TextField } from "../../../components";
import Logo from "../../../assets/auth carousel pics/verismart-background.png";
import { PrimaryLoadingButton } from "../../css components/Button";

export default function FormDisplay({ formikProps }) {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} textAlign={"center"}>
        <img style={{ height: "50px" }} src={Logo} alt="logo" />
        <Typography className="font">
          Your new password must be different from previously used passwords
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          type={showPassword ? "text" : "password"}
          name="newPassword"
          label="New password"
          placeholder="New Password"
          formikProps={formikProps}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <Visibility />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          type={confirmPassword ? "text" : "password"}
          name="confirmPassword"
          label="Confirm Password"
          placeholder="Confirm Password"
          formikProps={formikProps}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => setConfirmPassword(!confirmPassword)}
                >
                  <Visibility />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <PrimaryLoadingButton
          fullWidth
          type="submit"
          variant="contained"
          onClick={formikProps.handleSubmit}
          disabled={!formikProps.isValid}
          loading={formikProps.isSubmitting}
        >
          Reset
        </PrimaryLoadingButton>
      </Grid>

      <Grid item xs={12} alignSelf="center" textAlign={"center"}>
        <Typography className="font" variant="caption1">
          Go back to?{" "}
          <Typography
            variant="button"
            className="purple font"
            style={{ cursor: "pointer", fontWeight: 500 }}
            onClick={() => navigate("/sign-in")}
          >
            Sign In!
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
}
