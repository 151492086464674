import { Formik } from "formik";
import { Fragment, useState } from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Dialog, DialogContent, Grid } from "@mui/material";

import {
  CancelButton,
  PrimaryLoadingButton,
} from "./../../css components/Button";
import { useAxios, useComponent } from "../../../hooks";
import { FileUpload, Paper } from "../../../components";
import { HeadingEighteen } from "../../css components/Style";
import * as Yup from "yup";

const Card = styled(Dialog)`
  min-width: 1360px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export default function UploadCSV({
  dialogUploadCSV,
  setDialogUploadCSV,
  formikProps,
}) {
  const axios = useAxios();

  const dispatch = useDispatch();

  const { alert } = useComponent();

  const { userID, activeBrand } = useSelector((state) => state.memberDetails);

  const [formData, setFormData] = useState({
    file_url: "",
  });

  return (
    <Card open={dialogUploadCSV}>
      <DialogContent>
        <Paper>
          <Formik
            enableReinitialize
            initialValues={formData}
            validationSchema={validationSchema}
            onSubmit={(formData) => {
              axios({
                url: "/user/upload/pid",
                method: "POST",
                data: {
                  sender_brandId: formikProps.values.brandID,
                  sender_campaignId: formikProps.values.campaignID,
                  receiver_userId: userID,
                  receiver_brandId: activeBrand.ID,
                  file: formData.file_url,
                },
              }).then((resp) => {
                if (resp.status) {
                  setFormData({
                    file_url: "",
                  });
                  setDialogUploadCSV(false);
                  dispatch(alert.success(resp.data.message));
                }
              });
            }}
          >
            {(dialogFormikProps) => {
              return (
                <Fragment>
                  <Grid item>
                    <HeadingEighteen
                      style={{
                        fontWeight: "600",
                        fontSize: "20px",
                        lineHeight: "33px",
                      }}
                    >
                      Upload CSV
                    </HeadingEighteen>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    display={"flex"}
                    justifyContent={"end"}
                    mb={2}
                    mt={4}
                  >
                    <PrimaryLoadingButton
                      href="https://dc-chat-media.s3.ap-south-1.amazonaws.com/undefinedsample.csv"
                      variant="outlined"
                    >
                      <UploadFileIcon sx={{ mr: 2 }} /> Sample File
                    </PrimaryLoadingButton>

                    <PrimaryLoadingButton
                      variant="contained"
                      sx={{ ml: 3 }}
                      disabled={
                        !dialogFormikProps.isValid || !dialogFormikProps.dirty
                      }
                      onClick={dialogFormikProps.handleSubmit}
                    >
                      Upload CSV
                    </PrimaryLoadingButton>
                  </Grid>

                  <Grid item xs={12} m={"10px 0px"}>
                    <FileUpload
                      label="Upload File"
                      name="file_url"
                      formikProps={dialogFormikProps}
                      allowedExtension={".xlsx ,.csv"}
                      maxSize={16}
                    ></FileUpload>
                  </Grid>

                  <Grid item xs={12} display={"flex"} justifyContent={"end"}>
                    <CancelButton
                      variant="outlined"
                      style={{ marginRight: 0 }}
                      onClick={() => setDialogUploadCSV(false)}
                    >
                      Cancel
                    </CancelButton>
                  </Grid>
                </Fragment>
              );
            }}
          </Formik>
        </Paper>
      </DialogContent>
    </Card>
  );
}

const validationSchema = Yup.object().shape({
  file_url: Yup.string().required("File is required"),
});
