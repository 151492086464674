import { Fab } from "@mui/material";
import { Clear, Edit } from "@mui/icons-material";

import { colors } from "../pages/css components/Style";

export default function FloatingEditActionButtons({
  ID = "",
  editState,
  setEditState,
  formikProps,
}) {
  function handleCancel() {
    if (editState && !!ID) {
      setEditState(!editState);
      formikProps.handleReset();
    } else {
      setEditState(!editState);
    }
  }

  return (
    <Fab
      style={{
        position: "absolute",
        right: "4%",
        bottom: "4%",
        background: editState ? colors.error : colors.green,
        color: "#fff",
      }}
      onClick={handleCancel}
    >
      {editState ? <Clear /> : <Edit />}
    </Fab>
  );
}
