import { Fragment, useState } from "react";
import { Checkbox, Grid, Typography } from "@mui/material";
import { colors } from "../../css components/Style";
import { ComponentHeader } from "../../../components";
import { PrimaryLoadingButton } from "../../css components/Button";
import { useAxios } from "../../../hooks";
import { useDispatch } from "react-redux";
import { alert } from "../../../redux/slices/alertSlice";

export default function PrefrredChannels({ channels, formData }) {
  const axios = useAxios();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(
    formData.prefrredChannel
  );

  function handleSubmitChannel() {
    setLoading(true);
    axios({
      url: "/brand/campaign/selectedChannels",
      method: "POST",
      disableRedirect: true,
      data: {
        brand_id: formData.brandID,
        channel_ids: selectedChannel,
      },
    }).then((response) => {
      if (response.status) {
        dispatch(
          alert({
            type: "success",
            message: "Channels saved successfully",
          })
        );
        setLoading(false);
      }
    });
  }

  return (
    <Fragment>
      <Grid item xs={12}>
        <ComponentHeader
          title={"Select Preferred Channel"}
          subtitle={"Please choose channels "}
          buttons={[
            <PrimaryLoadingButton
              onClick={handleSubmitChannel}
              loading={loading}
              variant="contained"
            >
              Save
            </PrimaryLoadingButton>,
          ]}
        />
      </Grid>

      {channels.map(
        (channel, index) =>
          channel.is_active && (
            <Grid item xs={6}>
              <Grid container spacing={4}>
                <Grid item xs={7}>
                  <Typography variant="h5">{channel.channel_name}</Typography>
                </Grid>

                <Grid item xs={5}>
                  <Checkbox
                    onChange={(e) => {
                      handleChangeChannel(e, channel);
                    }}
                    checked={selectedChannel?.includes(channel.id)}
                    sx={{
                      padding: 0,
                      color: colors.purple,
                      "&.Mui-checked": {
                        color: colors.purple,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )
      )}
    </Fragment>
  );

  function handleChangeChannel(e, channel) {
    if (e.target.checked) {
      setSelectedChannel([...selectedChannel, channel.id]);
    } else {
      let channels = [...selectedChannel];
      const index = channels.indexOf(channel.id);
      if (index > -1) {
        channels.splice(index, 1);
      }
      setSelectedChannel(channels);
    }
  }
}
