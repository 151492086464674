import React from "react";
import { Formik } from "formik";
import {
  Dialog,
  DialogTitle,
  Grid,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import * as Yup from "yup";

import PhoneNumberForm from "./PhoneNumberForm";
import { useAxios, useComponent } from "../../../hooks";
import { CancelButton, PrimaryButton } from "../../css components/Button";

export default function TestTemplate({ popUp, setPopUp, agentID, templateID }) {
  const axios = useAxios({ baseURL: "cpass_api" });
  const { alert } = useComponent();

  var templatePopUp = {
    agent_id: agentID,
    mobile_number: "",
    template_id: templateID,
  };

  return (
    <Dialog open={popUp} onClose={handleClose} fullWidth>
      <DialogTitle style={{ borderBottom: "1px solid #cbcbcb" }}>
        Test Template
      </DialogTitle>

      <Formik
        initialValues={templatePopUp}
        enableReinitialize
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={(formData, { setSubmitting }) => {
          axios({
            url: "/template/test",
            method: "POST",
            data: formData,
          }).then((response) => {
            if (response.status) {
              alert.success(response.message?.displayMessage);
              handleClose();
            }
          });
        }}
      >
        {(dialogFormikProps) => (
          <form noValidate onSubmit={dialogFormikProps.handleSubmit}>
            <DialogContent>
              <PhoneNumberForm dialogFormikProps={dialogFormikProps} />
            </DialogContent>
            <DialogActions style={{ padding: "12px 24px 24px" }}>
              <Grid container spacing={2}>
                <Grid item>
                  <CancelButton
                    variant="outlined"
                    color="primary"
                    onClick={handleClose}
                  >
                    Cancel
                  </CancelButton>
                </Grid>
                <Grid item xs></Grid>

                <Grid item xs style={{ textAlign: "end" }}>
                  <PrimaryButton
                    onClick={dialogFormikProps.handleSubmit}
                    variant="contained"
                    color="primary"
                    disabled={
                      dialogFormikProps.dirty
                        ? dialogFormikProps.isSubmitting ||
                          !dialogFormikProps.isValid
                        : !dialogFormikProps.dirty
                    }
                  >
                    Test
                  </PrimaryButton>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );

  function handleClose() {
    setPopUp(false);
  }
}
const validationSchema = Yup.object().shape({
  mobile_number: Yup.string().required("Phone Number is required."),
});
