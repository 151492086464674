import { Fragment } from "react";
import { Verified } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import { Grid, InputAdornment, Tooltip } from "@mui/material";

import { TextField } from "../../../components";
import { colors } from "../../css components/Style";

export default function FormOne({ formData }) {
  return (
    <Fragment>
      <Grid item xs={6}>
        <TextField
          size="small"
          label="Company Name"
          name="companyName"
          placeholder="Company Name"
          value={formData.companyName}
          disabled
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          size="small"
          label="Company Contact"
          name="companyContact"
          placeholder="Company Contact"
          value={formData.companyContact}
          disabled
          InputProps={{
            endAdornment: (
              <InputAdornment position={"end"}>
                {formData.verifyNumber ? (
                  <Tooltip title="Company Contact Number Is Verified">
                    <Verified style={{ color: colors.green }} />
                  </Tooltip>
                ) : (
                  <Tooltip title="Company Contact Number Is Not Verified">
                    <CancelIcon style={{ color: colors.error }} />
                  </Tooltip>
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          size="small"
          label="Business Nature"
          name="companyNatureBusiness"
          placeholder="Business Nature"
          value={formData.companyNatureBusiness}
          disabled
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          size="small"
          label="Company Director's Name"
          name="companyDirectorsName"
          placeholder="Company Director's Name"
          value={formData.companyDirectorsName}
          disabled
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          size="small"
          label="Company GST Number"
          name="companyGSTNumber"
          placeholder="Company GST Number"
          value={formData.companyGSTNumber}
          disabled
          InputProps={{
            endAdornment: (
              <InputAdornment position={"end"}>
                {formData.gstVerified ? (
                  <Tooltip title="GST Number Is Verified">
                    <Verified style={{ color: colors.green }} />
                  </Tooltip>
                ) : (
                  <Tooltip title="GST Number Is Not Verified">
                    <CancelIcon style={{ color: colors.error }} />
                  </Tooltip>
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Fragment>
  );
}
