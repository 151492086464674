import React from "react";
import styled from "styled-components/macro";
import { Avatar, Grid } from "@mui/material";

import { ComponentHeader, TextField } from "../../../components";

const ImageAvatar = styled(Avatar)`
  height: 130px;
  width: 130px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`;

export default function FormOne({ formData }) {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <ComponentHeader title={"Agency Details"} subtitle={""} />
      </Grid>

      <Grid item textAlign="center" display="flex">
        <ImageAvatar src={formData.avatar} />
      </Grid>

      <Grid item xs={12}></Grid>

      <Grid item xs={4}>
        <TextField
          size="small"
          value={formData.agencyName}
          label="Agency Name"
          disabled
          placeholder="Agency Name"
          name="agencyName"
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          size="small"
          label="Website"
          disabled
          value={formData.website}
          name="website"
          placeholder="Website"
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          size="small"
          value={formData.about}
          label="About"
          disabled
          placeholder="About"
          name="about"
          multiline
        />
      </Grid>
    </React.Fragment>
  );
}
