export const agencyObject = {
  agencyID: "",
  businessEntityId: "",
  agencyName: "",
  website: "",
  socialMedia: {
    facebook: "",
    instagram: "",
    twitter: "",
    whatsapp: "",
  },
  avatar: "",
  about: "",
  steps: {},
};

export function objectFromResponse(response) {
  let medias = response?.social_media_handles;
  let fb = medias.facebook?.replace("@", "");
  let ig = medias.instagram?.replace("@", "");
  let tw = medias.twitter?.replace("@", "");
  return {
    agencyID: response.agency_id,
    businessEntityId: response.business_entity_id,
    agencyName: response.agency_name,
    website: response.website,
    status:
      response?.is_agency_verified === 0
        ? false
        : response?.is_agency_verified === 1
        ? "APPROVE"
        : "REJECTED",
    socialMedia: {
      facebook: fb,
      instagram: ig,
      twitter: tw,
      whatsapp: medias.whatsapp,
    },
    avatar: response.logo,
    about: response.about,
    steps: response.steps,
  };
}
