import { Fragment, useState } from "react";
import { Grid, Button, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import TransactionList from "./List";
import { ActiveTextEighteen } from "../css components/Style";

export default function Transaction({ creditInfo, page, setPage, lastPage }) {
  function handlePagination(step, index) {
    setPage((prev) =>
      step === "index" ? index + 1 : prev + (step === "next" ? 1 : -1)
    );
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} style={{ marginTop: "12px" }}>
        <ActiveTextEighteen>All Transaction Details</ActiveTextEighteen>
      </Grid>

      {creditInfo.creditHistory.length > 0 ? (
        <Fragment>
          <Grid item xs={12}>
            <TransactionList creditInfo={creditInfo} />
          </Grid>

          <Grid item container display={"flex"} justifyContent={"center"}>
            <Button
              variant="outlined"
              onClick={() => handlePagination("back")}
              disabled={page < 2}
            >
              <ArrowBackIcon />
            </Button>

            {Array(Math.ceil(lastPage?.totalCount / 25))
              .fill(0)
              .map((item, index) => {
                return (
                  <Typography
                    key={index}
                    variant="h5"
                    alignContent={"center"}
                    sx={{
                      mx: 1,
                      p: "10px",
                      borderRadius: "10px",
                      ...(index + 1 === page
                        ? { boxShadow: "rgb(107 79 104) 0px 2px 4px" }
                        : { "&:hover": { backgroundColor: "#dbdbdb" } }),
                    }}
                    onClick={() => handlePagination("index", index)}
                  >
                    {index + 1}
                  </Typography>
                );
              })}

            <Button
              variant="outlined"
              onClick={() => handlePagination("next")}
              disabled={page === Math.ceil(lastPage.totalCount / 25)}
            >
              <ArrowForwardIcon />
            </Button>
          </Grid>
        </Fragment>
      ) : (
        <Grid item xs={12} textAlign={"center"}>
          <ActiveTextEighteen style={{ fontSize: "16px", marginTop: "20px" }}>
            No Transactions found.
          </ActiveTextEighteen>
        </Grid>
      )}
    </Grid>
  );
}
