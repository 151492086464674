import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Loader } from "../../components";
import Form from "./push details components/Form";
import { useAxios, useComponent } from "../../hooks";

export default function PushStats() {
  const axios = useAxios();
  const { loader } = useComponent();

  const { campaignID } = useParams();

  const [channels, setChannels] = useState([]);
  const [editState, setEditState] = useState(false);
  const [formData, setFormData] = useState({
    campaignID: campaignID,
    channels: [],
  });

  useEffect(() => {
    loader.start(2);

    axios({
      url: "/get/statsDynamicFields/" + campaignID,
      method: "get",
    }).then((response) => {
      if (response.status) {
        setChannels(response.data);
        axios({
          url: "/get/stats/" + campaignID,
        }).then((res) => {
          if (res.status) {
            let keys = {};
            res.data[0].channels.map((ch) =>
              Object.keys(ch).map((k) => {
                if (k !== "channel_id") {
                  keys[k] = ch[k];
                }
                return keys;
              })
            );

            for (let field of response.data) {
              if (res.data.length > 0) {
                if (keys[field.key_name]) {
                  setFormData((val) => ({
                    ...val,
                    channels: [
                      ...val.channels,
                      { [field.key_name]: keys[field.key_name] },
                    ],
                  }));
                } else {
                  setFormData((val) => ({
                    ...val,
                    channels: [...val.channels, { [field.key_name]: {} }],
                  }));
                }
              } else {
                setFormData((val) => ({
                  ...val,
                  channels: [...val.channels, { [field.key_name]: {} }],
                }));
                break;
              }
            }
          }
          loader.apiComplete();
          loader.apiComplete();
        });
      }
    });
  }, [axios, campaignID, loader]);

  return (
    <Loader>
      <Form
        campaignID={campaignID}
        editState={editState}
        setEditState={setEditState}
        formData={formData}
        channels={channels}
      />
    </Loader>
  );
}
