import { Typography } from "@mui/material";
import styled from "styled-components/macro";

export const colors = {
  success: "#4caf50",
  warning: "#ff9800",
  error: "#d32f2f",
  info: "#2196f3",
  green: "#28967e",
  purple: "#8f1c82",
  blue: "#028fd1",
  yellow: "#f3b61f",
  lightSuccess: "#9feb9f3d",
  lightError: "rgba(235, 159, 159, 0.24)",
};

export const HeadingEighteen = styled(Typography)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  overflow-wrap: break-word;
`;

export const ActiveTextEighteen = styled(Typography)`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  overflow-wrap: break-word;
`;

export const TextFourteenBlack = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.4;
  overflow-wrap: break-word;
`;

export const TextFifteenYellow = styled(Typography)`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  overflow-wrap: break-word;
`;

export const TextDelete = styled(Typography)`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #ff0000;
  overflow-wrap: break-word;
`;

export const NoListTypography = styled(Typography)`
  font-weight: 500;
  font-size: 30px;
  display: flex;
  justify-content: center;
  overflow-wrap: break-word;
`;
