import AWS from "aws-sdk";

export default function uploadToS3({ bucket, filePath, file }) {
  if (!file) {
    return { status: false };
  }

  AWS.config.update({
    region: bucket.region,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: bucket.identityPoolID,
    }),
  });

  let s3 = new AWS.S3({
    apiVersion: "2006-03-01",
    params: {
      Bucket: bucket.name,
    },
  });

  return new Promise((resolve) =>
    s3.upload(
      {
        Key: filePath,
        ACL: "public-read",
        Body: file,
        ContentType: file.type,
      },
      (err) => {
        resolve({ status: !err });
      }
    )
  );
}
