import { Grid } from "@mui/material";

import { Paper } from "../../../components";
import { ActiveTextEighteen, colors } from "../../css components/Style";
import { Fragment } from "react";

export default function CampaignStatus({
  liveUsers,
  formikProps,
  setTakeUpOffer,
}) {
  return (
    <Paper>
      {formikProps.values.status === "ACTIVE" &&
        formikProps.values.campaignStatus !== "MISSED" && (
          <Grid item xs={12}>
            <ActiveTextEighteen color={colors.green}>
              Your campaign is now active.
            </ActiveTextEighteen>
          </Grid>
        )}

      {formikProps.values.campaignStatus === "MISSED" && (
        <Grid item xs={12}>
          <ActiveTextEighteen color={colors.error}>
            You missed this campaign.
          </ActiveTextEighteen>
        </Grid>
      )}

      {formikProps.values.status !== "ACTIVE" && (
        <Fragment>
          {" "}
          {formikProps.values.campaignStatus === "REQUESTED" && (
            <Grid item xs={12}>
              <ActiveTextEighteen color={colors.yellow}>
                Campaign offer has been requested
              </ActiveTextEighteen>

              <div style={{ display: "flex" }}>
                <ActiveTextEighteen>Have a look! &nbsp; </ActiveTextEighteen>
                {parseInt(liveUsers?.liveUser) > 0 && (
                  <ActiveTextEighteen
                    color={colors.green}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setTakeUpOffer((prevVal) => ({
                        ...prevVal,
                        action: "ACCEPTED",
                        state: true,
                      }))
                    }
                  >
                    Accept Now.
                  </ActiveTextEighteen>
                )}
              </div>
            </Grid>
          )}
          {formikProps.values.campaignStatus === "REJECTED" && (
            <Grid item xs={12}>
              <ActiveTextEighteen color={colors.error}>
                Campaign Offer was rejected.
              </ActiveTextEighteen>

              <div style={{ display: "flex" }}>
                <ActiveTextEighteen>Change of mind? &nbsp; </ActiveTextEighteen>
                <ActiveTextEighteen
                  color={colors.green}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setTakeUpOffer((prevVal) => ({
                      ...prevVal,
                      action: "ACCEPTED",
                      state: true,
                    }))
                  }
                >
                  Accept Now.
                </ActiveTextEighteen>
              </div>
            </Grid>
          )}
          {formikProps.values.campaignStatus === "PENDING" && (
            <Grid item xs={12}>
              <ActiveTextEighteen color={colors.yellow}>
                Campaign is in pending state.
              </ActiveTextEighteen>
              <div style={{ display: "flex" }}>
                <ActiveTextEighteen> Take action. &nbsp; </ActiveTextEighteen>
                <ActiveTextEighteen
                  color={colors.green}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setTakeUpOffer((prevVal) => ({
                      ...prevVal,
                      action: "ACCEPTED",
                      state: true,
                    }))
                  }
                >
                  Accept Now.
                </ActiveTextEighteen>
              </div>
            </Grid>
          )}
          {formikProps.values.campaignStatus === "ACCEPTED" && (
            <Grid item xs={12}>
              <ActiveTextEighteen color={colors.green}>
                Campaign Offer was accepted.
              </ActiveTextEighteen>

              <div style={{ display: "flex" }}>
                <ActiveTextEighteen>Change of mind? &nbsp; </ActiveTextEighteen>
                <ActiveTextEighteen
                  color={colors.error}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setTakeUpOffer((prevVal) => ({
                      ...prevVal,
                      action: "REJECTED",
                      state: true,
                    }))
                  }
                >
                  Reject Now.
                </ActiveTextEighteen>
              </div>
            </Grid>
          )}
        </Fragment>
      )}

      {formikProps.values.campaignStatus === "PROOF_GENERATION_FAILED" && (
        <Grid item xs={12}>
          <ActiveTextEighteen color={colors.error}>
            Campaign proof generation failed.
          </ActiveTextEighteen>

          <div style={{ display: "flex" }}>
            <ActiveTextEighteen>Accept Offer again. &nbsp; </ActiveTextEighteen>
            <ActiveTextEighteen
              color={colors.green}
              style={{ cursor: "pointer" }}
              onClick={() =>
                setTakeUpOffer((prevVal) => ({
                  ...prevVal,
                  action: "ACCEPTED",
                  state: true,
                }))
              }
            >
              Accept Now.
            </ActiveTextEighteen>
          </div>
        </Grid>
      )}
    </Paper>
  );
}
