import { Fragment, useState } from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { FaRegUser } from "react-icons/fa";
import { IoInformationCircleOutline } from "react-icons/io5";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";

import { Paper } from "../../../components";
import pages from "../../../constants/pages";
import {
  HeadingEighteen,
  TextFourteenBlack,
  TextFifteenYellow,
  colors,
} from "../../css components/Style";
import LOGO from "../../../assets/default_logo.webp";
import {
  brandDetailsObject,
  objectFromResponse,
} from "../../brand components/brand details components/brandDetailObject";
import BrandDetailsDialog from "../../campaign components/brand details components/BrandDetailsDialog";

const Link = styled(Typography)`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: ${colors.purple};
  cursor: pointer;
`;

const ImageDiv = styled.div`
  height: 55.8px;
  width: 55.8px;
  margin-left: 15px;
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 50%;
`;

const Icon = styled(IconButton)`
  border: 1px solid ${colors.purple};
  color: ${colors.purple};
  height: 35px;
  width: 35px;
  margin: 0 5px;
  padding: 0 5px;
  &:hover {
    border: 1px solid ${colors.purple};
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
`;

function convertDate(d) {
  let date = new Date(d);

  return (
    date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
  );
}

export default function BrandList({ brandList, component }) {
  const navigate = useNavigate();
  const [brandDetails, setBrandDetails] = useState({
    data: brandDetailsObject,
    state: false,
  });

  const onImageError = (e) => {
    e.target.src = LOGO;
  };

  function handleNavigation(brand) {
    if (component === "users") {
      navigate(pages.usersList.route + brand.brand_id);
    }

    if (component === "campaign") {
      navigate(pages.superAdminBrandCampaigns.route + brand.brand_id);
    }
  }

  return (
    <Fragment>
      <Grid container spacing={3}>
        {brandList.map((brand) => (
          <Grid item xs={12} key={brand.brand_id}>
            <Paper style={{ padding: "0px" }} noGrid={true}>
              <Grid container spacing={0}>
                <Grid item alignSelf="center" paddingRight="15px">
                  <ImageDiv>
                    <Image
                      src={brand.logo ? brand.logo : LOGO}
                      alt="!"
                      onError={onImageError}
                    />
                  </ImageDiv>
                </Grid>

                <Grid item xs style={{ padding: "10px 25px" }}>
                  <Grid container>
                    <Grid item xs={12} justifyContent={"end"} display="flex">
                      {brand.new_user && (
                        <Fragment>
                          <svg
                            style={{
                              position: "relative",
                              margin: "9px 9px",
                            }}
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="5" cy="5" r="5" fill={"#FFBB0B"} />
                          </svg>
                          <TextFifteenYellow
                            style={{ fontSize: "14px" }}
                            color={colors.warning}
                          >
                            New Users Pending Approval
                          </TextFifteenYellow>
                        </Fragment>
                      )}
                    </Grid>

                    <Grid item xs={2.5}>
                      <div style={{ display: "flex" }}>
                        <TextFourteenBlack> Brand </TextFourteenBlack>

                        <IoInformationCircleOutline
                          onClick={() => handleBrandDetails(brand)}
                          style={{
                            color: colors.purple,
                            marginLeft: "5px",
                            fontSize: "25px",
                            cursor: "pointer",
                            position: "relative",
                            bottom: "4px",
                          }}
                        />
                      </div>

                      <HeadingEighteen>{brand.brand_name}</HeadingEighteen>
                    </Grid>

                    <Grid item xs={3}>
                      <TextFourteenBlack>Partner Type</TextFourteenBlack>
                      <HeadingEighteen>
                        {brand.business_entity_type_name}
                      </HeadingEighteen>
                    </Grid>

                    <Grid item xs={1.5}>
                      <TextFourteenBlack>Date Joined</TextFourteenBlack>
                      <HeadingEighteen>
                        {convertDate(brand.created_date)}
                      </HeadingEighteen>
                    </Grid>

                    <Grid item xs={1.5}>
                      <TextFourteenBlack>Total Users</TextFourteenBlack>
                      <HeadingEighteen>{brand.total_users}</HeadingEighteen>
                    </Grid>

                    <Grid item xs={1.5}>
                      <TextFourteenBlack>Total Credits</TextFourteenBlack>
                      <HeadingEighteen>
                        {brand.credit_details?.coins || 0}
                      </HeadingEighteen>
                    </Grid>

                    <Grid
                      item
                      xs={2}
                      alignSelf={"center"}
                      display="flex"
                      justifyContent="end"
                    >
                      <Link onClick={() => handleNavigation(brand)}>
                        {component === "campaign" ? (
                          "Campaigns"
                        ) : (
                          <Tooltip title="View Users">
                            <Icon>
                              <FaRegUser
                                style={{ height: "20px", width: "20px" }}
                              />
                            </Icon>
                          </Tooltip>
                        )}
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {brandList.length === 0 && (
        <Grid item container p={12} justifyContent={"center"} xs={12}>
          <Typography variant="h3">No Brand Found</Typography>
        </Grid>
      )}
      <BrandDetailsDialog details={brandDetails} setDetails={setBrandDetails} />
    </Fragment>
  );

  function handleBrandDetails(brandData) {
    setBrandDetails({
      data: objectFromResponse(brandData),
      state: true,
      component: "connection",
    });
  }
}
