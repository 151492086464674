import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";

import { TextField } from "../../../components";
import { PrimaryLoadingButton } from "../../css components/Button";
import Logo from "../../../assets/auth carousel pics/verismart-background.png";

export default function FormDisplay({ formikProps }) {
  const navigate = useNavigate();

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} textAlign={"center"}>
        <img style={{ height: "50px" }} src={Logo} alt="logo" />
        <Typography className="font">
          Forgot your password? No Problem, enter your email and we will send
          you the reset password link!
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          label="Registered email"
          name="email"
          formikProps={formikProps}
          placeholder="Registered email"
        />
      </Grid>

      <Grid item xs={12}>
        <PrimaryLoadingButton
          fullWidth
          type="submit"
          variant="contained"
          onClick={formikProps.handleSubmit}
          disabled={!formikProps.isValid}
          loading={formikProps.isSubmitting}
        >
          Reset
        </PrimaryLoadingButton>
      </Grid>

      <Grid item xs={12} alignSelf="center" textAlign={"center"}>
        <Typography className="font" variant="caption1">
          Go back to?{" "}
          <Typography
            variant="button"
            className="purple font"
            style={{ cursor: "pointer", fontWeight: 500 }}
            onClick={() => navigate("/sign-in")}
          >
            Sign In!
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
}
