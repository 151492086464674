import { Paper } from "@mui/material";
import styled from "styled-components/macro";
import { useTheme } from "@mui/material/styles";

import Menu from "./sidebar components/Menu";

const Wrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  transition: 0.2s ease;
  border-right: 1px solid #cbcbcb5e;
  overflow: hidden;
  border-radius: 0;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
`;

export default function Sidebar({
  sidebarState,
  onMouseEnter,
  onMouseLeave,
  selectedMenu,
  setSelectedMenu,
}) {
  const theme = useTheme();

  return (
    <Wrapper
      elevation={2}
      style={{
        width: sidebarState.open ? "200px" : "80px",
        background: theme.sidebar.background,
      }}
    >
      <Menu
        sidebarState={sidebarState}
        selectedMenu={selectedMenu}
        setSelectedMenu={setSelectedMenu}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />

      {/* <Shortcuts sidebarState={sidebarState} /> */}
    </Wrapper>
  );
}
