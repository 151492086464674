import { Menu } from "@mui/material";
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import DropDownMenu from "./DropDownMenu";
import {
  PrimaryButton,
  Reject,
  Accept,
  Pending,
  AcceptUpload,
  PrimaryLoadingButton,
} from "../../css components/Button";
import pages from "../../../constants/pages";
import { useAxios } from "../../../hooks";
import { alert } from "../../../redux/slices/alertSlice";

export default function CampaignButtons({
  liveUsers,
  formikProps,
  component,
  brandIdentity,
  setTakeUpOffer,
  setDialogUploadCSV,
  setBroadcastReportDialog,
}) {
  const axios = useAxios();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [menuAnchor, setMenuAnchor] = useState(null);

  const [loading, setLoading] = useState(false);

  return (
    <Fragment>
      {component === "" &&
        formikProps.values?.campaignID &&
        formikProps.values?.status === "ACTIVE" &&
        formikProps.values.permissionSettings.display_stats && (
          <PrimaryButton
            onClick={() =>
              navigate(
                pages.analytics.route +
                  formikProps.values.campaignID +
                  "?campaignName=" +
                  formikProps.values?.campaign_name?.value
              )
            }
            variant="contained"
          >
            Analytics
          </PrimaryButton>
        )}
      {component === "superadmin" && (
        <Accept
          size="large"
          variant="contained"
          style={{ marginRight: "15px" }}
        >
          Approve Campaign
        </Accept>
      )}
      {brandIdentity &&
        component !== "accepted" &&
        formikProps.values.status !== "ACTIVE" && (
          <Fragment>
            {formikProps.values.campaignStatus !== "REJECTED" &&
              formikProps.values.status !== "ACTIVE" && (
                <Reject
                  size="large"
                  variant="contained"
                  style={{ marginRight: "15px" }}
                  onClick={() =>
                    setTakeUpOffer((prevVal) => ({
                      ...prevVal,
                      action: "REJECTED",
                      state: true,
                    }))
                  }
                >
                  Reject
                </Reject>
              )}

            {formikProps.values.campaignStatus === "REQUESTED" && (
              <Pending
                size="large"
                variant="contained"
                style={{ marginRight: "15px" }}
                onClick={() =>
                  setTakeUpOffer((prevVal) => ({
                    ...prevVal,
                    action: "PENDING",
                    state: true,
                  }))
                }
              >
                Pending
              </Pending>
            )}

            {formikProps.values.campaignStatus !== "ACCEPTED" &&
              formikProps.values.campaignStatus !== "MISSED" && (
                <Accept
                  size="large"
                  variant="contained"
                  disabled={
                    parseInt(liveUsers?.liveUser) <= 0 || !liveUsers.liveUser
                  }
                  style={{ marginRight: "15px" }}
                  onClick={() =>
                    setTakeUpOffer((prevVal) => ({
                      ...prevVal,
                      action: "ACCEPTED",
                      state: true,
                    }))
                  }
                >
                  Accept
                </Accept>
              )}
          </Fragment>
        )}

      {component === "request" &&
        formikProps.values.csvUpload &&
        (formikProps.values.campaignStatus === "REQUESTED" ||
          formikProps.values.campaignStatus === "REJECTED") && (
          <AcceptUpload
            variant="contained"
            onClick={() => setDialogUploadCSV(true)}
          >
            UPLOAD ACCEPT
          </AcceptUpload>
        )}

      {formikProps.values.status === "ACTIVE" && (
        <Fragment>
          <PrimaryLoadingButton
            variant="outlined"
            size="large"
            loading={loading}
            sx={{
              marginLeft: "5px",
              whiteSpace: "nowrap",
            }}
            onClick={(event) => {
              if (component === "") {
                setMenuAnchor(event.currentTarget);
              }

              if (component === "request") {
                handleDownloadReport();
              }
            }}
          >
            Report
          </PrimaryLoadingButton>

          <Menu
            style={{ marginTop: "10px" }}
            open={!!menuAnchor}
            anchorEl={menuAnchor}
            onClose={() => setMenuAnchor(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <DropDownMenu
              formikProps={formikProps}
              setLoading={setLoading}
              setMenuAnchor={setMenuAnchor}
            />
          </Menu>
        </Fragment>
      )}
    </Fragment>
  );

  function handleDownloadReport() {
    setLoading(true);
    let url = "/brand/campaign/report/" + formikProps.values?.campaignID;
    if (component === "request") {
      url = "/contributer/campaign/report/" + formikProps.values?.campaignID;
    }

    axios({
      url: url,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        window.location.href = response?.data?.reportUrl;
        dispatch(
          alert({
            type: "success",
            message: "Report was successfully downloaded.",
          })
        );
        setLoading(false);
      }
    });
  }
}
