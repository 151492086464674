import { Fragment } from "react";
import styled from "styled-components/macro";
import { Grid, MenuItem } from "@mui/material";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

import { TextField } from "../../../../components";

const Menu = styled(MenuItem)`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
`;

export default function FormFourth({ formikProps, editState }) {
  return (
    <Fragment>
      <Grid item xs={6}>
        <TextField
          select
          required
          label="Select Conversion Type"
          formikProps={formikProps}
          name="conversion_type.value"
          onBlur={() => {
            formikProps.setTouched({
              "conversion_type.value": true,
            });
          }}
          inputProps={{
            IconComponent: () =>
              !editState ? null : (
                <ArrowDropDown
                  style={{ marginRight: "8px", cursor: "pointer" }}
                />
              ),
          }}
          disabled={!editState}
          sx={{
            "& .MuiSvgIcon-root": {
              display: !editState && "none",
            },
          }}
          SelectProps={{
            displayEmpty: true,
          }}
          InputProps={{
            style: {
              color:
                formikProps.values.conversion_type.value === "" && "#bababacf",
            },
          }}
        >
          <Menu disabled selected value="">
            Select Conversion Type
          </Menu>
          <Menu value="CPM">CPM - (Cost Per Mile)</Menu>
        </TextField>
      </Grid>
    </Fragment>
  );
}
