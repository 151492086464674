import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { Loader } from "../../components";
import { useAxios, useComponent } from "../../hooks";
import {
  agencyObject,
  objectFromResponse,
} from "./unverified agencies details/unverifiedAgenciesObject";
import FormDisplay from "./unverified agencies details/FormDisplay";

export default function UnverifiedAgenciesDetails() {
  const axios = useAxios();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loader } = useComponent();

  const [brands, setBrands] = useState([]);
  const [formData, setFormData] = useState(agencyObject);

  const agencyID = queryString.parse(window.location.search).agencyID;

  const { location, languages } = useSelector((state) => state.memberDetails);

  useEffect(() => {
    loader.start(2);

    if (!!agencyID) {
      axios({
        url: "/getAgency/" + agencyID,
        method: "GET",
      }).then((res) => {
        if (res.status) {
          setFormData(objectFromResponse(res.data));
          axios({
            url: "/brand?businessEntityId=" + res.data.business_entity_id,
          }).then((response) => {
            if (response.status) {
              let brandList = [];
              for (const brand of response?.data) {
                brandList.push({
                  ID: brand.brand_id,
                  about: brand.about,
                  brandName: brand.brand_name,
                  logo: !!brand.logo
                    ? brand.logo
                    : "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/NaN_logo.png",
                  languages: languages.filter((rl) =>
                    brand.languages.includes(rl.code)
                  ),
                  locations: location.filter((rl) =>
                    brand.audience_location?.includes(rl.country_code)
                  ),
                  category: brand.site_category,
                  mau: brand.mau,
                  onlineStore: brand.online_store,
                  website: brand.website,
                  socialMedia: brand.social_media_handles,
                  brandType: brand.brand_type_id,
                  isBrandVerified: brand.is_brand_verified,
                });
              }
              setBrands(brandList);
            }
          });

          loader.stop();
        }
      });
    } else {
      loader.apiComplete(2);
    }
  }, [axios, loader, agencyID, navigate, dispatch]);

  return (
    <Loader>
      <FormDisplay
        brands={brands}
        formData={formData}
        setFormData={setFormData}
      />
    </Loader>
  );
}
