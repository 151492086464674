import { Chip as MuiChip } from "@mui/material";

export default function Chip({
  label = "",
  type = "",
  color = "",
  background = "",
  deleteButton = [],
  ...props
}) {
  let defaultColor, defaultLabel, defaultBackground;
  const [enableDelete, handleDelete] = deleteButton;

  switch (type) {
    case "success":
      defaultColor = "#4F8A10";
      defaultLabel = "Success";
      defaultBackground = "#DFF2BF";
      break;

    case "error":
      defaultColor = "#D8000C";
      defaultLabel = "Error";
      defaultBackground = "#FFD2D2";
      break;

    case "info":
      defaultColor = "#014361";
      defaultLabel = "Info";
      defaultBackground = "#E5f6FD";
      break;

    case "warning":
      defaultColor = "#663C00";
      defaultLabel = "Info";
      defaultBackground = "#FFF4E5";
      break;

    default:
      defaultColor = "#FFF";
      defaultLabel = "Label";
      defaultBackground = "#000";
  }

  return (
    <MuiChip
      label={label || defaultLabel}
      style={{
        fontWeight: 500,
        color: color || defaultColor,
        background: background || defaultBackground,
      }}
      onDelete={enableDelete ? handleDelete : null}
      {...props}
    />
  );
}
