import { Fragment } from "react";
import { FaRegQuestionCircle } from "react-icons/fa";
import { Grid, Typography, Checkbox, Tooltip, IconButton } from "@mui/material";

import { colors } from "../../css components/Style";
import EditButtons from "../../../components/EditButtons";
import { ButtonFooter, PageHeader, Paper } from "../../../components";
import {
  CancelButton,
  PrimaryLoadingButton,
} from "../../css components/Button";
import { useNavigate } from "react-router-dom";

export default function FormDisplay({
  settings,
  formikProps,
  editState,
  setEditState,
}) {
  const navigate = useNavigate();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PageHeader
          pageName={"Permission Setting"}
          pageHeader={"Control the permission settings here"}
          buttons={[
            <EditButtons setEditState={setEditState} editState={editState} />,
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        <Paper>
          <Grid item xs={4}>
            <Typography variant="h4">Feature</Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant="h4">Permission</Typography>
          </Grid>

          <Grid item xs={12} />
          {settings.map((setting) => (
            <Fragment key={setting.Id}>
              <Grid item xs={4}>
                <Typography variant="h5">
                  {setting.setting_name}
                  <Tooltip title={setting?.help_text || ""}>
                    <IconButton>
                      <FaRegQuestionCircle />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>

              <Grid item xs={8}>
                <Checkbox
                  disabled={!editState}
                  onChange={(e) => {
                    formikProps.setFieldValue(
                      setting.setting_key,
                      e.target.checked
                    );
                  }}
                  checked={formikProps.values[setting?.setting_key]}
                  sx={{
                    padding: 0,
                    color: colors.purple,
                    "&.Mui-checked": {
                      color: colors.purple,
                    },
                  }}
                />
              </Grid>
            </Fragment>
          ))}

          {editState && (
            <Fragment>
              <Grid item xs />

              <Grid item>
                <CancelButton onClick={() => navigate(-1)} variant="outlined">
                  Back
                </CancelButton>
              </Grid>

              <Grid item>
                <PrimaryLoadingButton
                  type="submit"
                  variant="contained"
                  fullWidth
                  onClick={formikProps.handleSubmit}
                  disabled={!formikProps.dirty || !formikProps.isValid}
                  loading={formikProps.isSubmitting}
                  color="primary"
                >
                  Submit
                </PrimaryLoadingButton>
              </Grid>
            </Fragment>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
