import { get } from "lodash";
import { Fragment, useState } from "react";
import { TbFileUpload } from "react-icons/tb";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Grid,
  Typography,
  IconButton,
  Chip,
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

import { Reject } from "../../css components/Button";
import { colors } from "../../css components/Style";
import { uploadToS3 } from "../../../utils";

export default function FormThree({
  agencyID,
  brandID,
  formikProps,
  editState,
}) {
  const [deleteDialog, setDeleteDialog] = useState({ state: false });

  function handleClick(url) {
    window.open(url, "_blank");
  }

  const handleClose = () => {
    setDeleteDialog({ state: false });
  };

  function handleDelete(event, name) {
    formikProps.setValues((prev) => ({
      ...prev,
      [name]: "",
    }));
  }

  return (
    <Fragment>
      <Grid item xs={12} display={"flex"} gap="10px">
        <div className="div-upload-document">
          {!formikProps.values.gstCertificate && (
            <IconButton>
              <input
                disabled={!editState}
                accept=".jpg,.jpeg,.png,.pdf"
                style={{ display: "none" }}
                id="contained-button-file-1"
                multiple
                onChange={(event) => handleFileChange(event, "gstCertificate")}
                type="file"
              />
              <label htmlFor="contained-button-file-1">
                <TbFileUpload
                  disabled={!editState}
                  style={{ fontSize: "25px", color: colors.purple }}
                />
              </label>
            </IconButton>
          )}

          <Typography color={colors.purple}>Company GST Certificate</Typography>

          {!!formikProps.values.gstCertificate && (
            <Chip
              className="file-chip"
              style={{
                borderColor: colors.green,
                color: colors.green,
              }}
              label={formikProps.values.gstCertificate?.split("_").pop()}
              variant="outlined"
              onClick={() => handleClick(formikProps.values.gstCertificate)}
              disabled={!editState}
              deleteIcon={
                <DeleteIcon
                  disabled={!editState}
                  style={{ color: colors.error }}
                />
              }
              onDelete={(event) =>
                setDeleteDialog({
                  state: true,
                  event: event,
                  target: "gstCertificate",
                })
              }
            />
          )}
        </div>

        <div className="div-upload-document">
          {!formikProps.values.companyPanCertificate && (
            <IconButton>
              <input
                disabled={!editState}
                accept=".jpg,.jpeg,.png,.pdf"
                style={{ display: "none" }}
                id="contained-button-file-2"
                multiple
                onChange={(event) =>
                  handleFileChange(event, "companyPanCertificate")
                }
                type="file"
              />
              <label htmlFor="contained-button-file-2">
                <TbFileUpload
                  disabled={!editState}
                  style={{ fontSize: "25px", color: colors.purple }}
                />
              </label>
            </IconButton>
          )}

          <Typography color={colors.purple}>
            Company PAN Certificate*
          </Typography>

          {!!formikProps.errors.companyPanCertificate && formikProps.dirty && (
            <Typography color={colors.error} fontWeight={600}>
              Company PAN Certificate Is Required
            </Typography>
          )}

          {!!formikProps.values.companyPanCertificate && (
            <Chip
              className="file-chip"
              style={{
                borderColor: colors.green,
                color: colors.green,
              }}
              disabled={!editState}
              label={formikProps.values.companyPanCertificate?.split("_").pop()}
              variant="outlined"
              onClick={() =>
                handleClick(formikProps.values.companyPanCertificate)
              }
              deleteIcon={<DeleteIcon style={{ color: colors.error }} />}
              onDelete={(event) =>
                setDeleteDialog({
                  state: true,
                  event: event,
                  target: "companyPanCertificate",
                })
              }
            />
          )}
        </div>

        <div className="div-upload-document">
          {!formikProps.values.directorAadharCertificate && (
            <IconButton>
              <input
                disabled={!editState}
                accept=".jpg,.jpeg,.png,.pdf"
                style={{ display: "none" }}
                id="contained-button-file-3"
                multiple
                onChange={(event) =>
                  handleFileChange(event, "directorAadharCertificate")
                }
                type="file"
              />
              <label htmlFor="contained-button-file-3">
                <TbFileUpload
                  disabled={!editState}
                  style={{ fontSize: "25px", color: colors.purple }}
                />
              </label>
            </IconButton>
          )}

          <Typography color={colors.purple}>
            Company Director's Aadhar Certificate
          </Typography>

          {!!formikProps.values.directorAadharCertificate && (
            <Chip
              disabled={!editState}
              className="file-chip"
              style={{
                borderColor: colors.green,
                color: colors.green,
              }}
              label={formikProps.values.directorAadharCertificate
                ?.split("_")
                .pop()}
              variant="outlined"
              onClick={() =>
                handleClick(formikProps.values.directorAadharCertificate)
              }
              deleteIcon={<DeleteIcon style={{ color: colors.error }} />}
              onDelete={(event) =>
                setDeleteDialog({
                  state: true,
                  event: event,
                  target: "directorAadharCertificate",
                })
              }
            />
          )}
        </div>

        <div className="div-upload-document">
          {!formikProps.values.directorPanCertificate && (
            <IconButton>
              <input
                disabled={!editState}
                accept=".jpg,.jpeg,.png,.pdf"
                style={{ display: "none" }}
                id="contained-button-file-4"
                multiple
                onChange={(event) =>
                  handleFileChange(event, "directorPanCertificate")
                }
                type="file"
              />
              <label htmlFor="contained-button-file-4">
                <TbFileUpload
                  disabled={!editState}
                  style={{ fontSize: "25px", color: colors.purple }}
                />
              </label>
            </IconButton>
          )}

          <Typography color={colors.purple}>
            Company Director's PAN Certificate*
          </Typography>

          {formikProps.errors.directorPanCertificate && formikProps.dirty && (
            <Typography color={colors.error} fontWeight={600}>
              Company Director's PAN Certificate Is Required
            </Typography>
          )}

          {!!formikProps.values.directorPanCertificate && (
            <Chip
              disabled={!editState}
              className="file-chip"
              style={{
                borderColor: colors.green,
                color: colors.green,
              }}
              label={formikProps.values.directorPanCertificate
                ?.split("_")
                .pop()}
              variant="outlined"
              onClick={() =>
                handleClick(formikProps.values.directorPanCertificate)
              }
              deleteIcon={
                <DeleteIcon
                  disabled={!editState}
                  style={{ color: colors.error }}
                />
              }
              onDelete={(event) =>
                setDeleteDialog({
                  state: true,
                  event: event,
                  target: "directorPanCertificate",
                })
              }
            />
          )}
        </div>
      </Grid>

      <DeleteDialog deleteDialog={deleteDialog} />
    </Fragment>
  );

  function DeleteDialog({ deleteDialog }) {
    return (
      <MUIDialog open={deleteDialog.state} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            You have selected the option to delete! Please confirm before going
            further.
          </DialogContentText>
        </DialogContent>

        <DialogActions style={{ padding: "0px 24px 16px" }}>
          <Reject
            onClick={() => {
              handleDelete(deleteDialog.event, deleteDialog.target);
              handleClose();
            }}
            variant="contained"
            color="primary"
          >
            Delete
          </Reject>
        </DialogActions>
      </MUIDialog>
    );
  }

  function handleFileChange({ target }, name) {
    let folderPath = "company_document/" + new Date().getTime() + "_";
    const file = get(target, "files[0]", "");
    let fileName = file?.name?.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, "");
    const uploadBucket = {
      name: "adn-media",
      region: "ap-south-1",
      identityPoolID: "ap-south-1:16fa1fcf-7cdd-4593-9ee0-6f621526defc",
    };

    uploadToS3({
      bucket: uploadBucket,
      filePath: folderPath + fileName,
      file,
    }).then((response) => {
      if (response.status) {
        formikProps.setFieldValue(
          [name],
          "https://" +
            uploadBucket.name +
            ".s3.ap-south-1.amazonaws.com/" +
            folderPath +
            fileName
        );
      }
    });
  }
}
