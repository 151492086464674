import { Grid, Avatar, Skeleton, Button, Pagination } from "@mui/material";
import { useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { Fragment, useEffect, useState } from "react";

import List from "../campaign list components/List";
import { useAxios, useComponent } from "../../../hooks";
import { Loader, PageHeader } from "../../../components";
import Search from "../campaign list components/superadmin list components/Search";
import SortBy from "../campaign list components/superadmin list components/SortBy";
import Categorize from "../campaign list components/superadmin list components/Categorize";
import BrandList from "../../superAdmin brand components/brand list components/BrandList";
import { toInternationalFormat } from "../../../utils";
import EmptyList from "../../misc/EmptyList";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const LogoAvatar = styled(Avatar)`
  height: 70px;
  width: 70px;
  border-radius: 50%;
`;

export default function SuperAdminBrandCampaignList() {
  const axios = useAxios();
  const params = useParams();
  const { loader } = useComponent();

  const [page, setPage] = useState(1);
  const [brand, setBrand] = useState({});
  const [sortBy, setSortBy] = useState("ALL");
  const [loading, setLoading] = useState(true);
  const [searchKey, setSearchKey] = useState("");
  const [campaigns, setCampaigns] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [lastPage, setLastPage] = useState({ totalCount: 0, lastPage: false });
  const [categorize, setCategorize] = useState("Campaign Offers");

  const brandID = params.brandID || "";

  useEffect(() => {
    loader.start();

    if (categorize === "Campaign Offers") {
      let url =
        "/user/brand/getCampaignsByBrand/?brandId=" +
        brandID +
        `&page=${page}&limit=10` +
        (searchKey ? `&search=${encodeURIComponent(searchKey)}` : "");

      if (sortBy !== "ALL") {
        url =
          "/user/brand/getCampaignsByBrand/?brandId=" +
          brandID +
          "&requestStatus=" +
          sortBy;
      }

      axios({
        url: url,
      }).then((response) => {
        if (response.status) {
          axios({
            url: "/getBrand/" + brandID,
            method: "GET",
          }).then((brands) => {
            if (brands.status) {
              setBrand(brands.data);
              setLoading(false);
              let arr = [];

              for (let key of response.data.campaignData) {
                let data = {};
                for (let fields of key.fields) {
                  if (
                    Object.keys(fields)[0] === "budget_min" ||
                    Object.keys(fields)[0] === "budget_max" ||
                    Object.keys(fields)[0] === "monthly_active_user" ||
                    Object.keys(fields)[0] === "volume"
                  ) {
                    data[Object.keys(fields)[0]] = toInternationalFormat(
                      Object.values(fields)[0]
                    );
                  } else {
                    data[Object.keys(fields)[0]] = Object.values(fields)[0];
                  }
                }
                arr.push({
                  ...data,
                  brand: {
                    name: brands.data.brand_name,
                    logo: brands.data.logo,
                  },
                  previewImage: !!key.preview_image
                    ? key.preview_image
                    : "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_placeholder.png",
                  status: key.status,
                  audience_id: key.audience_id,
                  brand_id: key.brand_id,
                  campaign_id: key.campaign_id,
                });
              }
              setCampaigns(arr);

              if (response.data.campaignData.length > 0) {
                setLastPage({
                  totalCount: response.data.totalCounts,
                  lastPage: response.data.campaignData.length < 10,
                });
              } else {
                setLastPage({
                  totalCount: response.data.totalCounts,
                  lastPage: true,
                });
              }

              loader.stop();
            }
          });
        } else {
          loader.stop();
        }
      });
    }

    if (categorize === "Unverified Campaigns") {
      axios({
        url: "/user/brand/getUnApprovedStatus",
      }).then((response) => {
        if (response.status) {
          setBrandList(response.data);
          loader.apiComplete();
        } else {
          loader.stop();
        }
      });
    }

    if (categorize === "Brands") {
      axios({
        url: "/getAllBrands",
      }).then((response) => {
        if (response.status) {
          setBrandList(response.data.parsedBrands);
          loader.apiComplete();
        } else {
          loader.stop();
        }
      });
    }

    if (categorize === "Active Campaigns") {
    }
  }, [axios, brandID, loader, categorize, sortBy, page, searchKey]);

  function handlePagination(step, index) {
    setPage((prev) =>
      step === "index" ? index : prev + (step === "next" ? 1 : -1)
    );
  }

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} display="flex">
          {loading ? (
            <div style={{ display: "flex", marginBottom: "20px" }}>
              <div>
                <Skeleton
                  animation="wave"
                  variant="circular"
                  height={70}
                  width={70}
                />
              </div>

              <div>
                <Skeleton
                  animation="wave"
                  style={{ marginLeft: "20px" }}
                  variant="rectangular"
                  height={23}
                  width={50}
                />

                <Skeleton
                  style={{ marginLeft: "20px", marginTop: "20px" }}
                  animation="wave"
                  variant="rectangular"
                  height={23}
                  width={80}
                />
              </div>
            </div>
          ) : (
            <Fragment>
              <Grid item alignSelf="center" paddingRight="20px">
                <LogoAvatar src={brand.logo} alt="!" />
              </Grid>

              <PageHeader
                heading={"Campaigns"}
                pageName={
                  categorize === "Brands" ? "All Brands" : brand.brand_name
                }
              />
            </Fragment>
          )}
        </Grid>

        <Grid item xs={6} display="flex" justifyContent={"space-between"}>
          <Categorize
            setPage={setPage}
            categorize={categorize}
            setCategorize={setCategorize}
          />

          <SortBy
            sortBy={sortBy}
            setPage={setPage}
            setSortBy={setSortBy}
            categorize={categorize}
          />

          <Search setPage={setPage} setSearchKey={setSearchKey} />
        </Grid>

        <Grid item xs={12} />
      </Grid>

      <Loader height="75%">
        {categorize === "Campaign Offers" ? (
          campaigns.length > 0 ? (
            <List campaigns={campaigns} component="superadmin" />
          ) : (
            <EmptyList title={"No campaigns to show!"} />
          )
        ) : categorize === "Brands" ? (
          brandList.length > 0 ? (
            <BrandList brandList={brandList} component="campaign" />
          ) : (
            <EmptyList title={"No campaigns to show!"} />
          )
        ) : (
          categorize === "Unverified Campaigns" && (
            <List campaigns={campaigns} component="superadmin" />
          )
        )}
      </Loader>

      {!!lastPage.totalCount && (
        <Grid item container display={"flex"} justifyContent={"center"}>
          <Pagination
            count={Math.ceil(lastPage.totalCount / 10)}
            variant="outlined"
            color="secondary"
            page={page}
            onChange={(e, value) => handlePagination("index", value)}
          />
        </Grid>
      )}
    </Fragment>
  );
}
