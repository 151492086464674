import queryString from "query-string";
import { useEffect, useState } from "react";

import { Loader } from "../../components";
import { useAxios, useComponent } from "../../hooks";
import {
  companyObject,
  objectFromResponseData,
} from "../company components/company details components/companyDetailsObject";
import FormDisplay from "./unverified company details/FormDisplay";

export default function UnverifiedCompanyDetails() {
  const axios = useAxios();
  const { loader } = useComponent();

  const [noDetails, setNoDetails] = useState(false);
  const [formData, setFormData] = useState(companyObject);
  const brandID = queryString.parse(window.location.search).brandID;

  useEffect(() => {
    loader.start(1);
    if (!!brandID) {
      axios({
        url: "/getCompany/" + brandID,
      }).then((response) => {
        if (response.status && response.data !== null) {
          setFormData(objectFromResponseData(response.data));
          loader.stop();
        } else {
          setNoDetails(true);
          setFormData(companyObject);
          loader.stop();
        }
      });
    } else {
      loader.stop();
    }
  }, [axios, brandID, loader]);

  return (
    <Loader>
      <FormDisplay
        noDetails={noDetails}
        formData={formData}
        setFormData={setFormData}
      />
    </Loader>
  );
}
