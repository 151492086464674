import { Formik } from "formik";
import { useDispatch } from "react-redux";

import FormDisplay from "./FormDisplay";
import { useAxios } from "../../../hooks";
import { alert } from "../../../redux/slices/alertSlice";

export default function Form({
  settings,
  formData,
  setFormData,
  editState,
  setEditState,
}) {
  const axios = useAxios();
  const dispatch = useDispatch();

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validateOnChange
      onSubmit={(formData, { setSubmitting, resetForm }) => {
        setSubmitting(true);

        axios({
          url: "/save/displaySettings",
          method: "POST",
          data: formData,
        }).then((response) => {
          if (response.status) {
            dispatch(
              alert({
                tyoe: "success",
                message: "Settings saved successfully",
              })
            );
          }
          setSubmitting(false);
          setFormData(formData);
          setEditState(false);
        });
      }}
    >
      {(formikProps) => (
        <FormDisplay
          settings={settings}
          setEditState={setEditState}
          editState={editState}
          formikProps={formikProps}
        />
      )}
    </Formik>
  );
}
