import { Grid } from "@mui/material";

import {
  ActiveTextEighteen,
  TextFifteenYellow,
  colors,
} from "../../css components/Style";

export default function FieldName({ formikProps }) {
  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextFifteenYellow>Column Names</TextFifteenYellow>
        </Grid>

        <Grid item xs={12}>
          <ActiveTextEighteen color={colors.purple}>
            {formikProps.values.fieldNames}
          </ActiveTextEighteen>
        </Grid>
      </Grid>
    </Grid>
  );
}
