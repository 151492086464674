import { Fragment, useEffect } from "react";
import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import styled from "styled-components/macro";

import { useAxios } from "../../../hooks";
import { Paper } from "../../../components";
import { ActiveTextEighteen, colors } from "../../css components/Style";

const OuterDiv = styled(Paper)`
  background: ${colors.green};
`;

const UserTypography = styled(Typography)`
  color: #fff;
  font-size: 18px;
  font-weight: 600;
`;

const Snap = styled(Typography)`
  color: #fff;
  margin-top: 10px;
  font-size: 20px;
`;

export default function CampaignLiveUser({
  liveUsers,
  loadingLiveUsers,
  userData,
  setLiveUsers,
  setLoadingLiveUsers,
}) {
  const axios = useAxios();

  useEffect(() => {
    // Use setTimeout to update the message after 2000 milliseconds (2 seconds)
    if (!!liveUsers.liveMessage) {
      const timeoutId = setInterval(() => {
        axios({
          url: "/user/getUsersCount?v=1",
          method: "POST",
          disableRedirect: true,
          disableTimeOut: true,
          data: userData,
        }).then((response) => {
          if (response.status) {
            setLiveUsers({
              state: true,
              liveMessage: response.data.message,
              liveUser: response?.data.usersCount,
            });
            setLoadingLiveUsers(false);
          }
        });
      }, 10000);

      // Cleanup function to clear the timeout if the component unmounts
      return () => clearTimeout(timeoutId);
    }
  }, [liveUsers.liveMessage, axios, userData]);

  return (
    <OuterDiv style={{ background: colors.green }}>
      <Grid item xs={12}>
        <ActiveTextEighteen style={{ color: "#fff" }}>
          Users Count
        </ActiveTextEighteen>

        {!loadingLiveUsers ? (
          <Fragment>
            <UserTypography>
              {liveUsers?.liveUser !== null || liveUsers?.liveUser !== ""
                ? liveUsers?.liveUser
                : "N.A"}{" "}
              {!!liveUsers.liveMessage && (
                <span style={{ color: "#fff", fontSize: "20px" }}>
                  <LinearProgressWithLabel value={liveUsers.liveMessage} />
                </span>
              )}
            </UserTypography>
          </Fragment>
        ) : (
          <Snap>
            <LinearProgressWithLabel value={"Fething Users"} />
          </Snap>
        )}
      </Grid>
    </OuterDiv>
  );

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ minWidth: 35, marginRight: "10px" }}>
          <Typography variant="body2" color="#fff">
            {props.value}
          </Typography>
        </Box>

        <Box sx={{ width: "30%", mr: 1 }}>
          <LinearProgress style={{ backgroundColor: "#fff" }} {...props} />
        </Box>
      </Box>
    );
  }
}
