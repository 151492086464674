import { useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components/macro";
import { CssBaseline } from "@mui/material";
import PerfectScrollBar from "react-perfect-scrollbar";

import GlobalStyle from "../components/GlobalStyle";
import Banner from "../assets/auth carousel pics/sigin.png";
import { renderUserInterface } from "../redux/slices/renderUISlice";

const Root = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const ScrollBar = styled(PerfectScrollBar)`
  flex: 1;
`;

const ImageDiv = styled.div`
  flex: 2 1 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-right: 0.5px solid #cbcbcb57;
`;

const MainDiv = styled.div`
  height: 100%;
`;

export default function AuthLayout() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(renderUserInterface());
  }, [dispatch]);

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />

      <ImageDiv id="gif">
        <img
          style={{ width: "80%", height: "80%" }}
          alt="banner"
          src={Banner}
        />
      </ImageDiv>

      <ScrollBar>
        <MainDiv>
          <Outlet />
        </MainDiv>
      </ScrollBar>
    </Root>
  );
}
