import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { DialogContent, Dialog, Grid, Typography, Button } from "@mui/material";

const Card = styled(Dialog)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export default function VerifyDialog({
  detail,
  setDetail,
  loginNavigation = false,
}) {
  const handleClose = () => {
    setDetail(false);
  };

  return (
    <Card
      open={detail}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "800px!important",
        },
      }}
    >
      <DialogContent>
        <Grid container spacing={3} style={{ padding: "20px" }}>
          <Grid item xs={12} marginBottom={"10px"}>
            <Typography variant="h4">{detail}</Typography>
          </Grid>

          {loginNavigation && (
            <Fragment>
              <Grid item xs />
              <Grid item>
                <Link to="/sign-in">
                  <Button variant="contained" color="primary">
                    Go To Login
                  </Button>
                </Link>
              </Grid>
            </Fragment>
          )}
        </Grid>
      </DialogContent>
    </Card>
  );
}
