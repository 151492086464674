import { Grid } from "@mui/material";

import { TextField } from "../../../components";

export default function FormTwo({ formData }) {
  return (
    <Grid item xs={6}>
      <TextField
        size="small"
        label="Company Address"
        placeholder="Company Address"
        name="companyAddress"
        multiline
        disabled
        value={formData.companyAddress}
      />
    </Grid>
  );
}
