import * as React from "react";
import { useMemo } from "react";
import { makeStyles } from "@mui/styles";

import SearchIcon from "@mui/icons-material/Search";
import { Paper, InputBase, IconButton } from "@mui/material";

const useStyles = makeStyles({
  input: {
    "&::placeholder": {
      fontWeight: 400,
      fontsize: "14px",
      lineHeight: "20px",
      color: "#000000",
      mixBlendMode: "normal",
      opacity: "0.4",
    },
  },
});

export default function Search({ searchKey, setPage, setSearchKey }) {
  const classes = useStyles();

  const handleDebounce = (func, delay) => {
    let debounceTimer;
    return (...args) => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedSetSearchKey = useMemo(
    () => handleDebounce(setSearchKey, 500),
    [handleDebounce, setSearchKey]
  );

  const handleChange = (e) => {
    setPage(1);
    debouncedSetSearchKey(e.target.value);
  };

  return (
    <Paper
      component="form"
      elevation={0}
      sx={{
        p: "15px 18px",
        display: "flex",
        alignItems: "center",
        width: "inherit",
        height: "50px",
        borderRadius: "10px",
        justifyContent: "space-between",
      }}
    >
      <InputBase
        fullWidth
        inputProps={{
          className: classes.input,
        }}
        placeholder="Search"
        onChange={handleChange}
      />
      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <SearchIcon style={{ color: "#231F20" }} />
      </IconButton>
    </Paper>
  );
}
