import * as Yup from "yup";
import { createYupSchema } from "./YupSchema";

export const audienceObject = {
  ID: "",
  brandID: "",
  additionalFields: [],
  percentage: {
    value: 0,
  },
  isEditable: true,
  audience_group: "",
  mandatoryTrueCount: "",
};

export function objectFromResponse(response, fields) {
  let data = {};
  data = {
    ...audienceObject,
  };

  let responseData = {};
  let additionalFields = [];
  for (let key of response.fields) {
    if (!key?.is_additional) {
      if (Array.isArray(Object.values(key)[0])) {
        data[Object.keys(key)[0]] = {
          value: Object.values(key)[0]?.length > 0 ? Object.values(key)[0] : [],
          mandatory: Object.values(key)[1] || false,
          userCount: Object.values(key)[3] || 0,
        };
      }

      if (typeof Object.values(key)[0] === "string") {
        data[Object.keys(key)[0]] = {
          value: !!Object.values(key)[0] ? Object.values(key)[0] : "",
          mandatory: Object.values(key)[1] || false,
          userCount: Object.values(key)[3] || 0,
        };
      }

      if (
        typeof Object.values(key)[0] === "object" &&
        !Array.isArray(Object.values(key)[0])
      ) {
        data[Object.keys(key)[0]] = {
          value: key[Object.keys(key)[0]],
          mandatory: Object.values(key)[1] || false,
          userCount: Object.values(key)[3] || 0,
        };
      }

      if (typeof Object.values(key)[0] === "number") {
        data[Object.keys(key)[0]] = {
          value: Object.values(key)[0] !== 0 ? Object.values(key)[0] : 0,
          mandatory: Object.values(key)[1] || false,
          userCount: Object.values(key)[3] || 0,
        };
      }
    } else {
      additionalFields.push({
        key: Object.keys(key)[0],
        value: Object.values(key)[0],
        mandatory: key.mandatory,
      });
    }
  }

  for (let field of fields) {
    if (field.control_id === 2 && field.multi_select) {
      responseData = {
        ...responseData,
        [field.name]: {
          value: data[field.name]?.value ? data[field.name]?.value : [],
          mandatory: data[field.name]?.mandatory
            ? data[field.name]?.mandatory
            : false,
          userCount: data[field.name]?.userCount,
        },
      };
    }

    if (field.control_id === 1) {
      responseData = {
        ...responseData,
        [field.name]: {
          value: data[field.name]?.value ? data[field.name]?.value : {},
          mandatory: data[field.name]?.mandatory
            ? data[field.name]?.mandatory
            : false,
          userCount: data[field.name]?.userCount,
        },
      };
    }

    if (field.control_id === 1) {
      responseData = {
        ...responseData,
        [field.name]: {
          value: data[field.name]?.value ? data[field.name]?.value : {},
          mandatory: data[field.name]?.mandatory
            ? data[field.name]?.mandatory
            : false,
          userCount: 0,
        },
      };
    }

    if (
      (field.control_id === 2 || field.control_id === 3) &&
      !field.multi_select
    ) {
      responseData = {
        ...responseData,
        [field.name]: {
          value: data[field.name]?.value ? data[field.name]?.value : "",
          mandatory: data[field.name]?.mandatory
            ? data[field.name]?.mandatory
            : false,
          userCount: data[field.name]?.userCount,
        },
      };
    }

    if (field.control_id === 4) {
      responseData = {
        ...responseData,
        [field.name]: {
          value: data[field.name]?.value ? data[field.name]?.value : null,
          mandatory: data[field.name]?.mandatory
            ? data[field.name]?.mandatory
            : false,
          userCount: data[field.name]?.userCount,
        },
      };
    }
  }
  return {
    ...responseData,
    percentage: data.percentage,
    ID: response.audience_id,
    brandID: response.brand_id,
    audience_group: data.audience_group,
    additionalFields: additionalFields,
    isEditable: response.is_editable,
  };
}

export function objectFromFormData(formData, details) {
  let fields = [];

  for (let field in formData) {
    if (
      field !== "ID" &&
      field !== "brandID" &&
      field !== "isEditable" &&
      field !== "additionalFields" &&
      field !== "dirty" &&
      field !== "mandatoryTrueCount"
    ) {
      if (formData[field]?.value !== null && formData[field] !== undefined) {
        fields.push({ [field]: formData[field] });
      }
    }
  }

  for (let addedFields of formData.additionalFields) {
    fields.push({
      [addedFields.key]: {
        value: addedFields.value,
        mandatory: addedFields.mandatory || false,
        is_additional: true,
      },
    });
  }

  return {
    brand_id: parseInt(details.activeBrand.ID),
    fields: fields,
  };
}

export function getAllAudiences(response) {
  let allAudiences = [];
  for (let key of response.data.audienceData) {
    let data = {};
    for (let fields of key.fields) {
      if (Array.isArray(Object.values(fields)[0])) {
        if (Object.values(fields)[0].length > 0) {
          data[Object.keys(fields)[0]] =
            Object.values(fields)[0].length > 0 ? Object.values(fields)[0] : [];
        }
      }

      if (typeof Object.values(fields)[0] === "string") {
        if (!!Object.values(fields)[0]) {
          data[Object.keys(fields)[0]] = !!Object.values(fields)[0]
            ? Object.values(fields)[0]
            : "";
        }
      }

      if (
        typeof Object.values(fields)[0] === "object" &&
        !Array.isArray(Object.values(fields)[0])
      ) {
        if (Object.keys(key)[0].length > 0) {
          data[Object.keys(fields)[0]] =
            Object.keys(key)[0].length > 0 ? Object.values(fields)[0] : {};
        }
      }
    }
    allAudiences.push({
      ...data,
      isEditable: key.is_editable,
      audience_id: key.audience_id,
    });
  }
  return allAudiences;
}

export function CreateValidationSchema(fields) {
  const yepSchema = fields.reduce(createYupSchema, {});

  const validationSchema = Yup.object().shape({
    additionalFields: Yup.array().of(
      Yup.object().shape({
        key: Yup.string().required("Additional Fields Key is required"),
        value: Yup.string().required("Additional Fields Value is required"),
      })
    ),
    audience_group: Yup.object().shape({
      value: Yup.string()
        .min(3, "Greater than 3 letters")
        .required("Audience Group is required"),
    }),
    mandatoryTrueCount: Yup.string().matches(
      /^[1-9]+$/,
      "One Field Need To Be Mandatory True"
    ),
    ...yepSchema,
  });

  return validationSchema;
}
