import { get } from "lodash";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { TbExternalLink } from "react-icons/tb";
import { Download, UploadFile } from "@mui/icons-material";
import { Fragment, useState, useRef } from "react";
import { InputAdornment, Button, IconButton } from "@mui/material";
import { RiErrorWarningLine, RiCheckboxCircleLine } from "react-icons/ri";

import TextField from "./TextField";
import { useAlert } from "../hooks";
import { uploadToS3, validateFileUpload } from "../utils";

const TextFieldWithCSS = styled(TextField)`
  * {
    ${(props) => {
      return { cursor: !props.disabled && "pointer" };
    }}
  }
`;

const Adornment = styled(InputAdornment)`
  margin-right: 0px;
`;

export default function FileUpload({
  folderPath,
  maxSize,
  bucket = {},
  allowedExtension = "*",
  formikProps,
  previewIcon,
  editState,
  downloadFile,
  link,
  ...props
}) {
  const uploadedFiles = useRef([]);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const { alert } = useAlert();

  const [uploadState, setUploadState] = useState("initial");

  return (
    <Fragment>
      <TextFieldWithCSS
        placeholder="No file chosen"
        InputProps={{
          readOnly: true,
          startAdornment: (
            <Adornment position="start">
              <Button
                size="small"
                variant="contained"
                disabled={props.disabled}
                startIcon={<UploadFile />}
                onClick={() => {
                  inputRef.current.click();
                }}
              >
                Upload
              </Button>
              {uploadState !== "initial" && (
                <Adornment position="start" style={{ padding: "0 5px" }}>
                  {uploadState === "success" ? (
                    <RiCheckboxCircleLine
                      size={20}
                      style={{ color: "green" }}
                    />
                  ) : (
                    <RiErrorWarningLine size={20} style={{ color: "red" }} />
                  )}
                </Adornment>
              )}
            </Adornment>
          ),
          endAdornment: (
            <div>
              {previewIcon && !!formikProps.values[props.name] && (
                <IconButton
                  onClick={() => linkOpen(formikProps.values[props.name])}
                >
                  <TbExternalLink />
                </IconButton>
              )}

              {downloadFile && !!link && (
                <IconButton
                  onClick={() => {
                    window.location.href = link;
                  }}
                >
                  <Download />
                </IconButton>
              )}
            </div>
          ),
        }}
        formikProps={formikProps}
        {...props}
      />

      <TextField
        type="file"
        inputRef={inputRef}
        onClick={handleFileClick}
        onChange={props.onChange || handleFileChange}
        style={{ display: "none" }}
        inputProps={{
          accept: allowedExtension,
        }}
      />
    </Fragment>
  );

  function handleFileClick() {
    formikProps.setFieldTouched(props.name, true);
  }

  function handleFileChange({ target }) {
    const file = get(target, "files[0]", "");
    let fileName = file?.name?.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, "");
    const existingFile = uploadedFiles.current.find(
      (uploadedFile) =>
        uploadedFile.name === file.name &&
        uploadedFile.size === file.size &&
        uploadedFile.lastModified === file.lastModified
    );

    if (!!existingFile) {
      formikProps.setFieldValue(props.name, existingFile.link);

      return;
    }

    const fileValidity = validateFileUpload({
      file,
      allowedExtension,
      maxSize: maxSize * 1024,
    });

    if (!fileValidity.status) {
      dispatch(alert({ type: "error", message: fileValidity.message }));
      formikProps.setFieldValue(props.name, "");
      setUploadState("failed");

      return;
    }

    const uploadBucket = {
      name: process.env.REACT_APP_S3_BUCKET_NAME,
      region: process.env.REACT_APP_S3_BUCKET_REGION,
      identityPoolID: process.env.REACT_APP_S3_IDENTITY_POOL_ID,
      ...bucket,
    };

    uploadToS3({
      bucket: uploadBucket,
      filePath: folderPath + fileName,
      file,
    }).then((response) => {
      if (response.status) {
        setUploadState("success");

        uploadedFiles.current.push({
          name: fileName,
          size: file.size,
          lastModified: file.lastModified,
          link:
            "https://" +
            uploadBucket.name +
            ".s3.ap-south-1.amazonaws.com/" +
            folderPath +
            fileName,
        });

        formikProps.setFieldValue(
          props.name,
          "https://" +
            uploadBucket.name +
            ".s3.ap-south-1.amazonaws.com/" +
            folderPath +
            fileName
        );

        dispatch(
          alert({ type: "success", message: "File Uploaded Successfully!" })
        );
      } else {
        dispatch(alert({ type: "error", message: "Error Uploading File." }));
        formikProps.setFieldValue(props.name, "");
        setUploadState("failed");
      }
    });
  }

  function linkOpen(val) {
    window.open(val, "_blank");
  }
}
