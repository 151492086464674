import { Navigation } from "swiper";
import { Add } from "@mui/icons-material";
import { Fragment, useState } from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import { MdEdit } from "react-icons/md";
import { Avatar, MenuItem, Typography } from "@mui/material";
import { MdOutlineCancel } from "react-icons/md";
import { FaRegQuestionCircle } from "react-icons/fa";
import { FaRegCheckCircle } from "react-icons/fa";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import pages from "../../../constants/pages";
import { TextField } from "../../../components";
import { signInMember, signOutMember } from "../../../redux/slices/memberSlice";
import {
  CancelButton,
  PrimaryButton,
  PrimaryLoadingButton,
} from "../../css components/Button";
import { colors } from "../../css components/Style";
import dashboardLayoutRoutes from "../../../router/router components/dashboardLayoutRoutes";

const Image = styled.div`
  margin-top: 20px;
  height: 180px;
  width: 180px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
`;

export default function SelectRole({
  businessEntityID,
  brands,
  authToken,
  userID,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const role =
    useSelector((state) => state?.memberDetails.role) ||
    sessionStorage.getItem("role");

  const agencyID =
    useSelector((state) => state?.memberDetails.agencyID) ||
    sessionStorage.getItem("agencyID");

  const { dashboardState, brandType, activeBrand } = useSelector(
    (state) => state.memberDetails
  );

  const [currentBrand, setCurrentBrand] = useState({
    ID: activeBrand.ID,
    name: activeBrand.name,
    type: activeBrand.brandType || brandType,
  });

  const [isBrandVerified, setBrandVerification] = useState();

  const [userDashboardState, setUserDashboardState] = useState(
    dashboardState === "NORMAL_USER" ? dashboardState : "monetization"
  );

  return (
    <Fragment>
      <div className="outer-brand-div">
        <div className="select-brand-div">
          {brands.length > 0 && (
            <Fragment>
              <Swiper
                slidesPerView={3}
                spaceBetween={1}
                navigation={true}
                modules={[Navigation]}
                className="mySwiper"
              >
                {brands.map((brand) => (
                  <Fragment>
                    <div
                      className="swiper-wrapper"
                      style={{
                        width: brands.length !== 1 && "fit-content",
                      }}
                    >
                      <SwiperSlide>
                        <Image
                          style={{
                            opacity:
                              (brand.isBrandVerified === 0 ||
                                brand.isBrandVerified === 2) &&
                              0.4,
                          }}
                          onClick={() => {
                            setBrand(
                              brand.ID,
                              brand.brandName,
                              brand.brandType
                            );
                            setBrandVerification(brand.isBrandVerified);
                          }}
                        >
                          <img
                            className="img-logo"
                            style={{
                              cursor: "pointer",
                              border:
                                currentBrand.ID === brand.ID &&
                                `1px solid ${colors.purple} `,
                            }}
                            src={brand.logo}
                            alt="logo"
                          />

                          <div className="outer-brand-status">
                            <div
                              style={{
                                background:
                                  brand.isBrandVerified === 0
                                    ? colors.warning
                                    : brand.isBrandVerified === 1
                                    ? colors.green
                                    : colors.error,
                              }}
                              className="brand-status"
                            >
                              {brand.isBrandVerified === 0 && (
                                <Fragment>
                                  <FaRegQuestionCircle
                                    color="#fff"
                                    fontSize={"15px"}
                                  />
                                  &nbsp;
                                  <span>{"Unverified"} </span>
                                </Fragment>
                              )}

                              {brand.isBrandVerified === 2 && (
                                <Fragment>
                                  <MdOutlineCancel
                                    color="#fff"
                                    fontSize={"15px"}
                                  />
                                  &nbsp;
                                  <span>{"Rejected"} </span>
                                </Fragment>
                              )}

                              {brand.isBrandVerified === 1 && (
                                <Fragment>
                                  <FaRegCheckCircle
                                    color="#fff"
                                    fontSize={"15px"}
                                  />
                                  &nbsp;
                                  <span>{"Approved"} </span>
                                </Fragment>
                              )}
                            </div>
                          </div>

                          <Typography className="brand-name">
                            {brand.brandName}
                          </Typography>
                        </Image>

                        {brand.isBrandVerified === 0 && (
                          <div
                            className="edit-icon"
                            onClick={() =>
                              navigate(
                                pages.brandDetailEmpty.route +
                                  "?brandID=" +
                                  brand.ID
                              )
                            }
                          >
                            <MdEdit fontSize={"15px"} color={colors.purple} />
                          </div>
                        )}
                      </SwiperSlide>
                    </div>
                  </Fragment>
                ))}
              </Swiper>

              <div className="choose-ds">
                <TextField
                  size="small"
                  select
                  placeholder="Select Role"
                  name="role"
                  value={userDashboardState}
                  onChange={setDashboardUser}
                  disabled={currentBrand.type !== 3}
                >
                  <MenuItem value={`promotion`}>I am Data Provider</MenuItem>
                  <MenuItem value={`monetization`}>I am Data Consumer</MenuItem>
                </TextField>
              </div>
            </Fragment>
          )}

          {brands.length === 0 && (
            <div onClick={() => addNewBrand()} className="add-new-brand">
              <Avatar
                style={{
                  background: colors.green,
                  margin: "auto",
                  width: "50px",
                  height: "50px",
                }}
              >
                <Add />
              </Avatar>
              <Typography
                style={{ textAlign: "center" }}
                variant="subtitle1"
                color={colors.green}
              >
                Add New Brand
              </Typography>
            </div>
          )}
        </div>
      </div>

      <div className="bottom-div">
        <div>
          <CancelButton
            style={{ float: "left" }}
            variant="outlined"
            onClick={() => dispatch(signOutMember())}
          >
            Logout
          </CancelButton>
        </div>

        <div style={{ gap: 5 }}>
          {agencyID && (
            <PrimaryButton
              style={{ float: "left", marginRight: "20px" }}
              variant="outlined"
              onClick={() => addNewBrand()}
            >
              Add New Brand
            </PrimaryButton>
          )}

          {agencyID && (
            <PrimaryButton
              style={{ float: "left", marginRight: "20px" }}
              variant="outlined"
              onClick={() => handleAgency()}
            >
              Agency Dashboard
            </PrimaryButton>
          )}

          {brands.length > 0 && (
            <PrimaryLoadingButton
              style={{ float: "right" }}
              type="submit"
              variant="contained"
              disabled={
                isBrandVerified === 2 ||
                isBrandVerified === 0 ||
                !currentBrand.ID
              }
              onClick={async () => {
                if (role === "AGENCY") {
                }
                sessionStorage.setItem("role", "NORMAL_USER");
                localStorage.setItem(
                  "dashboardState" + currentBrand.ID,
                  userDashboardState
                );
                await dispatch(
                  signInMember({
                    authToken: authToken,
                    userID: userID,
                    businessEntityId: businessEntityID,
                    brandType: currentBrand.type,
                  })
                );

                navigate(pages.brandDetails.route);
              }}
            >
              Let's Go
            </PrimaryLoadingButton>
          )}
        </div>
      </div>
    </Fragment>
  );

  async function addNewBrand() {
    localStorage.setItem("dashboardState" + currentBrand.ID, "monetization");
    navigate(pages.brandDetailCreateEmpty.route + "?newMultiBrand=" + true);
  }

  async function handleAgency() {
    sessionStorage.setItem("role", "AGENCY");
    await dispatch(
      signInMember({
        authToken: authToken,
        userID: userID,
        businessEntityId: businessEntityID,
        brandType: currentBrand.type,
      })
    );

    navigate(
      dashboardLayoutRoutes.agencyDetailsRoute.path + "?agencyID=" + agencyID
    );
  }

  function setBrand(ID, name, type) {
    if (type === 2) {
      setUserDashboardState("monetization");
    }
    if (type === 3) {
      setUserDashboardState("promotion");
    }

    setCurrentBrand({
      ID: ID,
      name: name,
      type: type,
    });
    sessionStorage.setItem("activeBrand", ID);
    sessionStorage.setItem("brandType", type);
  }

  function setDashboardUser(e) {
    setUserDashboardState(e.target.value);
  }
}
