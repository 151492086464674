import React from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { Add, Edit } from "@mui/icons-material";
import { Typography, Grid, Button } from "@mui/material";

export default function PageHeader({
  title = "",
  pageName,
  pageHeader = "",
  icons = [],
  breadcrumbs = [],
  addIcon = [],
  editIcon = [],
  buttons = [],
}) {
  const [buttonTitle, URL, createPermission] = addIcon;
  const [editState, setEditState, updatePermission] = editIcon;

  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Helmet title={title || pageName} />

      <Grid container alignItems="center">
        <Grid item xs={4}>
          <Typography variant="h3">{pageName}</Typography>

          {pageHeader && (
            <Typography
              variant="h5"
              style={{ opacity: "0.4", marginTop: "4px" }}
            >
              {pageHeader}
            </Typography>
          )}
        </Grid>

        <Grid
          item
          xs={8}
          container
          justifyContent="flex-end"
          style={{ gap: "5px" }}
        >
          {icons.length > 0 &&
            icons.map((icon, index) => (
              <Grid alignSelf={"center"} item key={index}>
                {icon}
              </Grid>
            ))}

          {buttons.length > 0 &&
            buttons.map((icon, index) => (
              <Grid alignSelf={"center"} item key={index}>
                {icon}
              </Grid>
            ))}

          {createPermission && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Add />}
                onClick={() => navigate(URL)}
              >
                {buttonTitle}
              </Button>
            </Grid>
          )}

          {!editState && updatePermission && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Edit />}
                onClick={() => setEditState((prevValue) => !prevValue)}
              >
                Edit
              </Button>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} style={{ marginBlock: "14px" }}></Grid>
      </Grid>
    </React.Fragment>
  );
}
