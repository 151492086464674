import { Formik } from "formik";
import { useEffect, useState } from "react";

import FormDisplay from "./FormDisplay";
import { useAxios } from "../../../hooks";

export default function Form({
  audiences,
  formData,
  takeUpOffer,
  setTakeUpOffer,
  brandDetails,
  setBrandDetails,
  audienceData,
  component,
  icon,
  categories,
  location,
  languages,
  rejectOffer,
  setRejectOffer,
  brandID,
  campaignID,
  userID,
  activeBrand,
}) {
  const axios = useAxios();

  const [liveUsers, setLiveUsers] = useState({
    liveUser: "",
    liveMessage: "",
    state: false,
  });
  const [loadingLiveUsers, setLoadingLiveUsers] = useState(false);

  useEffect(() => {
    if (formData.audienceID !== -1 && Boolean(formData.audienceID)) {
      setLoadingLiveUsers(true);
      axios({
        url: "/user/getUsersCount?v=1",
        method: "POST",
        disableRedirect: true,
        disableTimeOut: true,
        data: {
          sender_brandId: parseInt(brandID),
          sender_campaignId: parseInt(campaignID),
          receiver_userId: parseInt(userID),
          receiver_brandId: parseInt(activeBrand.ID),
        },
      }).then((response) => {
        if (response.status) {
          setLiveUsers({
            state: true,
            liveMessage: response.data.message,
            liveUser: response?.data.usersCount,
          });
          setLoadingLiveUsers(false);
        }
      });
    }
  }, [axios, brandID, campaignID, userID, activeBrand.ID, formData.audienceID]);

  return (
    <Formik enableReinitialize initialValues={formData} validateOnMount>
      {(formikProps) => (
        <FormDisplay
          userData={{
            sender_brandId: parseInt(brandID),
            sender_campaignId: parseInt(campaignID),
            receiver_userId: parseInt(userID),
            receiver_brandId: parseInt(activeBrand.ID),
          }}
          editState={false}
          categories={categories}
          location={location}
          languages={languages}
          icon={icon}
          liveUsers={liveUsers}
          formikProps={formikProps}
          audiences={audiences}
          component={component}
          takeUpOffer={takeUpOffer}
          setTakeUpOffer={setTakeUpOffer}
          brandDetails={brandDetails}
          setBrandDetails={setBrandDetails}
          audienceData={audienceData}
          rejectOffer={rejectOffer}
          setRejectOffer={setRejectOffer}
          setLiveUsers={setLiveUsers}
          loadingLiveUsers={loadingLiveUsers}
          setLoadingLiveUsers={setLoadingLiveUsers}
        />
      )}
    </Formik>
  );
}
