import { useParams } from "react-router-dom";
import {
  DialogContent,
  Dialog,
  Grid,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import {
  DraftsRounded,
  SendRounded,
  SmsFailedRounded,
  CheckCircleOutlineRounded,
  EmailRounded,
  HighlightOff,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";

import { useAxios } from "../../../hooks";
import convertDate from "./../../../utils/convertDate";

export default function BroadcastReportDialog({
  broadcastReportDialog,
  setBroadcastReportDialog,
}) {
  const axios = useAxios({ baseURL: "cpass_api" });

  const [broadcastReportData, setBroadcastReportData] = useState({});

  const params = useParams();

  const campaignID = params.campaignID || "";

  useEffect(() => {
    if (broadcastReportDialog) {
      axios({
        url: "/report/campaign/" + campaignID,
        Method: "GET",
      }).then((response) => {
        setBroadcastReportData({ ...response.data });
      });
    }
  }, [broadcastReportDialog, campaignID, axios]);

  const handleClose = () => {
    setBroadcastReportDialog(false);
  };

  return (
    <Dialog open={broadcastReportDialog} maxWidth={"lg"} onClose={handleClose}>
      <DialogContent>
        <Grid container item justifyContent={"space-between"}>
          <Typography variant="h3">Broadcast Report</Typography>

          <IconButton
            padding="0px"
            onClick={() => setBroadcastReportDialog(false)}
          >
            <HighlightOff />
          </IconButton>
        </Grid>

        <Divider sx={{ m: "20px 0px" }}></Divider>

        <Grid container display={"flex"} mb={"30px"}>
          <Grid
            item
            sx={{
              background: "white",
              padding: "35px",
              margin: "10px",
              borderRadius: "20px",
              textAlign: "center",

              boxShadow: "rgb(128 128 128 / 18%) 0px 0px 1px 5px ",
            }}
          >
            <SendRounded
              style={{
                fontSize: 50,
                background: "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)",
                color: "white",
                borderRadius: "10px",
                padding: "3px",
              }}
            ></SendRounded>

            <Typography
              variant="h3"
              textAlign={"center"}
              m={"25px 0px 6px 0px"}
            >
              {broadcastReportData.total_message_initiation
                ? broadcastReportData.total_message_initiation
                : "-"}
            </Typography>

            <Typography variant="h4"> Message Initiation</Typography>
          </Grid>
          <Grid
            item
            sx={{
              background: "white",
              padding: "35px",
              margin: "10px",
              borderRadius: "20px",
              textAlign: "center",

              boxShadow: "rgb(128 128 128 / 18%) 0px 0px 1px 5px ",
            }}
          >
            <EmailRounded
              style={{
                fontSize: 50,
                background:
                  "radial-gradient( circle 325px at 19.2% 64.8%,  rgba(254,62,101,1) 9.7%, rgba(166,24,146,1) 91.3% )",
                color: "white",
                borderRadius: "10px",
                padding: "3px",
              }}
            ></EmailRounded>

            <Typography
              variant="h3"
              textAlign={"center"}
              m={"25px 0px 6px 0px"}
            >
              {broadcastReportData.total_no_message_delivered
                ? broadcastReportData.total_no_message_delivered
                : "-"}
            </Typography>

            <Typography variant="h4"> Message Delivered</Typography>
          </Grid>

          <Grid
            item
            sx={{
              background: "white",
              padding: "35px",
              margin: "10px",
              borderRadius: "20px",
              textAlign: "center",
              boxShadow: "rgb(128 128 128 / 18%) 0px 0px 1px 5px ",
            }}
          >
            <SmsFailedRounded
              style={{
                fontSize: 50,
                background: "linear-gradient(147deg, #FFE53B 0%, #FF2525 74%)",
                color: "white",
                borderRadius: "10px",
                padding: "5px",
              }}
            ></SmsFailedRounded>

            <Typography
              variant="h3"
              textAlign={"center"}
              m={"25px 0px 6px 0px"}
            >
              {broadcastReportData?.total_no_message_failed}
            </Typography>

            <Typography variant="h4"> Message Failed</Typography>
          </Grid>

          <Grid
            item
            sx={{
              background: "white",
              padding: "35px",
              margin: "10px",
              borderRadius: "20px",
              textAlign: "center",

              boxShadow: "rgb(128 128 128 / 18%) 0px 0px 1px 5px ",
            }}
          >
            <DraftsRounded
              style={{
                fontSize: 50,
                background:
                  "linear-gradient( 183.5deg,  rgba(244,173,6,1) 18.6%, rgba(229,251,31,1) 119.9% )",
                color: "white",
                borderRadius: "10px",
                padding: "3px",
              }}
            ></DraftsRounded>

            <Typography
              variant="h3"
              textAlign={"center"}
              m={"25px 0px 6px 0px"}
            >
              {broadcastReportData.total_no_message_seen
                ? broadcastReportData.total_no_message_seen
                : "-"}
            </Typography>

            <Typography variant="h4"> Message Seen</Typography>
          </Grid>
          <Grid
            item
            sx={{
              background: "white",
              padding: "35px",
              margin: "10px",
              borderRadius: "20px",
              textAlign: "center",

              boxShadow: "rgb(128 128 128 / 18%) 0px 0px 1px 5px ",
            }}
          >
            <CheckCircleOutlineRounded
              style={{
                fontSize: 50,
                color: "#fff",
                background:
                  "radial-gradient( circle farthest-corner at 10% 20%,  rgba(14,174,87,1) 0%, rgba(12,116,117,1) 90% )",
                padding: "3px",
                borderRadius: "10px",
              }}
            ></CheckCircleOutlineRounded>

            <Typography
              variant="h3"
              textAlign={"center"}
              m={"25px 0px 6px 0px"}
            >
              {broadcastReportData.total_no_message_sent
                ? broadcastReportData.total_no_message_sent
                : "-"}
            </Typography>

            <Typography variant="h4"> Message Sent</Typography>
          </Grid>
        </Grid>

        <Grid
          container
          display={"flex"}
          justifyContent={"space-between"}
          p={"15px"}
        >
          <Grid item display={"flex"} xs={6}>
            <Grid>
              <Typography
                variant="h4"
                sx={{ mb: "20px", color: "rgb(128 128 128 / 92%)" }}
              >
                Campaign Name :
              </Typography>

              <Typography
                variant="h4"
                sx={{ mb: "20px", color: "rgb(128 128 128 / 92%)" }}
              >
                Status :
              </Typography>

              <Typography
                variant="h4"
                sx={{ mb: "20px", color: "rgb(128 128 128 / 92%)" }}
              >
                Scheduled Time :
              </Typography>

              <Typography
                variant="h4"
                sx={{ mb: "20px", color: "rgb(128 128 128 / 92%)" }}
              >
                Scheduled Date :
              </Typography>

              <Typography
                variant="h4"
                sx={{ mb: "20px", color: "rgb(128 128 128 / 92%)" }}
              >
                Created AT :
              </Typography>
            </Grid>

            <Grid>
              <Typography variant="h4" sx={{ mb: "20px", ml: "30px" }}>
                {broadcastReportData.campaign_name
                  ? broadcastReportData.campaign_name
                  : "N.A"}
              </Typography>

              <Typography
                variant="h4"
                sx={{
                  mb: "20px",
                  ml: "30px",
                  border: "1px solid green",
                  color: " #006c00",
                  padding: " 5px 15px 5px 15px",
                  background: "#0080001f",
                  borderRadius: "10px",
                  textAlign: "center",
                }}
              >
                {broadcastReportData?.status
                  ? broadcastReportData?.status
                  : "N.A"}
              </Typography>

              <Typography variant="h4" sx={{ mb: "20px", ml: "30px" }}>
                {broadcastReportData.scheduled_time
                  ? broadcastReportData.scheduled_time
                  : "N.A"}
              </Typography>

              <Typography variant="h4" sx={{ mb: "20px", ml: "30px" }}>
                {broadcastReportData.scheduled_date
                  ? broadcastReportData.scheduled_date
                  : "N.A"}
              </Typography>

              <Typography variant="h4" sx={{ mb: "20px", ml: "30px" }}>
                {broadcastReportData.created_at
                  ? broadcastReportData.created_at
                  : "N.A"}
              </Typography>
            </Grid>
          </Grid>

          <Grid item display={"flex"} xs={6}>
            <Grid>
              <Typography
                variant="h4"
                sx={{ mb: "20px", color: "rgb(128 128 128 / 92%)" }}
              >
                Template ID :
              </Typography>

              <Typography
                variant="h4"
                sx={{ mb: "20px", color: "rgb(128 128 128 / 92%)" }}
              >
                Template Name :
              </Typography>

              <Typography
                variant="h4"
                sx={{ mb: "20px", color: "rgb(128 128 128 / 92%)" }}
              >
                Broadcast Url :
              </Typography>

              <Typography
                variant="h4"
                sx={{ mb: "20px", color: "rgb(128 128 128 / 92%)" }}
              >
                Created BY :
              </Typography>

              <Typography
                variant="h4"
                sx={{ mb: "20px", color: "rgb(128 128 128 / 92%)" }}
              >
                Updated BY :
              </Typography>
            </Grid>

            <Grid>
              <Typography variant="h4" sx={{ mb: "20px", ml: "30px" }}>
                {broadcastReportData.template_id
                  ? broadcastReportData.template_id
                  : "N.A"}
              </Typography>

              <Typography variant="h4" sx={{ mb: "20px", ml: "30px" }}>
                {broadcastReportData.template_name
                  ? broadcastReportData.template_name
                  : "N.A"}
              </Typography>

              <Typography variant="h4" sx={{ mb: "20px", ml: "30px" }}>
                {broadcastReportData.broadcast_url
                  ? broadcastReportData.broadcast_url
                  : "N.A"}
              </Typography>

              <Typography variant="h4" sx={{ mb: "20px", ml: "30px" }}>
                {broadcastReportData.created_by
                  ? convertDate(broadcastReportData.created_by)
                  : "N.A"}
              </Typography>

              <Typography variant="h4" sx={{ mb: "20px", ml: "30px" }}>
                {broadcastReportData.updated_by
                  ? convertDate(broadcastReportData.updated_by)
                  : "N.A"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
