import React from "react";
import { Grid, Button, IconButton, Tooltip } from "@mui/material";

import { TextField } from "../../../../components";
import { DeleteOutline } from "@mui/icons-material";

export default function QuickReply({ formikProps, addSample }) {
  var buttons = {
    text: "",
  };

  const handleRemoveButtons = (index) => {
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      ...prevVal.templateButtons?.buttons?.splice(index, 1),
    }));
  };

  const addButton = () => {
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      templateButtons: addTemplateButtons(prevVal.templateButtons),
    }));
    function addTemplateButtons(templateButtons) {
      if (Boolean(templateButtons.buttons)) {
        templateButtons.buttons.push(buttons);
        return templateButtons;
      } else {
        templateButtons.buttons = [];
        templateButtons.buttons.push(buttons);
        return templateButtons;
      }
    }
  };

  return (
    <Grid container spacing={4}>
      {formikProps.values?.templateButtons?.buttons?.map((button, index) => (
        <React.Fragment key={index}>
          <Grid item xs={5}>
            <TextField
              name={"templateButtons.buttons[" + index + "].text"}
              label="Button Text"
              formikProps={formikProps}
              disabled={Boolean(formikProps.values.ID) || Boolean(addSample)}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name={"templateButtons.buttons[" + index + "].payload"}
              label="Button Payload"
              formikProps={formikProps}
              disabled={Boolean(formikProps.values.ID) || Boolean(addSample)}
            />
          </Grid>

          <Grid textAlign={"end"} item xs={1}>
            <Tooltip title="Delete button">
              <IconButton
                disabled={Boolean(formikProps.values.ID) || Boolean(addSample)}
                onClick={() => handleRemoveButtons(index)}
                style={{ color: "#ff0000" }}
              >
                <DeleteOutline />
              </IconButton>
            </Tooltip>
          </Grid>
        </React.Fragment>
      ))}

      {Boolean(formikProps.values?.templateButtons?.type) &&
        !Boolean(formikProps.values.ID) &&
        formikProps.values.templateButtons?.buttons?.length <= 2 && (
          <React.Fragment>
            <Grid item xs={10} />
            <Grid textAlign={"end"} item xs={2}>
              <Button
                disabled={Boolean(formikProps.values.ID) || Boolean(addSample)}
                variant="contained"
                size="small"
                onClick={addButton}
              >
                Add Buttons
              </Button>
            </Grid>
          </React.Fragment>
        )}
    </Grid>
  );
}
