import { Fragment } from "react";
import { Grid, IconButton, Tooltip } from "@mui/material";

import Input from "./Inputs";
import SingleDropdown from "./DropDown";
import MultiDropdown from "./MultiDropdown";
import TextFieldNumber from "./TextFieldNumber";
import { RemoveCircle } from "@mui/icons-material";

export default function Fields({
  fields,
  fieldValue,
  setFieldValue,
  editState,
  state,
  dispatch,
  message,
}) {
  return (
    <Fragment>
      {fields.map((field, index) => (
        <Fragment key={field.id}>
          <Grid item xs={2}>
            <Input
              dispatch={dispatch}
              state={state}
              field={field}
              editState={editState}
            />
          </Grid>

          <Grid item xs={2}>
            <SingleDropdown
              state={state}
              field={field}
              setFieldValue={setFieldValue}
              fieldValue={fieldValue}
              editState={editState}
              dispatch={dispatch}
            />
          </Grid>

          <Grid item xs={3}>
            <MultiDropdown
              dispatch={dispatch}
              state={state}
              field={field}
              setFieldValue={setFieldValue}
              fieldValue={fieldValue}
              editState={editState}
            />
          </Grid>

          <TextFieldNumber
            message={message}
            dispatch={dispatch}
            state={state}
            index={index}
            field={field}
            editState={editState}
          />

          {editState && (
            <Grid item xs={1}>
              <Tooltip title="Clear Values">
                <IconButton
                  onClick={() => {
                    dispatch({
                      type: "CLEAR_VALUES",
                      payload: { name: field.field_name },
                    });
                  }}
                >
                  <RemoveCircle />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Fragment>
      ))}
    </Fragment>
  );
}
