import { Fragment } from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";

import pages from "../../../constants/pages";
import { colors } from "../../css components/Style";
import Collapse from "../../../assets/auth carousel pics/audiece_target.png";

const OuterDiv = styled.div`
  background: ${colors.green};
  border-radius: 10px;
  padding: 20px 20px;
`;

export default function ExploreConnection({ formikProps }) {
  const navigate = useNavigate();

  return (
    <OuterDiv>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className="explore-connection-text">
            Get connected with partners who drive higher conversions with
            targeted audience
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <div
            className="explore-connection-button"
            onClick={() =>
              navigate(
                pages.exploreConnections.route +
                  "?campaignID=" +
                  formikProps.values.campaignID
              )
            }
          >
            Explore Connections
          </div>
        </Grid>

        <Grid item xs={6} textAlign="end">
          <img
            className="explore-connection-image"
            src={Collapse}
            alt="!"
          ></img>
        </Grid>
      </Grid>
    </OuterDiv>
  );
}
