import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useState, useEffect, Fragment } from "react";

import CampaignStatus from "../create campaign components/Status";
import { ButtonFooter, PageHeader, Paper } from "../../../components";
import TakeUpDialog from "../take up offer components/TakeUpDailog";
import RejectOfferDialog from "../reject offer components/RejectOfferDialog";
import CampaignLiveUser from "../create campaign components/CampaignLiveUser";
import ExploreConnection from "../create campaign components/ExploreConnection";
import BrandDetailsDialog from "../brand details components/BrandDetailsDialog";
import CampaignButtons from "../create campaign components/CreateCampaignButtons";
import FormEighth from "../create campaign components/campaign form components/SelectCampaignChannel";
import FormOne from "../create campaign components/campaign form components/CreateCampaignFirstForm";
import FormTwo from "../create campaign components/campaign form components/CreateCampaignSecondForm";
import FormThird from "../create campaign components/campaign form components/CreateCampaignThirdForm";
import FormFifth from "../create campaign components/campaign form components/CreateCampaignFifthForm";
import FormSeventh from "../create campaign components/campaign form components/PreferredCampaignChannel";
import { CancelButton } from "../../css components/Button";
import { useNavigate } from "react-router-dom";

export default function FormDisplay({
  audiences,
  editState,
  liveUsers,
  categories,
  rejectOffer,
  formikProps,
  takeUpOffer,
  audienceData,
  setEditState,
  audienceSize,
  component = "",
  brandDetails,
  setTakeUpOffer,
  setBrandDetails,
  setRejectOffer,
  setAudienceData,
  setAudienceCreated,
  preferredChannels,
  loadingLiveUsers,
  userData,
  setLiveUsers,
  setLoadingLiveUsers,
}) {
  const navigate = useNavigate();

  const [brandIdentity, setBrandIdentity] = useState(false);

  const { activeBrand } = useSelector((state) => state.memberDetails);

  useEffect(() => {
    if (formikProps.values.brandID) {
      if (parseInt(formikProps.values.brandID) !== parseInt(activeBrand.ID)) {
        setBrandIdentity(true);
      }
    }
  }, [formikProps.values.brandID, activeBrand.ID]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PageHeader
          pageName={"Campaigns"}
          pageHeader={
            component === "" ? "Let's create campaign" : "Campaign Details"
          }
          buttons={[
            <CancelButton
              variant="outlined"
              style={{ marginRight: "15px" }}
              onClick={() => navigate(-1)}
            >
              Back
            </CancelButton>,
            <CampaignButtons
              ID={formikProps.values.campaignID}
              editState={editState}
              liveUsers={liveUsers}
              setEditState={setEditState}
              formikProps={formikProps}
              component={component}
              brandIdentity={brandIdentity}
              setTakeUpOffer={setTakeUpOffer}
            />,
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2} direction="row" alignItems="stretch">
          {component !== "" && (
            <Grid item xs={6}>
              <CampaignStatus
                formikProps={formikProps}
                setTakeUpOffer={setTakeUpOffer}
                liveUsers={liveUsers}
              />
            </Grid>
          )}

          {brandIdentity && (
            <Grid item xs={6}>
              <CampaignLiveUser
                userData={userData}
                liveUsers={liveUsers}
                component={component}
                takeUpOffer={takeUpOffer}
                setLiveUsers={setLiveUsers}
                loadingLiveUsers={loadingLiveUsers}
                setLoadingLiveUsers={setLoadingLiveUsers}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      {/* {component === "" && <Connections connections={connectionList} />} */}

      <Grid item xs={12}>
        <Paper gridSpacing={6}>
          <FormOne
            categories={categories}
            brandDetails={brandDetails}
            setBrandDetails={setBrandDetails}
            formikProps={formikProps}
            editState={editState}
            component={component}
            brandIdentity={brandIdentity}
          />
        </Paper>

        <Paper gridSpacing={6}>
          <FormTwo
            setAudienceData={setAudienceData}
            formikProps={formikProps}
            audiences={audiences}
            editState={editState}
            component={component}
            brandIdentity={brandIdentity}
            audienceData={audienceData}
            setAudienceCreated={setAudienceCreated}
          />
        </Paper>

        <Paper gridSpacing={6}>
          <FormThird
            brandIdentity={brandIdentity}
            formikProps={formikProps}
            editState={editState}
            component={component}
          />
        </Paper>

        {component !== "request" && (
          <Paper gridSpacing={6}>
            <FormSeventh
              editState={editState}
              formikProps={formikProps}
              preferredChannels={preferredChannels}
            />

            {formikProps.values?.pref_campaign_channel?.value?.length !== 0 &&
              formikProps.values.pref_campaign_channel.value.includes(1) && (
                <FormEighth formikProps={formikProps} editState={editState} />
              )}
          </Paper>
        )}

        {editState && (
          <Grid item xs={12}>
            <ButtonFooter
              ID={formikProps.values.campaignID}
              editState={editState}
              setEditState={setEditState}
              formikProps={formikProps}
            />
          </Grid>
        )}

        {formikProps.values.campaignID && (
          <Fragment>
            <Paper gridSpacing={6}>
              <FormFifth
                formikProps={formikProps}
                editState={editState}
                component={component}
                brandIdentity={brandIdentity}
              />
            </Paper>

            <Grid item xs={7}>
              {component !== "request" && component !== "accepted" && (
                <ExploreConnection formikProps={formikProps} />
              )}
            </Grid>
          </Fragment>
        )}
      </Grid>

      <RejectOfferDialog reject={rejectOffer} setReject={setRejectOffer} />

      <TakeUpDialog
        takeUp={takeUpOffer}
        formikProps={formikProps}
        setTakeUp={setTakeUpOffer}
      />

      <BrandDetailsDialog details={brandDetails} setDetails={setBrandDetails} />
    </Grid>
  );
}
