/*
Contains all the routes pertaining to Dashboard Layout
Use the following structure: 
export const {name}Route = {
  ID: "",
  path: "/",
  element: "",
  index: true, (if the path is default for some folder)
  disableAuth: true, (if the page should always be in the dashboard)
}
*/

import pages from "../../constants/pages";
import React from "react";

//list routes

import TemplateList from "../../pages/template/TemplateList";
import UsersList from "../../pages/users components/UsersList";
import Connection from "../../pages/connection components/Connection";
import AudienceList from "../../pages/audience components/AudienceList";
import SuperAdminUsers from "../../pages/superAdmin user components/UserList";
import SuperAdminBrand from "../../pages/superAdmin brand components/BrandList";
import Campaign from "../../pages/campaign components/campaign list parent components/Campaign";
import CampaignDP from "../../pages/campaign components/campaign list parent components/CampaignDP";
import OnBoardedConnectionList from "../../pages/superAdmin dp onboarding components/OnboardedConnectionList";
import SuperAdminAllCampaigns from "../../pages/campaign components/campaign list parent components/SuperAdminAllCampaigns";
import SuperAdminBrandCampaignList from "../../pages/campaign components/campaign list parent components/SuperAdminBrandCampaignList";

//details routes

import TemplateDetails from "../../pages/template/TemplateDetails";
import BrandDetails from "../../pages/brand components/BrandDetails";
import UserDetails from "../../pages/superAdmin user components/UsersDetails";
import AudienceDetails from "../../pages/audience components/AudienceDetails";
import DpOnBoardingDetails from "../../pages/superAdmin dp onboarding components/OnboardingDetails";
import CampaignDetails from "../../pages/campaign components/create campaign components/CampaignDetails";
import CampaignRequestDetails from "../../pages/campaign components/campaign request components/CampaignRequestDetails";
import SuperadminCampaignDetails from "../../pages/campaign components/superadmin campaign details component/SuperAdminCampaignDetails";

//other routes
import Credit from "../../pages/credit components/Credit";
import ChangePassword from "../../pages/change password components/ChangePassword";
import CompanyDetails from "../../pages/company components/CompanyDetails";
import Profile from "../../pages/profile components/Profile";
import UnverifiedBrandList from "../../pages/unverified brands component/UnverifiedBrandList";
import UnverifiedBrandDetails from "../../pages/unverified brands component/UnverifiedBrandDetails";
import UnverifiedCompanyDetails from "../../pages/unverified brands component/UnverifiedCompanyDetails";

// import async from "./Async";
//For async const Component = async(() => import ("../component location"));
import WhatsappOnboarding from "./../../pages/whatsapp onboarding components/WhatsappOnboarding";
import BotDetails from "../../pages/whatsapp onboarding components/onboarding bot details components/BotDetails";
import PermissionSettings from "../../pages/permission component/PermissionSettings";
import PushStats from "../../pages/superadmin push stats components/PushStats";
import CampaignAnalytics from "../../pages/campaign components/campaign analytics components/CampaignAnalytics";
import DynamicUI from "../../pages/superadmin dynamic ui components/DynamicUI";
import UnverifiedAgenciesList from "../../pages/superadmin unverified agencies/UnverifiedAgencies";
import UnverifiedAgenciesDetails from "../../pages/superadmin unverified agencies/UnverifiedAgenciesDetails";
import UnverifiedCompanyAgencyDetails from "../../pages/superadmin unverified agencies/UnverifiedCompanyDetails";
import AgencyDetails from "../../pages/agency components/Agency";
// const DpOnBoardingDetails = React.lazy(() =>
//   import("../../pages/superAdmin dp onboarding components/OnboardingDetails")
// );

//Main Module Components

const dashboardLayoutRoutes = {
  brandDetailsRoute: {
    ID: pages.brandDetails.ID,
    path: pages.brandDetails.route,
    role: ["monetization", "promotion"],
    element: BrandDetails,
  },

  analyticsRoute: {
    ID: pages.analytics.ID,
    path: pages.analytics.route + ":campaignID",
    role: ["monetization"],
    element: CampaignAnalytics,
  },

  profileDetailsRoute: {
    ID: pages.profilePage.ID,
    path: pages.profilePage.route,
    role: ["monetization", "promotion", "AGENCY"],
    element: Profile,
  },

  companyDetailsRoute: {
    ID: pages.companyDetails.ID,
    path: pages.companyDetails.route,
    role: ["AGENCY", "monetization", "promotion"],
    element: CompanyDetails,
  },

  createCampaignRoute: {
    ID: pages.createCampaign.ID,
    path: pages.createCampaign.route,
    role: ["monetization"],
    element: CampaignDetails,
  },

  myCampaignRoute: {
    ID: pages.myCampaign.ID,
    path: pages.myCampaign.route,
    element: Campaign,
    role: ["SUPER_ADMIN", "monetization", "promotion"],
  },

  campaignRequestRoute: {
    ID: pages.campaignRequest.ID,
    path: pages.campaignRequest.route,
    role: ["promotion"],
    element: CampaignDP,
  },

  campaignDetailsRoute: {
    ID: pages.campaignDetails.ID,
    path: pages.campaignDetails.route + ":campaignID",
    element: CampaignDetails,
    role: ["monetization", "promotion"],
  },

  campaignRequestDetailsRoute: {
    ID: pages.campaignRequestDetails.ID,
    path: pages.campaignRequestDetails.route + ":campaignID",
    element: CampaignRequestDetails,
    role: ["promotion"],
  },

  campaignAcceptedDetailsRoute: {
    ID: pages.campaignAcceptedDetails.ID,
    path: pages.campaignAcceptedDetails.route + ":campaignID",
    role: ["promotion"],
    element: CampaignRequestDetails,
  },

  audienceListRoute: {
    ID: pages.audienceList.ID,
    path: pages.audienceList.route,
    element: AudienceList,
    role: ["monetization"],
  },

  createAudienceRoute: {
    ID: pages.createAudience.ID,
    path: pages.createAudience.route,
    element: AudienceDetails,
    role: ["monetization"],
  },

  audienceDetailsRoute: {
    ID: pages.audienceDetails.ID,
    path: pages.audienceDetails.route + ":audienceID",
    element: AudienceDetails,
    role: ["monetization"],
  },

  exploreConnectionRoute: {
    ID: pages.exploreConnections.ID,
    path: pages.exploreConnections.route,
    element: Connection,
    role: ["monetization"],
  },

  changePasswordRoute: {
    ID: pages.changePassword.ID,
    path: pages.changePassword.route,
    element: ChangePassword,
    role: ["monetization", "promotion", "AGENCY"],
  },

  templateListRoute: {
    ID: pages.templateList.ID,
    path: pages.templateList.route,
    element: TemplateList,
    role: ["SUPER_ADMIN", "monetization", "promotion"],
  },

  templateCreateRoute: {
    ID: pages.createTemplate.ID,
    path: pages.createTemplate.route,
    element: TemplateDetails,
    role: ["SUPER_ADMIN", "monetization", "promotion"],
  },

  templateDetailsRoute: {
    ID: pages.templateDetails.ID,
    path: pages.templateDetails.route + ":templateID",
    element: TemplateDetails,
    role: ["SUPER_ADMIN", "monetization", "promotion"],
  },

  creditRoute: {
    ID: pages.creditPage.ID,
    path: pages.creditPage.route,
    element: Credit,
    role: ["monetization"],
  },

  // superadmin routes

  unverifiedRoute: {
    ID: pages.unverifiedPage.ID,
    path: pages.unverifiedPage.route,
    element: UnverifiedBrandList,
    role: ["SUPER_ADMIN"],
  },

  unverifiedAgenciesRoute: {
    ID: pages.unverifiedAgenciesList.ID,
    path: pages.unverifiedAgenciesList.route,
    element: UnverifiedAgenciesList,
    role: ["SUPER_ADMIN"],
  },

  unverifiedAgenciesDetailsRoute: {
    ID: pages.unverifiedAgenciesDetails.ID,
    path: pages.unverifiedAgenciesDetails.route,
    element: UnverifiedAgenciesDetails,
    role: ["SUPER_ADMIN"],
  },

  unverifiedAgencyCompanyDetailsRoute: {
    ID: pages.unverifiedCompanyAgencyDetails.ID,
    path: pages.unverifiedCompanyAgencyDetails.route,
    element: UnverifiedCompanyAgencyDetails,
    role: ["SUPER_ADMIN"],
  },

  addCampaignStatsRoute: {
    ID: pages.addCampaignStats.ID,
    path: pages.addCampaignStats.route + ":campaignID",
    element: PushStats,
    role: ["SUPER_ADMIN"],
  },

  unverifiedDetailsRoute: {
    ID: pages.unverifiedPageDetails.ID,
    path: pages.unverifiedPageDetails.route,
    element: UnverifiedBrandDetails,
    role: ["SUPER_ADMIN"],
  },

  unverifiedCompanyDetailsRoute: {
    ID: pages.unverifiedCompanyDetails.ID,
    path: pages.unverifiedCompanyDetails.route,
    element: UnverifiedCompanyDetails,
    role: ["SUPER_ADMIN"],
  },

  brandListRoute: {
    ID: pages.brandsList.ID,
    path: pages.brandsList.route,
    element: SuperAdminBrand,
    role: ["SUPER_ADMIN"],
  },

  userDetailsRoute: {
    ID: pages.userDetails.ID,
    path: pages.userDetails.route + ":userID",
    element: UserDetails,
    role: ["SUPER_ADMIN"],
  },

  userCreateRoute: {
    ID: pages.createUser.ID,
    path: pages.createUser.route,
    element: UserDetails,
    role: ["SUPER_ADMIN"],
  },

  userListRoute: {
    ID: pages.usersList.ID,
    path: pages.usersList.route + ":brandID",
    element: SuperAdminUsers,
    role: ["SUPER_ADMIN"],
  },

  accountVerificationRoute: {
    ID: pages.accountVerification.ID,
    path: pages.accountVerification.route,
    element: UsersList,
    role: ["SUPER_ADMIN"],
  },

  superadminBrandCampaignsListRoute: {
    ID: pages.superAdminBrandCampaigns.ID,
    path: pages.superAdminBrandCampaigns.route + ":brandID",
    element: SuperAdminBrandCampaignList,
    role: ["SUPER_ADMIN"],
  },

  superadminBrandCampaignsDetailsRoute: {
    ID: pages.superAdminCampaignDetails.ID,
    path: pages.superAdminCampaignDetails.route + ":campaignID",
    element: SuperadminCampaignDetails,
    role: ["SUPER_ADMIN"],
  },

  superadminAllCampaignsRoute: {
    ID: pages.superAdminAllCampaigns.ID,
    path: pages.superAdminAllCampaigns.route,
    element: SuperAdminAllCampaigns,
    role: ["SUPER_ADMIN"],
  },

  superadminDPOnBoardingDetailsRoute: {
    ID: pages.dpOnBoardingDetails.ID,
    path: pages.dpOnBoardingDetails.route + ":connectionID",
    element: DpOnBoardingDetails,
    role: ["SUPER_ADMIN"],
  },

  superadminDPOnBoardingCreateRoute: {
    ID: pages.dpOnBoarding.ID,
    path: pages.dpOnBoarding.route,
    element: DpOnBoardingDetails,
    role: ["SUPER_ADMIN"],
  },

  superadminDPOnBoardingListRoute: {
    ID: pages.dpOnBoardingList.ID,
    path: pages.dpOnBoardingList.route,
    element: OnBoardedConnectionList,
    role: ["SUPER_ADMIN"],
  },

  superadminPermissionSettingsRoute: {
    ID: pages.permissionSettingsPage.ID,
    path: pages.permissionSettingsPage.route,
    element: PermissionSettings,
    role: ["SUPER_ADMIN"],
  },

  superadminDynamicUISetting: {
    ID: pages.dynamicUiPageDetails.ID,
    path: pages.dynamicUiPageDetails.route,
    element: DynamicUI,
    role: ["SUPER_ADMIN"],
  },

  whatsAppOnboardingPageRoute: {
    ID: pages.whatsAppOnboardingPage.ID,
    path: pages.whatsAppOnboardingPage.route,
    element: WhatsappOnboarding,
    role: ["monetization"],
  },

  botDetailsPageRoute: {
    ID: pages.botDetailsPage.ID,
    path: pages.botDetailsPage.route + ":botID",
    element: BotDetails,
    role: ["monetization"],
  },

  // Agency

  agencyDetailsRoute: {
    ID: pages.agencyDashboardDetailPage.ID,
    path: pages.agencyDashboardDetailPage.route,
    element: AgencyDetails,
    role: ["AGENCY"],
  },
};

export default dashboardLayoutRoutes;
