import { Formik } from "formik";

import FormDisplay from "./FormDisplay";
import { validationSchema } from "../../brand components/brand details components/brandDetailObject";
import { useSelector } from "react-redux";

export default function Form({
  formData,
  editState,
  setEditState,
  handleClose,
}) {
  const { languages, location, categories } = useSelector(
    (state) => state.memberDetails
  );

  return (
    <Formik
      enableReinitialize
      initialValues={formData.data}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {}}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit}>
          <FormDisplay
            handleClose={handleClose}
            languages={languages}
            location={location}
            categories={categories}
            formikProps={formikProps}
            editState={editState}
            setEditState={setEditState}
          />
        </form>
      )}
    </Formik>
  );
}
