import { useState } from "react";
import { Visibility } from "@mui/icons-material";
import { Grid, InputAdornment, IconButton } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import {
  Paper,
  TextField,
  PageHeader,
  ComponentHeader,
  ButtonFooter,
} from "../../components";
import { CancelButton, PrimaryLoadingButton } from "../css components/Button";
import { useNavigate } from "react-router-dom";

export default function FormDisplay({ formikProps, userID }) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PageHeader
          pageName={"Change Password"}
          pageHeader="Setup your new password"
        />
      </Grid>

      <Grid item xs={12}>
        <Paper gridSpacing={6}>
          <Grid item xs={12}>
            <ComponentHeader title={"Change Password"} />
          </Grid>

          <Grid item xs={4}>
            <TextField
              required
              type={showPassword ? "text" : "password"}
              name="oldPassword"
              formikProps={formikProps}
              placeholder="Old password"
              label="Old password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {!showPassword ? <Visibility /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              required
              type={newPassword ? "text" : "password"}
              name="newPassword"
              label="New Password"
              placeholder="New Password"
              formikProps={formikProps}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={() => setNewPassword(!newPassword)}
                    >
                      {!newPassword ? <Visibility /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              required
              type={confirmPassword ? "text" : "password"}
              name="confirmPassword"
              label="Confirm Password"
              placeholder="Confirm Password"
              formikProps={formikProps}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={() => setConfirmPassword(!confirmPassword)}
                    >
                      {!confirmPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper>
          <Grid item xs />
          <Grid item>
            <PrimaryLoadingButton
              type="submit"
              variant="contained"
              fullWidth
              onClick={formikProps.handleSubmit}
              disabled={!formikProps.isValid}
              loading={formikProps.isSubmitting}
              color="primary"
            >
              Change Password
            </PrimaryLoadingButton>
          </Grid>

          <Grid item>
            <CancelButton
              variant="outlined"
              disabled={formikProps.isSubmitting}
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </CancelButton>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
