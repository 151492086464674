import { Fragment } from "react";
import { Grid } from "@mui/material";

import { ComponentHeader, TextField } from "../../../../components";
import FormFourth from "./CreateCampaignFouthForm";

export default function FormThird({ formikProps, editState, component }) {
  return (
    <Fragment>
      <Grid item xs={12}>
        <ComponentHeader
          title={"Budget & Payout"}
          subtitle={
            component === ""
              ? "Choose the budget and conversion type for the campaign."
              : "Budget and conversion type for the campaign"
          }
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          required
          label="Minimum Budget"
          name="budget.value.min"
          formikProps={formikProps}
          disabled={!editState}
          placeholder="Minimum budget"
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          required
          label="Maximum Budget"
          name="budget.value.max"
          formikProps={formikProps}
          disabled={!editState}
          placeholder="Maximum budget"
        />
      </Grid>

      <FormFourth formikProps={formikProps} editState={editState} />
    </Fragment>
  );
}
