export default function convertDate(unixTimeStamp) {
  var timeStamp = new Date(unixTimeStamp * 1000);

  if (!(timeStamp > 0)) {
    return null;
  }

  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = timeStamp.getFullYear();
  var month = months[timeStamp.getMonth()];

  var day =
    timeStamp.getDate() < 10 ? "0" + timeStamp.getDate() : timeStamp.getDate();

  var hour =
    timeStamp.getHours() < 10
      ? "0" + timeStamp.getHours()
      : timeStamp.getHours();

  var min =
    timeStamp.getMinutes() < 10
      ? "0" + timeStamp.getMinutes()
      : timeStamp.getMinutes();

  var time = day + " " + month + " " + year + " | " + hour + ":" + min;

  return time;
}
