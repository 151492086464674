import styled from "styled-components/macro";
import { HighlightOff } from "@mui/icons-material";
import { Avatar, Grid, IconButton, Typography } from "@mui/material";

import {
  ComponentHeader,
  TextField,
  AutocompleteMultiSelect,
} from "../../../components";
import { colors } from "../../css components/Style";

const ImageAvatar = styled(Avatar)`
  height: 130px;
  width: 130px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`;

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: ${colors.purple};
`;

export default function FormDisplay({
  handleClose,
  formikProps,
  editState,
  languages,
  location,
  categories,
}) {
  return (
    <Grid container spacing={6}>
      <Grid item xs={8}>
        <MainHeading>Brand Profile</MainHeading>
      </Grid>

      <Grid item xs={4} textAlign="end">
        <IconButton padding="0px" onClick={handleClose}>
          <HighlightOff />
        </IconButton>
      </Grid>

      <Grid item xs={6}>
        <ComponentHeader title={"Display Picture"} />
      </Grid>

      <Grid item xs={6}>
        <ComponentHeader title={formikProps.values.brandName} />
      </Grid>

      <Grid item xs={6} textAlign="center" display="flex">
        <ImageAvatar
          src={formikProps.values.brandAvatar}
          sx={{ width: "100px", height: "100px" }}
        ></ImageAvatar>
      </Grid>

      <Grid item xs={6}>
        <Typography>{formikProps.values.about}</Typography>
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="onlineStore"
          placeholder="Online Store"
          label="Online Store"
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="website"
          label="Website"
          placeholder="Website"
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="monthlyActiveUsers"
          label="Monthly Active Users"
          placeholder="Monthly Active Users"
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteMultiSelect
          name="audienceLocation"
          disabled={!editState}
          forcePopupIcon={!editState ? false : true}
          formikProps={formikProps}
          defaults={{
            primaryKey: "country_code",
            displayLabel: "country_name",
          }}
          options={location}
          label="Select Location"
          placeholder="Select Location"
          onChange={(_, arr, r, option) =>
            handleChange(arr, option, "audienceLocation", "country_code")
          }
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteMultiSelect
          name="audienceLanguages"
          disabled={!editState}
          forcePopupIcon={!editState ? false : true}
          formikProps={formikProps}
          defaults={{
            primaryKey: "code",
            displayLabel: "language",
          }}
          options={languages}
          label="Select Language"
          placeholder="Select Language"
          onChange={(_, arr, r, option) =>
            handleChange(arr, option, "audienceLanguages", "code")
          }
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteMultiSelect
          name="siteCategory"
          disabled={!editState}
          forcePopupIcon={!editState ? false : true}
          formikProps={formikProps}
          defaults={{
            primaryKey: "name",
            displayLabel: "name",
          }}
          options={categories}
          label="Select Category"
          placeholder="Select Category"
          onChange={(_, arr, r, option) =>
            handleChange(arr, option, "siteCategory", "name")
          }
        />
      </Grid>

      <Grid item xs={12}>
        <ComponentHeader title="Social Media Handles" />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="socialMedia.facebook"
          label="Facebook URL"
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="socialMedia.instagram"
          label="Instagram URL"
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="socialMedia.twitter"
          label="Twitter"
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="socialMedia.whatsapp"
          label="Whatsapp Number"
        />
      </Grid>
    </Grid>
  );

  function handleChange(event) {
    const {
      target: { value },
    } = event;

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      [event.target.name]: setValuesOfDropDown(prevVal.name),
    }));

    function setValuesOfDropDown(values) {
      values = typeof value === "string" ? value.split(",") : value;
      return values;
    }
  }
}
