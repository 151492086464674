import { useEffect, useState } from "react";

import Form from "./settings components/Form";
import { settingObject } from "./settings components/settingsObject";
import { useAxios, useComponent } from "../../hooks";
import { Loader } from "../../components";

export default function PermissionSettings() {
  const axios = useAxios();
  const { loader } = useComponent();
  const [settings, setSettings] = useState([]);
  const [editState, setEditState] = useState(true);
  const [formData, setFormData] = useState(settingObject);

  useEffect(() => {
    loader.start();
    axios({
      url: "/get/displaySettings",
      method: "GET",
    }).then((response) => {
      if (response.status) {
        setSettings(response.data);
        for (let setting of response.data) {
          setFormData((prev) => ({
            ...prev,
            [setting.setting_key]: setting.setting_flag,
          }));
        }
        loader.stop();
      }
    });
  }, [axios, loader]);

  return (
    <Loader>
      <Form
        settings={settings}
        formData={formData}
        setFormData={setFormData}
        editState={editState}
        setEditState={setEditState}
      />
    </Loader>
  );
}
