import * as Yup from "yup";

export const signUpDetailsObject = {
  givenName: "",
  familyName: "",
  email: "",
  password: "",
  displayName: "",
  confirmPassword: "",
  role_name: "",
};

export function objectFromFormData(formData) {
  return {
    given_name: formData.givenName,
    family_name: formData.familyName,
    email: formData.email,
    display_name: formData.displayName,
    confirmPassword: formData.confirmPassword,
    password: formData.password,
    role_name: formData.role_name,
  };
}

export const validationSchema = Yup.object().shape({
  givenName: Yup.string().required("First Name is required."),
  familyName: Yup.string().required("Last Name is required."),
  displayName: Yup.string().required("Display Name is required."),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#@$!."()%:;^+*,?&`~-])[A-Za-z0-9#@$!."()%:;^+*,?&`~-]{8,}$/,
      "Password should be 8 characters long with upper case,lower case, numbers and special characters #@$!.()%:;^+*,?&~-."
    )
    .max(255)
    .required("Please enter password"),

  confirmPassword: Yup.string().when("password", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: () =>
      Yup.string()
        .oneOf([Yup.ref("password")], "Both password need to be the same")
        .required("Please enter confirm password"),
  }),
  email: Yup.string()
    .email("Must be a valid email")
    .matches(
      /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/gm,
      "Only accepts business emails"
    )
    .max(255)
    .required("Email is required"),
});
