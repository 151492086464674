import React from "react";
import { Grid } from "@mui/material";
import { Paper, ComponentHeader } from "../../../../components";

import "../../whatsapp interface/WhatsappPreview.css";
import AddSampleMedia from "./AddSampleMedia";
import AddBodyVariable from "./AddBodyVariableSample";
import AddButtonVariable from "./AddButtonVariable";

export default function AddSample({ formikProps }) {
  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title={"Add Sample Content"}
          subtitle={
            "This helps us during the review and approval process, so we can understand what kind of message you plan to send. Make sure these are examples and do not include any actual user or customer information."
          }
        />
      </Grid>

      <AddSampleMedia formikProps={formikProps} />
      <AddBodyVariable formikProps={formikProps} />
      <AddButtonVariable formikProps={formikProps} />
    </Paper>
  );
}
