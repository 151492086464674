import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { DialogContent, Dialog, Grid, Typography } from "@mui/material";

import { useAxios } from "../../../../hooks";
import pages from "../../../../constants/pages";
import {
  audienceObject,
  objectFromResponse,
} from "../../../audience components/create audience components/audienceObjects";
import Form from "../../../audience components/create audience components/Form";
import { colors } from "../../../css components/Style";

const Card = styled(Dialog)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export default function AudienceDetailsDialog({
  categories,
  location,
  languages,
  component,
  details,
  setDetails,
  campaignID,
  formikProps,
  setAudienceCreated,
}) {
  const axios = useAxios();
  const navigate = useNavigate();
  const [fields, setFields] = useState([]);
  const [formData, setFormData] = useState(audienceObject);

  useEffect(() => {
    axios({
      url: "/adtech/getAllDynamicFields",
      method: "GET",
      disableRedirect: true,
    })
      .then((field) => {
        if (field.status) {
          setFields(field.data);

          if (details.state && !!details.id && details.open !== "create") {
            axios({
              url: "/brand/getDetailsOfAudience/" + details.id,
            }).then((response) => {
              if (response.status) {
                setFormData(objectFromResponse(response.data, field.data));
              }
            });
          } else {
            setFormData(audienceObject);
          }
        }
      })
      .catch((err) => console.error(err));
  }, [axios, details.id, details.state, details.open]);

  const handleClose = () => {
    setDetails({ id: "", state: false });
  };

  return (
    <Card
      open={details.state}
      onClose={handleClose}
      PaperProps={{
        sx: {
          minWidth: "1100px!important",
        },
      }}
    >
      <DialogContent>
        <Grid container spacing={3} style={{ padding: "20px" }}>
          <Form
            fields={fields}
            categories={categories}
            location={location}
            languages={languages}
            formData={formData}
            component={component}
            campaignID={campaignID}
            dialogDetails={details}
            editState={
              details.open === "create"
                ? true
                : !campaignID && !formikProps?.values?.audienceID
                ? true
                : false
            }
            campaignFormikProps={formikProps}
            setAudienceCreated={setAudienceCreated}
            handleClose={handleClose}
          />

          {details.open === "detail" && (
            <Grid item xs={12}>
              <Typography
                onClick={() =>
                  navigate(
                    pages.audienceDetails.route + details.id + "?mode=edit"
                  )
                }
                style={{ color: colors.purple, cursor: "pointer" }}
              >
                Edit Audience Group Details
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Card>
  );
}
