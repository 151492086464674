import { Grid } from "@mui/material";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

import FormOne from "./FormOne";
import FormTwo from "./FormTwo";
import FormThree from "./FormThree";
import { ComponentHeader, PageHeader, Paper } from "../../../components";
import { ActiveTextEighteen, colors } from "../../css components/Style";
import { CancelButton, PrimaryButton } from "../../css components/Button";
import ViewAgreement from "../../company components/agreement components/ViewAgreement";

export default function FormDisplay({ formData, noDetails }) {
  const navigate = useNavigate();

  const [viewAgreement, setViewAgreement] = useState({
    data: {},
    state: false,
  });

  return (
    <Fragment>
      <Grid item xs={12}>
        <PageHeader
          pageName={"Company Details"}
          pageHeader={formData.companyName}
          buttons={[
            <CancelButton variant="outlined" onClick={() => navigate(-1)}>
              Back
            </CancelButton>,
          ]}
        />
      </Grid>

      {noDetails && (
        <Paper>
          <Grid item xs={12} alignSelf={"center"}>
            <ActiveTextEighteen color={colors.error}>
              Brand hasn't registered it's company yet.
            </ActiveTextEighteen>
          </Grid>
        </Paper>
      )}

      <Grid item xs={12}>
        <Paper gridSpacing={4}>
          <Grid item xs={12}>
            <ComponentHeader
              title={"Company Details"}
              buttons={[
                <PrimaryButton
                  onClick={() =>
                    setViewAgreement({
                      data: formData.viewAgreement,
                      state: true,
                    })
                  }
                  disabled={!formData.viewAgreement}
                  variant="contained"
                >
                  {!formData.viewAgreement
                    ? "Agreement Not Signed"
                    : "View Signed Agreement"}
                </PrimaryButton>,
              ]}
            />
          </Grid>

          <FormOne formData={formData} />

          <FormTwo formData={formData} />

          <FormThree formData={formData} />
        </Paper>

        <ViewAgreement
          viewAgreement={viewAgreement}
          setViewAgreement={setViewAgreement}
        />
      </Grid>
    </Fragment>
  );
}
