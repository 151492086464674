import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";

import {
  HeadingEighteen,
  TextFourteenBlack,
  colors,
} from "../../css components/Style";
import { Paper, TextAvatar } from "../../../components";
import { useAxios, useAlert } from "../../../hooks";
import convertDateTimeUTC from "./../../../utils/convertUTC";

const Card = styled(Grid)`
  display: flex;
  gap: 20px;
`;

const OuterCard = styled(Paper)`
  display: flex;
  gap: 20px;
  margin: 8px 0;
  padding: 10px;
`;

const Icon = styled(IconButton)`
  height: 35px;
  width: 35px;
  margin: 0 5px;
  padding: 0 5px;
  &:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
`;

export default function List({ usersList, setUserStatus }) {
  const axios = useAxios();
  const { alert } = useAlert();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(Array(usersList.length).fill(true));

  function handleResendEmail(emailId, index) {
    setLoading((prev) => {
      let array = [...prev];
      array[index] = false;

      return array;
    });

    axios({
      url: "/send/unverified/users/email",
      method: "POST",
      data: { email: emailId },
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        dispatch(alert({ type: "success", message: response.data }));
      }
      setLoading((prev) => {
        let array = [...prev];
        array[index] = true;

        return array;
      });
    });
  }

  return (
    <Grid container spacing={0}>
      {usersList.map((user, index) => (
        <Grid item xs={12} key={index}>
          <OuterCard elevation={1}>
            <Grid item alignSelf="center" paddingRight={"20px"}>
              <TextAvatar
                userName={
                  user.given_name.replace(/_/g, " ") + " " + user.family_name
                }
              />
            </Grid>

            <Grid item xs>
              <Card container spacing={2}>
                <Grid item xs={3}>
                  <TextFourteenBlack>User</TextFourteenBlack>

                  <HeadingEighteen>
                    {user.given_name.replace(/_/g, " ") +
                      " " +
                      user.family_name}
                  </HeadingEighteen>

                  <TextFourteenBlack>{user.display_name}</TextFourteenBlack>
                </Grid>

                <Grid item xs={4}>
                  <TextFourteenBlack>Email</TextFourteenBlack>
                  <HeadingEighteen>
                    {user.email?.length > 20
                      ? user.email?.substr(0, 20) + "..."
                      : user.email || "-"}
                  </HeadingEighteen>
                </Grid>

                <Grid item xs={4}>
                  <TextFourteenBlack>Registration Date</TextFourteenBlack>

                  <HeadingEighteen>
                    {convertDateTimeUTC(user.created_at)}
                  </HeadingEighteen>
                </Grid>
              </Card>
            </Grid>

            <Grid item xs={1.5}>
              <TextFourteenBlack>Email Verification</TextFourteenBlack>
              <HeadingEighteen>
                {!user.is_email_verified ? (
                  <CloseIcon
                    sx={{
                      color: colors.error,
                      borderRadius: "5px",
                      border: "3px solid",
                      fontSize: "x-large",
                      marginLeft: "45px",
                      marginTop: "5px",
                    }}
                  />
                ) : (
                  ""
                )}
                {user.is_email_verified ? (
                  <CheckIcon
                    sx={{
                      color: colors.green,
                      borderRadius: "5px",
                      border: "3px solid",
                      fontSize: "x-large",
                      marginLeft: "45px",
                      marginTop: "5px",
                    }}
                  />
                ) : (
                  ""
                )}
              </HeadingEighteen>
            </Grid>

            <Grid item xs={1.5}>
              <TextFourteenBlack>Admin Verification</TextFourteenBlack>
              <HeadingEighteen>
                {!user.account_status ? (
                  <CloseIcon
                    sx={{
                      color: colors.error,
                      borderRadius: "5px",
                      border: "3px solid",
                      fontSize: "x-large",
                      marginLeft: "45px",
                      marginTop: "5px",
                    }}
                  />
                ) : (
                  ""
                )}
                {user.account_status ? (
                  <CheckIcon
                    sx={{
                      color: colors.green,
                      borderRadius: "5px",
                      border: "3px solid",
                      fontSize: "x-large",
                      marginLeft: "45px",
                      marginTop: "5px",
                    }}
                  />
                ) : (
                  ""
                )}
              </HeadingEighteen>
            </Grid>

            <Grid item xs={1.5}>
              <TextFourteenBlack>Brand Created</TextFourteenBlack>
              <HeadingEighteen>
                {!user.is_brand_created ? (
                  <CloseIcon
                    sx={{
                      color: colors.error,
                      borderRadius: "5px",
                      border: "3px solid",
                      fontSize: "x-large",
                      marginLeft: "45px",
                      marginTop: "5px",
                    }}
                  />
                ) : (
                  ""
                )}
                {user.is_brand_created ? (
                  <CheckIcon
                    sx={{
                      color: colors.green,
                      borderRadius: "5px",
                      border: "3px solid",
                      fontSize: "x-large",
                      marginLeft: "45px",
                      marginTop: "5px",
                    }}
                  />
                ) : (
                  ""
                )}
              </HeadingEighteen>
            </Grid>

            {!user.is_email_verified ? (
              <Grid item xs={1}>
                <TextFourteenBlack>Email</TextFourteenBlack>

                <div style={{ display: "flex" }}>
                  <Tooltip title="Resend Email">
                    {!loading[index] ? (
                      <CircularProgress />
                    ) : (
                      <Icon
                        style={{
                          border: `1px solid ${colors.green}`,
                          color: colors.green,
                        }}
                      >
                        <ForwardToInboxIcon
                          style={{
                            color: colors.green,
                            height: "20px",
                            width: "20px",
                          }}
                          onClick={() => handleResendEmail(user.email, index)}
                        />
                      </Icon>
                    )}
                  </Tooltip>
                </div>
              </Grid>
            ) : (
              <Grid item xs={1}></Grid>
            )}
          </OuterCard>
        </Grid>
      ))}
    </Grid>
  );

  // function handleAction(user, status) {
  //   axios({
  //     url: "/user/accountAction",
  //     method: "POST",
  //     data: {
  //       email: user.email,
  //       user_id: user.user_id.toString(),
  //       status: status,
  //     },
  //   }).then((response) => {
  //     if (response.status) {
  //       setUserStatus(user.user_id);
  //       dispatch(
  //         alert({
  //           message: response.data,
  //           type: "success",
  //         })
  //       );
  //     }
  //   });
  // }
}
