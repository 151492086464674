/*
Contains all the routes pertaining to Dashboard Layout
Use the following structure: 
export const {name}Route = {
  ID: "",
  path: "/",
  element: "",
  index: true, (if the path is default for some folder)
}
*/

import pages from "../../constants/pages";
import Page404 from "../../pages/misc/Page404";
import Invitation from "../../pages/invitation components/Invitation";

const blankLayoutRoutes = {
  verifyAccountRoute: {
    ID: pages.verifyAccount.ID,
    path: pages.verifyAccount.route + ":code",
    element: Invitation,
  },

  //Error 404
  error404Route: {
    ID: "error404",
    path: "*",
    element: Page404,
  },

  //permission
};

export default blankLayoutRoutes;
