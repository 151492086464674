import { Formik } from "formik";
import { Fragment } from "react";
import { useDispatch } from "react-redux";

import FormDisplay from "./FormDisplay";
import { useAxios } from "../../../hooks";
import { objectFromFormData } from "./dynamicUiObjects";
import { alert } from "../../../redux/slices/alertSlice";

export default function Form({
  formData,
  setFormData,
  editState,
  setEditState,
}) {
  const axios = useAxios();
  const dispatch = useDispatch();

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        axios({
          url: "/save/dynamicDashboard",
          method: "POST",
          data: objectFromFormData(formData),
          disableRedirect: true,
        }).then((response) => {
          if (response.status) {
            dispatch(
              alert({
                type: "success",
                message: response.message.displayMessage,
              })
            );
          }
          setSubmitting(false);
        });
      }}
    >
      {(formikProps) => (
        <Fragment>
          <FormDisplay
            setEditState={setEditState}
            editState={editState}
            formikProps={formikProps}
          />
        </Fragment>
      )}
    </Formik>
  );
}
