import { useNavigate } from "react-router-dom";
import { Button, Grid, Pagination, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { Fragment, useState, useEffect } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import pages from "../../constants/pages";
import { useAxios, useComponent } from "../../hooks";
import { Loader, PageHeader } from "../../components";
import Search from "./onboarding list components/Search";
import { PrimaryButton } from "../css components/Button";
import BrandList from "./onboarding list components/BrandList";
import EmptyList from "../misc/EmptyList";

export default function OnBoardedConnectionList() {
  const axios = useAxios();
  const navigate = useNavigate();
  const { loader } = useComponent();

  const [dpList, setDpList] = useState([]);

  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [lastPage, setLastPage] = useState({ totalCount: 0, lastPage: false });

  useEffect(() => {
    loader.start();
    axios({
      url:
        "/user/getAllDataPartner" +
        `?page=${page}&limit=10` +
        (searchKey ? `&search=${searchKey}` : ""),
    }).then((response) => {
      if (response.status) {
        if (response.data.data.length > 0) {
          setDpList(response.data.data);

          setLastPage({
            totalCount: response.data.totalCount,
            lastPage: response.data.data.length < 10,
          });
        } else {
          setDpList(response.data);

          setLastPage({
            totalCount: response.data.totalCount,
            lastPage: true,
          });
        }
        loader.apiComplete();
      }
    });
  }, [loader, axios, searchKey, page]);

  function handlePagination(step, index) {
    setPage((prev) =>
      step === "index" ? index : prev + (step === "next" ? 1 : -1)
    );
  }

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageHeader
            pageName={"Onboarded Brands"}
            pageHeader={"List of onboarded brands"}
            icons={[
              <Search
                searchKey={searchKey}
                setPage={setPage}
                setSearchKey={setSearchKey}
              />,
            ]}
            buttons={[
              <PrimaryButton
                variant="contained"
                size="large"
                onClick={() => navigate(pages.dpOnBoarding.route)}
              >
                New Onboarding
              </PrimaryButton>,
            ]}
          />
        </Grid>

        {/* <Categorize brands={brands} setBrands={setBrands} /> */}
      </Grid>

      <Loader height="75%">
        {dpList.length > 0 ? (
          <BrandList dpList={dpList} />
        ) : (
          <EmptyList title={"No new brands"} />
        )}
      </Loader>

      {!!lastPage.totalCount && (
        <Grid item container display={"flex"} justifyContent={"center"}>
          <Pagination
            count={Math.ceil(lastPage.totalCount / 10)}
            variant="outlined"
            color="secondary"
            page={page}
            onChange={(e, value) => handlePagination("index", value)}
          />
        </Grid>
      )}
    </Fragment>
  );
}
