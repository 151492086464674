import { Fragment } from "react";
import {
  Accept,
  CancelButton,
  PrimaryLoadingButton,
} from "../../css components/Button";
import { Grid } from "@mui/material";

export default function ActionButtons({
  formikProps,
  editState,
  setEditState,
  changeData,
  setChangeData,
}) {
  return (
    <Fragment>
      <Grid item style={{ alignSelf: "center" }}>
        {editState && (
          <CancelButton
            variant="outlined"
            onClick={() => setEditState(!editState)}
          >
            Cancel
          </CancelButton>
        )}
      </Grid>

      <Grid item>
        {!editState ? (
          <Accept
            style={{ marginLeft: "5px" }}
            size="large"
            variant="contained"
            onClick={() => setEditState(!editState)}
          >
            Edit
          </Accept>
        ) : (
          <PrimaryLoadingButton
            type="submit"
            variant="contained"
            fullWidth
            onClick={formikProps.handleSubmit}
            disabled={!formikProps.isValid}
            loading={formikProps.isSubmitting}
            color="primary"
          >
            Submit
          </PrimaryLoadingButton>
        )}
      </Grid>
    </Fragment>
  );
}
