import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  CancelButton,
  Accept,
  Reject,
  PrimaryButton,
} from "../../css components/Button";
import { useAxios, useComponent } from "../../../hooks";
import pages from "../../../constants/pages";
import Dialog from "../../../components/Dialog";

export default function ActionButton({ ID = "", setFormData, formData }) {
  const axios = useAxios();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { alert } = useComponent();
  const [dialogState, setDialogState] = useState({
    state: false,
    data: {},
  });

  const [loading, setLoading] = useState({
    REJECTED: false,
    APPROVE: false,
    PENDING: false,
  });

  function handleCancel() {
    navigate(-1);
  }

  return (
    <Fragment>
      <CancelButton
        onClick={handleCancel}
        variant="outlined"
        style={{ marginRight: "10px" }}
      >
        {"Back"}
      </CancelButton>

      {Object.keys(formData.agency)?.length === 0 && (
        <PrimaryButton
          style={{ marginRight: "10px" }}
          variant="contained"
          disabled={
            formData.company_details === null ||
            !formData?.isCompanyDetailFilled
          }
          onClick={() =>
            navigate(pages.unverifiedCompanyDetails.route + "?brandID=" + ID)
          }
        >
          {formData?.isCompanyDetailFilled
            ? "Company Details"
            : "No Company Details"}
        </PrimaryButton>
      )}

      {(!formData.status || formData.status === "APPROVE") && (
        <Reject
          style={{ marginRight: "10px" }}
          size="large"
          variant="contained"
          loading={loading.REJECTED}
          onClick={() =>
            setDialogState({
              state: true,
              data: {
                brand: formData,
                status: 2,
              },
            })
          }
        >
          Reject
        </Reject>
      )}

      {(!formData.status || formData.status === "REJECTED") && (
        <Accept
          style={{ marginRight: "10px" }}
          size="large"
          variant="contained"
          loading={loading.APPROVE}
          onClick={() => handleCampaignStatus(1, "APPROVE")}
        >
          Approve
        </Accept>
      )}

      <Dialog
        dialogState={dialogState}
        setDialogState={setDialogState}
        handleAgree={handleAgree}
        dialogContent={{
          title: "Do You want to reject this brand?",
          dangerButton: "Reject Brand",
        }}
      />
    </Fragment>
  );

  function handleAgree() {
    handleCampaignStatus(dialogState.data?.status, "REJECTED");
  }

  function handleCampaignStatus(s, status) {
    setLoading((prev) => ({
      ...prev,
      [status]: true,
    }));

    axios({
      url: "/brand/verification/action",
      method: "POST",
      disableRedirect: true,
      data: {
        brand_id: ID,
        brand_status: s,
      },
    }).then((response) => {
      if (response.status) {
        dispatch(alert.success(response.message.displayMessage));
        setFormData((formData) => ({
          ...formData,
          status: status,
        }));
      }
      setLoading((prev) => ({
        ...prev,
        [status]: false,
      }));
    });
  }
}
