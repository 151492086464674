import React from "react";
import styled from "styled-components/macro";
import { DialogContent, Dialog, Grid } from "@mui/material";

import FormDisplay from "./FormDisplay";

const Card = styled(Dialog)`
  min-width: 360px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export default function TakeUpDialog({ takeUp, setTakeUp, formikProps }) {
  const handleClose = (e, reason) => {
    if (reason && reason === "backdropClick") return;
    setTakeUp({ state: false, data: { ...takeUp.data, dp_note: "" } });
  };

  return (
    <React.Fragment>
      <Card disab open={takeUp?.state} onClose={handleClose}>
        <DialogContent>
          <Grid container spacing={3} style={{ padding: "20px" }}>
            <FormDisplay
              takeUp={takeUp}
              setTakeUp={setTakeUp}
              formikProps={formikProps}
              handleClose={handleClose}
            />
          </Grid>
        </DialogContent>
      </Card>
    </React.Fragment>
  );
}
