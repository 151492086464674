import { Grid } from "@mui/material";

import { Paper } from "../../../components";
import { ActiveTextEighteen, colors } from "../../css components/Style";

export default function AdminAgencyStatus({ formData }) {
  return (
    <Paper>
      {formData.status === "REJECTED" && (
        <Grid item xs={12}>
          <ActiveTextEighteen color={colors.error}>
            The agency has been rejected.
          </ActiveTextEighteen>
        </Grid>
      )}

      {!formData.status && (
        <Grid item xs={12}>
          <ActiveTextEighteen color={colors.warning}>
            The agency is in pending state.
          </ActiveTextEighteen>
        </Grid>
      )}

      {formData.status === "APPROVE" && (
        <Grid item xs={12}>
          <ActiveTextEighteen color={colors.green}>
            The agency has been accepted.
          </ActiveTextEighteen>
        </Grid>
      )}
    </Paper>
  );
}
