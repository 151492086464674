import { Formik } from "formik";
import { Fragment } from "react";
import { useDispatch } from "react-redux";

import FormDisplay from "./FormDisplay";
import {
  changePasswordObject,
  validationSchema,
  objectFromFormData,
} from "./emailObject";
import { useAxios, useAlert } from "../../../hooks";

export default function Form() {
  const axios = useAxios();
  const { alert } = useAlert();

  const dispatch = useDispatch();

  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={changePasswordObject}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(formData, { setSubmitting }) => {
          setSubmitting(true);
          axios({
            url: "/user/auth/passwordResetToken",
            method: "POST",
            data: objectFromFormData(formData),
            disableRedirect: true,
          }).then((response) => {
            if (response.status) {
              dispatch(
                alert({
                  message: response?.message?.displayMessage,
                  type: "success",
                })
              );
            }
            setSubmitting(false);
          });
        }}
      >
        {(formikProps) => <FormDisplay formikProps={formikProps} />}
      </Formik>
    </Fragment>
  );
}
