import { Fragment } from "react";
import { Grid, Typography, Chip } from "@mui/material";

import { colors } from "../../css components/Style";

export default function FormThree({ formData }) {
  function handleClick(url) {
    window.open(url, "_blank");
  }

  return (
    <Fragment>
      <Grid item xs={12} display={"flex"} gap="10px">
        <div className="div-upload-document">
          <Typography color={colors.purple}>Company GST Certificate</Typography>

          {!!formData.gstCertificate && (
            <Chip
              className="file-chip"
              style={{
                borderColor: colors.green,
                color: colors.green,
              }}
              label={formData.gstCertificate?.split("_").pop()}
              variant="outlined"
              onClick={() => handleClick(formData.gstCertificate)}
            />
          )}
        </div>

        <div className="div-upload-document">
          <Typography color={colors.purple}>Company PAN Certificate</Typography>

          {!!formData.companyPanCertificate && (
            <Chip
              className="file-chip"
              style={{
                borderColor: colors.green,
                color: colors.green,
              }}
              label={formData.companyPanCertificate?.split("_").pop()}
              variant="outlined"
              onClick={() => handleClick(formData.companyPanCertificate)}
            />
          )}
        </div>

        <div className="div-upload-document">
          <Typography color={colors.purple}>
            Company Director's Aadhar Certificate
          </Typography>

          {!!formData.directorAadharCertificate && (
            <Chip
              className="file-chip"
              style={{
                borderColor: colors.green,
                color: colors.green,
              }}
              label={formData.directorAadharCertificate?.split("_").pop()}
              variant="outlined"
              onClick={() => handleClick(formData.directorAadharCertificate)}
            />
          )}
        </div>

        <div className="div-upload-document">
          <Typography color={colors.purple}>
            Company Director's PAN Certificate
          </Typography>

          {!!formData.directorPanCertificate && (
            <Chip
              className="file-chip"
              style={{
                borderColor: colors.green,
                color: colors.green,
              }}
              label={formData.directorPanCertificate?.split("_").pop()}
              variant="outlined"
              onClick={() => handleClick(formData.directorPanCertificate)}
            />
          )}
        </div>
      </Grid>
    </Fragment>
  );
}
