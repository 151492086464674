import React from "react";
import { Formik } from "formik";

import FormDisplay from "./FormDisplay";
import validationSchema from "./validationSchema";
import useAxios from "./../../../../hooks/useAxios";
import { ButtonFooter } from "../../../../components";
import { objectFromFormData } from "./botDetailsObject";
import { useAlert } from "../../../../hooks";
import { useDispatch } from "react-redux";

export default function Form({ formData, editState, setEditState }) {
  const axios = useAxios({ baseURL: "cpass_api" });

  const alert = useAlert();
  const dispatch = useDispatch();

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        axios({
          url: "/edit-key/" + formData.agentID,
          method: "POST",
          data: objectFromFormData(formData),
          disableRedirect: true,
        })
          .then((res) => {
            if (res.status) {
              dispatch(
                alert(
                  alert({
                    message: res.message.displayMessage,
                    type: "success",
                  })
                )
              );
            }
          })
          .catch((err) => console.log(err));
      }}
    >
      {(formikProps) => (
        <React.Fragment>
          <FormDisplay formikProps={formikProps} editState={editState} />

          <ButtonFooter
            ID={formikProps.values.agentID}
            editState={editState}
            setEditState={setEditState}
            formikProps={formikProps}
          />
        </React.Fragment>
      )}
    </Formik>
  );
}
