import React from "react";
import { Grid, Button, InputAdornment } from "@mui/material";
import { Add } from "@mui/icons-material";
import { FaVideo, FaImage, FaFile } from "react-icons/fa";

import HeaderButtons from "./HeaderButtons";
import "../../whatsapp interface/WhatsappPreview.css";

import {
  ComponentHeader,
  Paper,
  TextField,
  Label,
} from "../../../../components";

export default function TemplateHeader({ formikProps, addSample }) {
  const handleAddVariable = () => {
    var textHeader = document.getElementsByName("templateHeader.text")[0].value;
    var count_var_1 = (textHeader.match(/\{{/g) || []).length;
    var count_var_2 = (textHeader.match(/\}}/g) || []).length;
    if (count_var_1 === 0 && count_var_2 === 0) {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        templateHeader: setVariables(prevVal.templateHeader),
      }));
      function setVariables(templateHeader) {
        templateHeader.text = textHeader + "{{1}}";
        templateHeader.count_of_variable = 1;
        return templateHeader;
      }
    } else {
    }
  };

  const handleHeaderChange = (event) => {
    var textHeader = document.getElementsByName("templateHeader.text")[0].value;
    var count_var_1 = (textHeader.match(/\{{/g) || []).length;

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      templateHeader: setVariables(prevVal.templateHeader),
    }));
    function setVariables(templateHeader) {
      templateHeader.text = event.target.value;
      templateHeader.count_of_variable = count_var_1 === 0 ? 0 : count_var_1;
      return templateHeader;
    }
  };

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title={"Template Header"}
          subtitle={
            "Add a title or choose which type of media you'll use for this header."
          }
          buttons={[
            <HeaderButtons formikProps={formikProps} addSample={addSample} />,
          ]}
        />
      </Grid>

      {formikProps.values?.templateHeader?.type === "text" && (
        <React.Fragment>
          <Grid item xs={12}>
            <TextField
              label="Header Text"
              name="templateHeader.text"
              formikProps={formikProps}
              onChange={handleHeaderChange}
              disabled={Boolean(formikProps.values.ID) || Boolean(addSample)}
              inputProps={{
                maxLength: 60,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {formikProps.values?.templateHeader?.text?.length}/60
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={9} />

          <Grid textAlign={"end"} item xs={3}>
            <Button
              variant="outlined"
              color="primary"
              disabled={Boolean(addSample) || Boolean(formikProps.values.ID)}
              onClick={handleAddVariable}
              startIcon={<Add />}
            >
              Add Variable
            </Button>
          </Grid>
        </React.Fragment>
      )}

      {formikProps.values?.templateHeader?.type === "image" && (
        <Grid item xs={12}>
          <div align="center" className="HeaderType">
            <FaImage />
          </div>
        </Grid>
      )}

      {formikProps.values?.templateHeader?.type === "video" && (
        <Grid item xs={12}>
          <div align="center" className="HeaderType">
            <FaVideo />
          </div>
        </Grid>
      )}

      {formikProps.values?.templateHeader?.type === "document" && (
        <Grid item xs={12}>
          <div align="center" className="HeaderType">
            <FaFile />
          </div>
        </Grid>
      )}
    </Paper>
  );
}
