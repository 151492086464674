import { CgEye } from "react-icons/cg";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { Grid, Avatar, IconButton, Tooltip } from "@mui/material";

import {
  HeadingEighteen,
  TextFourteenBlack,
  colors,
} from "../../css components/Style";
import { Paper } from "../../../components";
import { useAxios, useAlert } from "../../../hooks";
import pages from "../../../constants/pages";
import Dialog from "../../../components/Dialog";

const Icon = styled(IconButton)`
  border: 1px solid ${colors.purple};
  color: ${colors.purple};
  height: 35px;
  width: 35px;
  margin: 0 5px;
  padding: 0 5px;
  &:hover {
    border: 1px solid ${colors.purple};
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
`;

const Check = styled(CheckIcon)`
  color: #28967e;
  border-radius: 5px;
  border: 3px solid;
  font-size: x-large;
  margin-top: 5px;
`;

const Close = styled(CloseIcon)`
  color: #d32f2f;
  border-radius: 5px;
  border: 3px solid;
  font-size: x-large;
  margin-top: 5px;
`;

export default function List({ brands, setBrandStatus }) {
  const axios = useAxios();
  const { alert } = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dialogState, setDialogState] = useState({
    state: false,
    data: {},
  });

  function handleBrandNavigation(brandID) {
    navigate(pages.unverifiedPageDetails.route + "?brandID=" + brandID);
  }

  return (
    <Grid container spacing={3}>
      {brands.map((brand) => (
        <Grid item xs={12}>
          <Paper>
            <Grid item xs={12} style={{ textAlign: "end" }}>
              {brand?.agency_name && (
                <TextFourteenBlack>
                  Agency : {brand.agency_name?.replace(/_/g, " ") || "-"}
                </TextFourteenBlack>
              )}
            </Grid>

            <Grid item xs={1}>
              <Avatar
                onClick={() => handleBrandNavigation(brand.brand_id)}
                src={brand.logo}
                style={{ height: "60px", width: "60px", cursor: "pointer" }}
              />
            </Grid>

            <Grid item xs={2}>
              <TextFourteenBlack>Brand Name</TextFourteenBlack>
              <HeadingEighteen>
                {brand.brand_name.replace(/_/g, " ") || "-"}
              </HeadingEighteen>
            </Grid>

            <Grid item xs={2}>
              <TextFourteenBlack>Brand Status</TextFourteenBlack>
              <HeadingEighteen
                style={{
                  color:
                    brand.brand_verification_status === 0
                      ? colors.yellow
                      : brand.brand_verification_status === 1
                      ? colors.green
                      : colors.error,
                }}
              >
                {brand.brand_verification_status === 0 && "Unverified"}
                {brand.brand_verification_status === 1 && "Verified"}
                {brand.brand_verification_status === 2 && "Rejected"}
              </HeadingEighteen>
            </Grid>

            <Grid item xs={2}>
              <TextFourteenBlack>Brand Detail</TextFourteenBlack>

              <HeadingEighteen>
                <Check
                  sx={{
                    marginLeft: "35px",
                  }}
                />
              </HeadingEighteen>
            </Grid>

            <Grid item xs={2}>
              <TextFourteenBlack>Company Detail</TextFourteenBlack>

              <HeadingEighteen
                style={{
                  color:
                    brand.is_company_detail_filled === 0
                      ? colors.yellow
                      : colors.green,
                }}
              >
                {brand.is_company_detail_filled === 1 ? (
                  <Check
                    sx={{
                      marginLeft: "45px",
                    }}
                  />
                ) : (
                  <Close
                    sx={{
                      marginLeft: "45px",
                    }}
                  />
                )}
              </HeadingEighteen>
            </Grid>

            <Grid item xs={2}>
              <TextFourteenBlack>Agreement Signed</TextFourteenBlack>

              <HeadingEighteen
                style={{
                  color:
                    brand.is_sign_agreement === false
                      ? colors.yellow
                      : colors.green,
                }}
              >
                {brand.is_sign_agreement === true ? (
                  <Check
                    sx={{
                      marginLeft: "55px",
                    }}
                  />
                ) : (
                  <Close
                    sx={{
                      marginLeft: "55px",
                    }}
                  />
                )}
              </HeadingEighteen>
            </Grid>

            <Grid
              item
              xs={1}
              alignSelf={"center"}
              display="flex"
              justifyContent={"end"}
            >
              <Tooltip title="View Brand">
                <Icon>
                  <CgEye
                    onClick={() => handleBrandNavigation(brand.brand_id)}
                    style={{ height: "20px", width: "20px" }}
                  />
                </Icon>
              </Tooltip>
            </Grid>
          </Paper>
        </Grid>
      ))}

      <Dialog
        dialogState={dialogState}
        setDialogState={setDialogState}
        handleAgree={handleAgree}
        dialogContent={{
          title: "Do You want to reject this brand?",
          dangerButton: "Reject Brand",
        }}
      />
    </Grid>
  );
  function handleAgree(brand, status) {
    handleAction(dialogState.data?.brand, dialogState.data?.status);
  }

  function handleAction(brand, status) {
    axios({
      url: "/brand/verification/action",
      method: "POST",
      disableRedirect: true,
      data: {
        brand_id: brand.brand_id,
        brand_status: status,
      },
    }).then((response) => {
      if (response.status) {
        setBrandStatus(brand.brand_id);
        dispatch(
          alert({
            message: response.message.displayMessage,
            type: "success",
          })
        );
      }
    });
  }
}
