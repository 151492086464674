import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";

import pages from "../../../constants/pages";
import {
  ComponentHeader,
  PageHeader,
  Paper,
  TextField,
} from "../../../components";
import { colors } from "../../css components/Style";

export default function FormOne({ formikProps }) {
  const navigate = useNavigate();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PageHeader pageName={"Profile Details"} pageHeader={"User Details"} />
      </Grid>

      <Grid item xs={12}>
        <Paper gridSpacing={4}>
          <Grid item xs={12}>
            <ComponentHeader title={"Details"} />
          </Grid>

          <Grid item xs={4}>
            <TextField
              name="displayName"
              placeholder="Display Name"
              label="Display Name"
              disabled
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              disabled
              name="givenName"
              placeholder="Given Name"
              label="Given Name"
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              disabled
              name="familyName"
              placeholder="Family Name"
              label="Family Name"
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled
              name="email"
              placeholder="Email"
              label="Email"
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              placeholder="Role"
              label="Role"
              disabled
              name="role"
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              color={colors.purple}
              variant="caption1"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(pages.changePassword.route)}
            >
              Change Password
            </Typography>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
