export const audienceObject = {
  ID: "",
  brandID: "",
  minimumAge: "",
  isEditable: true,

  audience_group: {
    value: "",
    mandatory: false,
  },

  age: {
    value: { min: "", max: "" },
    mandatory: false,
  },

  gender: {
    value: [],
    mandatory: false,
  },

  approx_mau: {
    value: "",
    mandatory: false,
  },

  arpu: {
    value: { min: "", max: "" },
    mandatory: false,
  },

  audience_location: {
    value: [],
    mandatory: false,
  },

  languages: {
    value: [],
    mandatory: false,
  },
  percentage: {
    value: 100,
  },
};

export function objectFromResponse(response) {
  let data = {};

  for (let key of response.fields) {
    data[Object.keys(key)[0]] = {
      value: Object.values(key)[0],
      mandatory: Object.values(key)[1],
    };
  }

  return {
    ...data,
    ID: response.audience_id,
    brandID: response.brand_id,
    isEditable: response.is_editable,
  };
}

export function objectFromFormData(formData, details) {
  return {
    brand_id: parseInt(details.activeBrand.ID),
    fields: [
      {
        audience_group: formData.audience_group,
      },
      {
        age: formData.age,
      },
      {
        gender: formData.gender,
      },
      {
        arpu: formData.arpu,
      },
      {
        audience_location: formData.audience_location,
      },
      {
        languages: formData.languages,
      },
      {
        percentage: formData.percentage,
      },
    ],
  };
}
