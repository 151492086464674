import ENV from "../config/config";

const URL_PREFIX = ENV === "main" ? "" : ENV + ".";

const URL =
  ENV === "main"
    ? "https://adtech-backend.verismart.ai"
    : "https://" + URL_PREFIX + "backend-adn.metawing.ai";

const BASE_URL = {
  adn_api: {
    url: URL,
    version: "/api/v1/adn",
  },
  cpass_api: {
    url: "https://" + URL_PREFIX + "backend-adn.metawing.ai",
    version: "/v1/cpass",
  },
  insights_api: {
    url: "https://" + URL_PREFIX + "backend-adn.metawing.ai",
    version: "/v1/insights",
  },
};

export default BASE_URL;
